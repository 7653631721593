import React, { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const SelfArrangedFinance = ({
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
  setShowSelfForm,
  setSelectedFinance,
}) => {
  const [cosigner, setCosigner] = useState(
    response.step3?.selfArannaged?.cosigner
  );

  const ValidationSchema = Yup.object().shape({
    lender: Yup.string().required("Lender's name is required"),
    amount: Yup.string().required("Amount is required"),
    apr: Yup.string(),
    term: Yup.string(),
    cosigner: Yup.string().required("Please select an option."),
    cosignerName: cosigner
      ? Yup.string().required("Co-signer's full name is required.")
      : Yup.string(),
  });

  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-3xl text-[#183558] mb-4 font-bold">
        Self-Arranged Finance
      </h1>
      <p className="text-sm text-[#757989] mb-10">
        You need to be pre-approved for a loan from another lender to use this
        option. To get started, we will need a few details regarding your
        pre-approved loan.
      </p>
      <Formik
        initialValues={{
          ...(response.step3.selfArannaged || {
            lender: "",
            amount: "",
            payment: "",
            apr: "",
            term: "",
            cosigner: "",
            cosignerName: "",
          }),
        }}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          console.log({
            ...response,
            step3: {
              ...response.step3,
              selfArannaged: { ...values },
              completed: true,
            },
          });
          handleSaveFormData(
            {
              ...response,
              step3: {
                ...response.step3,
                selfArannaged: { ...values },
                completed: true,
              },
            },
            setSubmitting,
            (res) => {
              setCurrentStep((prev) => prev + 1);
              setResponse(res.data.data);
            }
          );
        }}
        validationSchema={ValidationSchema}
      >
        {({
          isSubmitting,
          errors,
          values,
          setValues,
          setFieldValue,
          handleChange,
          handleBlur,
          touched,
        }) => (
          <Form>
            <div className="lg:flex justify-between pb-10 mb-10 border-b border-[#d9dde9]">
              <div className="lg:w-[calc(100%-256px-16px)]">
                <div className="w-full">
                  <div className="grid gap-4">
                    <TextField
                      name="lender"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.lender}
                      fullWidth
                      error={Boolean(errors?.lender)}
                      helperText={errors?.lender}
                      label="LENDER'S NAME"
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                    <TextField
                      name="amount"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount}
                      fullWidth
                      error={ Boolean(errors.amount)}
                      helperText={ errors.amount}
                      label="MAX PRE-QUALIFIED AMOUNT *"
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                    <TextField
                      name="payment"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.payment}
                      fullWidth
                      error={Boolean(errors.payment)}
                      helperText={errors.payment}
                      label="PAYMENT TO Renaissance"
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                    <div className="lg:grid grid-cols-2 gap-2">
                      <TextField
                        name="apr"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.apr}
                        fullWidth
                        error={Boolean(errors.apr)}
                        helperText={ errors.apr}
                        label="APR"
                        variant="outlined"
                        inputProps={{ style: { background: "white" } }}
                      />
                      <FormControl
                        fullWidth
                        error={Boolean(errors.term)}
                      >
                        <InputLabel id="select">TERM LENGTH</InputLabel>
                        <Select
                          name="term"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.term}
                          fullWidth
                          labelId="select"
                          label="TERM LENGTH"
                          variant="outlined"
                          sx={{ background: "white" }}
                        >
                          <MenuItem value={""}>None</MenuItem>
                          <MenuItem value={"12"}>12</MenuItem>
                          <MenuItem value={"24"}>24</MenuItem>
                          <MenuItem value={"36"}>36</MenuItem>
                          <MenuItem value={"48"}>48</MenuItem>
                          <MenuItem value={"60"}>60</MenuItem>
                          <MenuItem value={"72"}>72</MenuItem>
                          <MenuItem value={"84"}>84</MenuItem>
                          <MenuItem value={"85"}>85</MenuItem>
                        </Select>
                        <FormHelperText>
                          {errors.term}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-64">
                <div className="bg-white p-4 rounded-md">
                  <p className="text-sm text-[#183558] font-bold">
                    Payment to Renaissance
                  </p>
                  <div className="flex justify-between mt-5 pb-3 border-b border-[#d9dde9]">
                    <p className="text-sm text-[#183558]">Cash down payment</p>
                    <p className="text-sm text-[#183558]">$37,202.84</p>
                  </div>
                  <div className="flex justify-between mt-3">
                    <p className="text-sm text-[#183558]">Total Due at Order</p>
                    <p className="text-sm text-[#183558]">$37,202.84</p>
                  </div>
                </div>
                <div className="bg-white mt-2 p-4 rounded-md">
                  <div className="flex justify-between">
                    <p className="text-sm text-[#183558]">
                      Total Amount Financed
                    </p>
                    <p className="text-sm text-[#183558]">$0.00</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="text-sm text-[#183558]">
                Do you have a co-signer for your vehicle? *
              </p>
              <div className="flex justify-between gap-2 mt-5">
                <button
                  type="button"
                  onClick={() => {
                    setCosigner(true);
                    setFieldValue("cosigner", true);
                  }}
                  className={`rounded  text-[#183558] hover:text-[#fff] bg-[#fff] hover:bg-[#00aed9]  font-medium min-h-10  border border-[#d9dde9] md:w-1/2 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                    cosigner == true
                      ? "!bg-[#00619e] !text-white hover:!bg-[#00aed9]"
                      : ""
                  }`}
                >
                  Yes
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setCosigner(false);
                    setFieldValue("cosigner", false);
                  }}
                  className={`rounded  text-[#183558] hover:text-[#fff] bg-[#fff] hover:bg-[#00aed9]  font-medium min-h-10  border border-[#d9dde9] md:w-1/2 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                    cosigner === false
                      ? "!bg-[#00619e] !text-white hover:!bg-[#00aed9]"
                      : ""
                  }`}
                >
                  No
                </button>
              </div>
              <p className="text-[#d32f2f] text-[0.75rem] mt-[3px]">
                {errors.cosigner}
              </p>
              {cosigner && (
                <div className="mt-5 md:w-3/5">
                  <TextField
                    name="cosignerName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cosignerName}
                    fullWidth
                    error={Boolean(errors.cosignerName)}
                    helperText={ errors.cosignerName}
                    label="COSIGNER'S FULL NAME"
                    variant="outlined"
                    inputProps={{ style: { background: "white" } }}
                  />
                </div>
              )}
            </div>
            <div className="flex justify-center mt-10">
              <button
                type="submit"
                disabled={isSubmitting}
                className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
              >
                SAVE & CONTINUE
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div>
        <p className="text-sm text-center text-[#757989] mt-5 mb-3">
          Looking for instant and competitive financing option?
        </p>
        <p
          onClick={() => {
            setResponse({
              ...response,
              step3: {
                ...response.step3,
                selfArannaged: {},
                completed: false,
                paymentMethod: "",
              },
            });
            setSelectedFinance("");
            setShowSelfForm(false);
          }}
          className="text-sm text-center text-[#00aed9] font-semibold cursor-pointer"
        >
          Finance with Renaissance.
        </p>
      </div>
    </div>
  );
};

export default SelfArrangedFinance;
