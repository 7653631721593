import React, { createRef, useState } from "react";
import Popper from "popper.js";

const Delivery = ({
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
  setEditing,
}) => {
  const [loading, setLoading] = useState();
  const [selectedDelivery, setSelectedDelivery] = useState(
    response.step4?.deliveryOption?.type || "Pick it up"
  );

  const [selectedpickUp, setSelectedpickUp] = useState(
    response.step4?.deliveryOption?.pickup || "Auburn, WA"
  );

  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = createRef();
  const popoverDropdownRef = createRef();
  const openDropdownPopover = () => {
    new Popper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-3xl text-[#183558] mb-4 font-bold">
        How to get your vehicle
      </h1>
      <p className="text-sm text-[#757989] mb-10">
        No matter which option you select, you'll have 7 days with your new ride
        to love it, swap it, or return it.
      </p>
      <div className="flex flex-col gap-4">
        <div
          className={`p-8 pt-0 bg-white border rounded-lg  ${
            selectedDelivery === "Pick it ups"
              ? "border-[#00aed9]"
              : "border-[#d9dde9]"
          }`}
        >
          <div className="flex justify-between items-center pt-6 pb-5 border-b border-[#d9dde9] rounded px-6 mb-6">
            <label className="flex text-[#183558] mb-2 font-bold cursor-pointer">
              <input
                className="mr-2 mt-1 w-5 h-5 cursor-pointer"
                type="radio"
                checked={selectedDelivery === "Pick it up"}
                onClick={() => {
                  setSelectedDelivery("Pick it up");
                }}
                name=""
              />
              <div>
                <span className="mt-[1px]">Pick it up</span>
                <p className="text-xs font-normal text-[#757989]">
                  As soon as posssible
                </p>
              </div>
            </label>
            <h1 className="text-xl  text-[#183558] font-bold ">+$0</h1>
          </div>
          <div className="pb-6 border-b border-[#d9dde9]">
            <div className="px-6">
              <p className="text-sm text-[#757989]">
                Pick up your vehicle at a Renaissance location near you.{" "}
                <b>Note: this is not a Car Vending Machine location.</b>
              </p>
            </div>
          </div>
          <div>
            <div className="px-6 mt-5">
              <p className="text-sm text-[#183558] font-bold">
                {selectedpickUp}
              </p>
              <p className="text-sm text-[#757989]">
                201 37th St NW, Auburn, WA 98001
              </p>
              <p className="text-sm text-[#183558]">24 miles away</p>

              <>
                <div className="flex flex-wrap">
                  <div className="w-full">
                    <div className="relative inline-flex align-middle w-full">
                      <p
                        className="text-sm text-[#00aed9] cursor-pointer select-none"
                        style={{ transition: "all .15s ease" }}
                        type="button"
                        ref={btnDropdownRef}
                        onClick={() => {
                          dropdownPopoverShow
                            ? closeDropdownPopover()
                            : openDropdownPopover();
                        }}
                      >
                        Change
                      </p>
                      <div
                        ref={popoverDropdownRef}
                        className={
                          (dropdownPopoverShow ? "block " : "hidden ") +
                          "bg-white " +
                          "text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-60"
                        }
                      >
                        <div
                          onClick={() => {
                            setSelectedpickUp("Auburn, WA");
                            closeDropdownPopover();
                          }}
                          className={`cursor-pointer hover:bg-[#183558] hover:text-white py-4 px-3 text-sm font-bold uppercase flex justify-between items-center w-full whitespace-no-wrap ${
                            selectedpickUp !== "Auburn, WA"
                              ? "text-[#183558]"
                              : "text-[#d9dde9]"
                          }`}
                        >
                          <span>Auburn, WA</span>
                          <p className="text-[#757989] text-xs">24 miles</p>
                        </div>
                        <div
                          onClick={() => {
                            setSelectedpickUp("Wood Village, OR");
                            closeDropdownPopover();
                          }}
                          className={`cursor-pointer hover:bg-[#183558] hover:text-white py-4 px-3 text-sm font-bold uppercase flex justify-between items-center w-full whitespace-no-wrap ${
                            selectedpickUp !== "Wood Village, OR"
                              ? "text-[#183558]"
                              : "text-[#d9dde9]"
                          }`}
                        >
                          <span>Wood Village, OR</span>
                          <p className="text-[#757989] text-xs">181 Miles</p>
                        </div>
                        <div
                          onClick={() => {
                            setSelectedpickUp("Boise, ID");
                            closeDropdownPopover();
                          }}
                          className={`cursor-pointer hover:bg-[#183558] hover:text-white py-4 px-3 text-sm font-bold uppercase flex justify-between items-center w-full whitespace-no-wrap ${
                            selectedpickUp !== "Boise, ID"
                              ? "text-[#183558]"
                              : "text-[#d9dde9]"
                          }`}
                        >
                          <span>Boise, ID</span>
                          <p className="text-[#757989] text-xs">509 Miles</p>
                        </div>
                        <div
                          onClick={() => {
                            setSelectedpickUp("Lehi, UT");
                            closeDropdownPopover();
                          }}
                          className={`cursor-pointer hover:bg-[#183558] hover:text-white py-4 px-3 text-sm font-bold uppercase flex justify-between items-center w-full whitespace-no-wrap ${
                            selectedpickUp !== "Lehi, UT"
                              ? "text-[#183558]"
                              : "text-[#d9dde9]"
                          }`}
                        >
                          <span>Lehi, UT</span>
                          <p className="text-[#757989] text-xs">866 Miles</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        <div
          className={`p-8 pt-0 bg-white border rounded-lg  ${
            selectedDelivery === "Have it delivered"
              ? "border-[#00aed9]"
              : "border-[#d9dde9]"
          }`}
        >
          <div className="flex justify-between items-center pt-6 pb-5 border-b border-[#d9dde9] rounded px-6 mb-6">
            <label className="flex text-[#183558] mb-2 font-bold cursor-pointer">
              <input
                className="mr-2 mt-1 w-5 h-5 cursor-pointer"
                type="radio"
                checked={selectedDelivery === "Have it delivered"}
                onClick={() => {
                  setSelectedDelivery("Have it delivered");
                }}
                name=""
              />
              <div>
                <span className="mt-[1px]">Have it delivered</span>
                <p className="text-xs font-normal text-[#757989]">
                  As soon as posssible
                </p>
              </div>
            </label>
            <h1 className="text-xl  text-[#183558] font-bold ">+$490</h1>
          </div>
          <div className="px-6 mb-5 min-h-64 bg-[#f4f8fa] rounded-lg p-8 xl:flex justify-between">
            <img className="w-full h-full" src="/images/delivey.webp" alt="" />
            <div className="mt-4 xl:mt-0 xl:ml-5 flex flex-col justify-center">
              <p className="text-sm text-[#183558] font-bold mb-1">
                Hassle-free delivery
              </p>
              <p className="text-sm text-[#183558]">
                Let us come to you! We'll deliver your vehicle right to your
                doorstep.
              </p>
            </div>
          </div>
          <div className="border-t border-[#d9dde9]">
            <div className="px-6 mt-5">
              <p className="text-sm text-[#183558] font-bold mb-1">
                Delivery address
              </p>
              <p className="text-sm text-[#757989]">
                {response.step1?.deliveryAddress?.line1}{" "}
                {response.step1?.deliveryAddress?.line2}{" "}
                {response.step1?.deliveryAddress?.city}{" "}
                {response.step1?.deliveryAddress?.state}{" "}
                {response.step1?.deliveryAddress?.zipCode}
              </p>
              <p
                className="text-sm text-[#00aed9] cursor-pointer"
                onClick={() => {
                  setEditing(true);
                  setCurrentStep(1);
                }}
              >
                Change
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-10">
        <button
          disabled={loading}
          onClick={() => {
            setLoading(true);
            handleSaveFormData(
              {
                ...response,
                step4: {
                  ...response.step4,
                  completed: true,
                  deliveryOption: {
                    type: selectedDelivery,
                    address:
                      selectedDelivery === "Have it delivered"
                        ? { ...response.step1.deliveryAddress }
                        : {},
                    pickup:
                      selectedDelivery === "Have it delivered"
                        ? ""
                        : selectedpickUp,
                    price: selectedDelivery === "Have it delivered" ? 490 : 0,
                  },
                },
              },
              setLoading,
              (res) => {
                setCurrentStep((prev) => prev + 1);
                setResponse(res.data.data);
              }
            );
          }}
          className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
        >
          SAVE & CONTINUE
        </button>
      </div>
    </div>
  );
};

export default Delivery;
