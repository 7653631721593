import React from "react";
import { TextField } from "@mui/material";

const Personal = ({ fields, isCoBuyer }) => {
  const { errors, values, handleChange, handleBlur, touched } = fields;

  return (
    <div>
      <div className="border-b border-gray-300 pb-10">
        <h1 className="text-lg font-bold text-[#183558] pb-6 ">
          Buyer's Personal Info
        </h1>
        <div className="grid grid-cols-2 gap-4">
          {/* Buyer fields */}
          {["firstName", "lastName", "phone", "email", "ssn", "dob"].map(
            (field) => (
              <div key={field}>
                <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                  {field.replace(/([A-Z])/g, " $1").toUpperCase()}*
                </h1>
                <TextField
                  name={field}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values[field]}
                  fullWidth
                  error={Boolean(errors[field])}
                  helperText={errors[field]}
                  label=""
                  variant="outlined"
                  inputProps={{ style: { background: "white" } }}
                  type={field === "dob" ? "date" : "text"}
                />
              </div>
            )
          )}
        </div>
      </div>
      {isCoBuyer && (
        <div className="mt-10">
          <h1 className="text-lg font-bold text-[#183558] pb-6 ">
            Co-Buyer's Personal Info
          </h1>
          <div className="grid grid-cols-2 gap-4">
            {/* Co-Buyer fields */}
            {["firstName", "lastName", "phone", "email", "ssn", "dob"].map(
              (field) => (
                <div key={field}>
                  <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                    {field.replace(/([A-Z])/g, " $1").toUpperCase()}*
                  </h1>
                  <TextField
                    name={`coBuyer.${field}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.coBuyer?.[field]}
                    fullWidth
                    error={Boolean(errors.coBuyer?.[field])}
                    helperText={errors.coBuyer?.[field]}
                    label=""
                    variant="outlined"
                    inputProps={{ style: { background: "white" } }}
                    type={field === "dob" ? "date" : "text"}
                  />
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Personal;
