import React from "react";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const Employment = ({
  fields,
  isCoBuyer,
  setEmploymentStatus,
  setEmploymentStatusCoBuyer,
}) => {
  const {
    errors,
    values,
    setValues,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
  } = fields;
  return (
    <div>
      <div className="border-b border-gray-300 pb-10">
        <h1 className="text-lg font-bold text-[#183558] pb-6 ">
          Buyer's Employment Info
        </h1>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 mb-4">
            <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
              Employment Status*
            </h1>
            <div className="flex flex-wrap">
              {[
                "Full-time",
                "Part-time",
                "Selft-Employed",
                "Unemployed",
                "Retired Military",
                "Retired",
                "Contract",
                "Temporary",
                "Not Applicable",
              ].map((item) => (
                <div
                  className={`text-[#6F6D6F] text-sm cursor-pointer border border-[#DCDDDF] py-2 px-4 mb-4 flex items-center justify-center rounded-[5rem] mr-4 ${
                    values.employmentStatus === item
                      ? "text-black font-semibold bg-[#EAF1FB] border-[#EAF1FB]"
                      : ""
                  }`}
                  onClick={() => {
                    setFieldValue("employmentStatus", item);
                    setEmploymentStatus(item);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
            {errors.employmentStatus && (
              <p className="text-[#d32f2f] text-[0.75rem]">
                {errors.employmentStatus}
              </p>
            )}
          </div>
          {[
            "Full-time",
            "Part-time",
            "Selft-Employed",
            "Contract",
            "Temporary",
          ].includes(values.employmentStatus) && (
            <div className="col-span-2">
              <h1 className="text-base font-semibold text-[#183558] pb-2.5 ">
                Employment Details
              </h1>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                    JOB TITLE*
                  </h1>
                  <TextField
                    name="jobTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.jobTitle}
                    fullWidth
                    error={Boolean(errors.jobTitle)}
                    helperText={errors.jobTitle}
                    label=""
                    variant="outlined"
                    inputProps={{ style: { background: "white" } }}
                  />
                </div>

                <div>
                  <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                    EMPLOYER NAME
                  </h1>
                  <TextField
                    name="employerName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.employerName}
                    fullWidth
                    error={Boolean(errors.employerName)}
                    helperText={errors.employerName}
                    label=""
                    variant="outlined"
                    inputProps={{ style: { background: "white" } }}
                  />
                </div>

                <div>
                  <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                    EMPLOYER PHONE
                  </h1>
                  <TextField
                    type="number"
                    name="employerPhone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.employerPhone}
                    fullWidth
                    error={Boolean(errors.employerPhone)}
                    helperText={errors.employerPhone}
                    label=""
                    variant="outlined"
                    inputProps={{ style: { background: "white" } }}
                  />
                </div>

                <div className="mt-4 col-span-2">
                  <h1 className="text-base font-semibold text-[#183558] pb-4 ">
                    Employment Duration
                  </h1>
                  <div className="mb-4">
                    <label className="flex items-center text-[#183558] mb-2 cursor-pointer">
                      <input
                        className="mr-2 w-5 h-5 cursor-pointer"
                        type="radio"
                        checked={values.employmentDuration === "own"}
                        onClick={() => {
                          setValues({ ...values, employmentDuration: "own" });
                        }}
                      />
                      <span className="">More than 2 years</span>
                    </label>
                    <label className="flex items-center text-[#183558] mb-2 cursor-pointer">
                      <input
                        className="mr-2 w-5 h-5 cursor-pointer"
                        type="radio"
                        checked={values.employmentDuration === "rent"}
                        onClick={() => {
                          setValues({ ...values, employmentDuration: "rent" });
                        }}
                      />
                      <span className="">Less than 2 years</span>
                    </label>
                  </div>
                </div>

                <div>
                  <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                    YEARS*
                  </h1>
                  <TextField
                    name="employmentyears"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.employmentyears}
                    fullWidth
                    error={Boolean(errors.employmentyears)}
                    helperText={errors.employmentyears}
                    label=""
                    variant="outlined"
                    inputProps={{ style: { background: "white" } }}
                  />
                </div>

                <div>
                  <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                    MONTHS*
                  </h1>
                  <FormControl
                    fullWidth
                    error={Boolean(errors.employmentMonths)}
                  >
                    <Select
                      name="employmentMonths"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employmentMonths}
                      fullWidth
                      label=""
                      variant="outlined"
                      sx={{ background: "white" }}
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
                        (item, index) => (
                          <MenuItem
                            key={index}
                            value={item == "None" ? "" : item}
                          >
                            {item}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    <FormHelperText>{errors.employmentMonths}</FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isCoBuyer && (
        <div className="mt-10">
          <h1 className="text-lg font-bold text-[#183558] pb-6 ">
            Co-Buyer's Employment Info
          </h1>
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2 mb-4">
              <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                Employment Status*
              </h1>
              <div className="flex flex-wrap">
                {[
                  "Full-time",
                  "Part-time",
                  "Selft-Employed",
                  "Unemployed",
                  "Retired Military",
                  "Retired",
                  "Contract",
                  "Temporary",
                  "Not Applicable",
                ].map((item) => (
                  <div
                    className={`text-[#6F6D6F] text-sm cursor-pointer border border-[#DCDDDF] py-2 px-4 mb-4 flex items-center justify-center rounded-[5rem] mr-4 ${
                      values.coBuyer?.employmentStatus === item
                        ? "text-black font-semibold bg-[#EAF1FB] border-[#EAF1FB]"
                        : ""
                    }`}
                    onClick={() => {
                      setFieldValue("coBuyer.employmentStatus", item);
                      setEmploymentStatusCoBuyer(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
              {errors.coBuyer?.employmentStatus && (
                <p className="text-[#d32f2f] text-[0.75rem]">
                  {errors.coBuyer?.employmentStatus}
                </p>
              )}
            </div>
            {[
              "Full-time",
              "Part-time",
              "Selft-Employed",
              "Contract",
              "Temporary",
            ].includes(values.coBuyer?.employmentStatus) && (
              <div className="col-span-2">
                <h1 className="text-base font-semibold text-[#183558] pb-2.5 ">
                  Employment Details
                </h1>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                      JOB TITLE*
                    </h1>
                    <TextField
                      name="coBuyer.jobTitle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.coBuyer?.jobTitle}
                      fullWidth
                      error={Boolean(errors.coBuyer?.jobTitle)}
                      helperText={errors.coBuyer?.jobTitle}
                      label=""
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                  </div>

                  <div>
                    <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                      EMPLOYER NAME
                    </h1>
                    <TextField
                      name="coBuyer.employerName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.coBuyer?.employerName}
                      fullWidth
                      error={Boolean(errors.coBuyer?.employerName)}
                      helperText={errors.coBuyer?.employerName}
                      label=""
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                  </div>

                  <div>
                    <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                      EMPLOYER PHONE
                    </h1>
                    <TextField
                      type="number"
                      name="coBuyer.employerPhone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.coBuyer?.employerPhone}
                      fullWidth
                      error={Boolean(errors.coBuyer?.employerPhone)}
                      helperText={errors.coBuyer?.employerPhone}
                      label=""
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                  </div>

                  <div className="mt-4 col-span-2">
                    <h1 className="text-base font-semibold text-[#183558] pb-4 ">
                      Employment Duration
                    </h1>
                    <div className="mb-4">
                      <label className="flex items-center text-[#183558] mb-2 cursor-pointer">
                        <input
                          className="mr-2 w-5 h-5 cursor-pointer"
                          type="radio"
                          checked={values.coBuyer?.employmentDuration === "own"}
                          onClick={() => {
                            setValues({
                              ...values,
                              coBuyer: {
                                ...values.coBuyer,
                                employmentDuration: "own",
                              },
                            });
                          }}
                        />
                        <span className="">More than 2 years</span>
                      </label>
                      <label className="flex items-center text-[#183558] mb-2 cursor-pointer">
                        <input
                          className="mr-2 w-5 h-5 cursor-pointer"
                          type="radio"
                          checked={
                            values.coBuyer?.employmentDuration === "rent"
                          }
                          onClick={() => {
                            setValues({
                              ...values,
                              coBuyer: {
                                ...values.coBuyer,
                                employmentDuration: "rent",
                              },
                            });
                          }}
                        />
                        <span className="">Less than 2 years</span>
                      </label>
                    </div>
                  </div>

                  <div>
                    <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                      YEARS*
                    </h1>
                    <TextField
                      name="coBuyer.employmentyears"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.coBuyer?.employmentyears}
                      fullWidth
                      error={Boolean(errors.coBuyer?.employmentyears)}
                      helperText={errors.coBuyer?.employmentyears}
                      label=""
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                  </div>

                  <div>
                    <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                      MONTHS*
                    </h1>
                    <FormControl
                      fullWidth
                      error={Boolean(errors.coBuyer?.employmentMonths)}
                    >
                      <Select
                        name="coBuyer.employmentMonths"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.coBuyer?.employmentMonths}
                        fullWidth
                        label=""
                        variant="outlined"
                        sx={{ background: "white" }}
                      >
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
                          (item, index) => (
                            <MenuItem
                              key={index}
                              value={item == "None" ? "" : item}
                            >
                              {item}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      <FormHelperText>
                        {errors.coBuyer?.employmentMonths}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Employment;
