import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { getSavedFormData, saveFormData } from "../../api";
import Sidebar from "./Components/Sidebar";
import CancelOrder from "./Components/CancelOrder";
import CancelSuccess from "./Components/CancelSuccess";
import PersonalDetails from "./Components/PersonalDetails";
import TradeIn from "./Components/TradeIn";
import Finance from "./Components/Finance";
import Delivery from "./Components/Delivery";
import License from "./Components/License";
import Protection from "./Components/Protection";
import Payment from "./Components/Payment";
import Review from "./Components/Review";
import BankVerification from "./Components/BankVerification";
import { stepsdata } from "./stepsStructure";

const Order = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const productId = urlParams.get("productId");
  const id = urlParams.get("id");
  console.log(id);

  const [openPopup, setOpenPopup] = useState(false);
  const [cancelledPopup, setCancelledPopup] = useState(false);
  const [editing, setEditing] = useState(true);

  const [response, setResponse] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [activeStep, setActiveStep] = useState(1);
  const [getloading, setGetLoading] = useState(true);

  const handleSaveFormData = async (values, setIsLoading, onSuccess) => {
    await saveFormData(values)
      .then((res) => {
        setIsLoading(false);
        onSuccess(res);
        if (!id) {
          navigate(`/?id=${res.data.data._id}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.error);
      });
  };

  useEffect(() => {
    const fetchData = async (id) => {
      setGetLoading(true);
      try {
        let data;
        if (id) {
          console.log(id);
          const res = await getSavedFormData(id);
          data = res.data.data;
        } else {
          data = stepsdata;
        }
        if (data.step1.completed === false) {
          setCurrentStep(1);
          setActiveStep(1);
        } else if (data.step2.completed === false) {
          setCurrentStep(2);
          setActiveStep(2);
        } else if (data.step3.completed === false) {
          setCurrentStep(3);
          setActiveStep(3);
        } else if (data.step4.completed === false) {
          setCurrentStep(4);
          setActiveStep(4);
        } else if (data.step5.completed === false) {
          setCurrentStep(5);
          setActiveStep(5);
        } else if (data.step6.completed === false) {
          setCurrentStep(6);
          setActiveStep(6);
        } else if (data.step7.completed === false) {
          setCurrentStep(7);
          setActiveStep(7);
        } else if (data.step8.completed === false) {
          setCurrentStep(8);
          setActiveStep(8);
        }
        setResponse(data);
      } catch (err) {
        toast.error(err.response.data.error);
      } finally {
        setGetLoading(false);
      }
    };

    fetchData(id ? id : false);
  }, []);

  useEffect(() => {
    if (activeStep !== 1) {
      setEditing(false);
    }
  }, [activeStep]);

  return (
    <div>
      {openPopup && (
        <CancelOrder
          setOpenPopup={setOpenPopup}
          setCancelledPopup={setCancelledPopup}
          id={id}
        />
      )}
      {cancelledPopup && <CancelSuccess setOpenPopup={setCancelledPopup} />}
      {getloading ? (
        <div className="min-h-screen flex justify-center items-center">
          <div>
            <ThreeDots
              height="40"
              width="80"
              radius="9"
              color="#000"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
              }}
              wrapperClassName="spinner"
              visible={true}
            />
          </div>
        </div>
      ) : (
        <div className="flex min-h-screen w-full">
          <Sidebar
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            response={response}
            setOpenPopup={setOpenPopup}
            id={id}
          />
          <div className="w-[calc(100%-384px)] py-20 px-5 md:px-10 lg:px-[10%] box-border bg-[#f4f8fa]">
            {currentStep === 1 && (
              <PersonalDetails
                response={response}
                setResponse={setResponse}
                setCurrentStep={setCurrentStep}
                handleSaveFormData={handleSaveFormData}
                editing={editing}
              />
            )}
            {currentStep === 2 && (
              <TradeIn
                response={response}
                setResponse={setResponse}
                setCurrentStep={setCurrentStep}
                handleSaveFormData={handleSaveFormData}
              />
            )}
            {currentStep === 3 && (
              <Finance
                response={response}
                setResponse={setResponse}
                setCurrentStep={setCurrentStep}
                handleSaveFormData={handleSaveFormData}
                setEditing={setEditing}
              />
            )}
            {currentStep === 4 && (
              <Delivery
                response={response}
                setResponse={setResponse}
                setCurrentStep={setCurrentStep}
                handleSaveFormData={handleSaveFormData}
                setEditing={setEditing}
              />
            )}
            {currentStep === 5 && (
              <Protection
                response={response}
                setResponse={setResponse}
                setCurrentStep={setCurrentStep}
                handleSaveFormData={handleSaveFormData}
              />
            )}
            {currentStep === 6 && (
              <License
                response={response}
                setResponse={setResponse}
                setCurrentStep={setCurrentStep}
                handleSaveFormData={handleSaveFormData}
              />
            )}
            {currentStep === 7 && (
              <Payment
                id={id}
                response={response}
                setResponse={setResponse}
                setCurrentStep={setCurrentStep}
                handleSaveFormData={handleSaveFormData}
              />
            )}
            {currentStep === 8 && (
              <Review
                response={response}
                setResponse={setResponse}
                setCurrentStep={setCurrentStep}
                handleSaveFormData={handleSaveFormData}
                setEditing={setEditing}
              />
            )}
            {currentStep === 9 && (
              <BankVerification
                response={response}
                setResponse={setResponse}
                setCurrentStep={setCurrentStep}
                handleSaveFormData={handleSaveFormData}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Order;
