import React from "react";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const Residential = ({
  fields,
  isCoBuyer,
  setResidenceType,
  setResidenceTypeCoBuyer,
}) => {
  const {
    errors,
    values,
    setValues,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
  } = fields;
  return (
    <div>
      <div className="border-b border-gray-300 pb-10">
        <h1 className="text-lg font-bold text-[#183558] pb-6 ">
          Buyer's Residential Info
        </h1>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
              ADDRESS*
            </h1>
            <TextField
              name="address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              fullWidth
              error={Boolean(errors.address)}
              helperText={errors.address}
              label=""
              variant="outlined"
              inputProps={{ style: { background: "white" } }}
            />
          </div>

          <div>
            <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
              APARTMENT/UNIT
            </h1>
            <TextField
              name="appartment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.appartment}
              fullWidth
              error={Boolean(errors.appartment)}
              helperText={errors.appartment}
              label=""
              variant="outlined"
              inputProps={{ style: { background: "white" } }}
            />
          </div>

          <div>
            <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
              CITY*
            </h1>
            <TextField
              name="city"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              fullWidth
              error={Boolean(errors.city)}
              helperText={errors.city}
              label=""
              variant="outlined"
              inputProps={{ style: { background: "white" } }}
            />
          </div>

          <div>
            <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
              STATE*
            </h1>
            <TextField
              name="state"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state}
              fullWidth
              error={Boolean(errors.state)}
              helperText={errors.state}
              label=""
              variant="outlined"
              inputProps={{ style: { background: "white" } }}
            />
          </div>

          <div>
            <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
              ZIP CODE*
            </h1>
            <TextField
              name="zip"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zip}
              fullWidth
              error={Boolean(errors.zip)}
              helperText={errors.zip}
              label=""
              variant="outlined"
              inputProps={{ style: { background: "white" } }}
            />
          </div>
          <div className="mt-4 col-span-2 mb-4">
            <h1 className="text-base font-semibold text-[#183558] pb-4 ">
              Buyer’s Residence Type
            </h1>
            <div className="flex items-center">
              <label className="flex items-center text-[#183558] mb-2 cursor-pointer">
                <input
                  className="mr-2 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={values.residenceType === "own"}
                  onClick={() => {
                    setValues({ ...values, residenceType: "own" });
                    setResidenceType("own");
                  }}
                />
                <span className="">I own</span>
              </label>
              <label className="flex items-center text-[#183558] mb-2 cursor-pointer ml-6">
                <input
                  className="mr-2 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={values.residenceType === "rent"}
                  onClick={() => {
                    setValues({ ...values, residenceType: "rent" });
                    setResidenceType("rent");
                  }}
                />
                <span className="">I rent</span>
              </label>
              <label className="flex items-center text-[#183558] mb-2 cursor-pointer ml-6">
                <input
                  className="mr-2 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={values.residenceType === "relative"}
                  onClick={() => {
                    setValues({ ...values, residenceType: "relative" });
                    setResidenceType("relative");
                  }}
                />
                <span className="">I live with relatives</span>
              </label>
              <label className="flex items-center text-[#183558] mb-2 cursor-pointer ml-6">
                <input
                  className="mr-2 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={values.residenceType === "other"}
                  onClick={() => {
                    setValues({ ...values, residenceType: "other" });
                    setResidenceType("other");
                  }}
                />
                <span className="">Other</span>
              </label>
            </div>
            {errors.residenceType && (
              <p className="text-[#d32f2f] text-[0.75rem]">
                {errors.residenceType}
              </p>
            )}
          </div>

          <div className="col-span-2 mb-4">
            <h1 className="text-base font-semibold text-[#183558] pb-2.5 ">
              Monthly Rent / Mortgage Payment Amount
            </h1>
            <p className="text-sm mb-4">
              Please enter your monthly rent / mortgage payment below
            </p>
            <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
              Monthly Payment
            </h1>
            <div className="w-1/2">
              <TextField
                name="monthlyPayment"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.monthlyPayment}
                fullWidth
                error={Boolean(errors.monthlyPayment)}
                helperText={errors.monthlyPayment}
                label=""
                variant="outlined"
                inputProps={{ style: { background: "white" } }}
              />
            </div>
          </div>

          <div className="mt-4 col-span-2 mb-4">
            <h1 className="text-base font-semibold text-[#183558] pb-4 ">
              Time at Address
            </h1>
            <div className="flex items-center">
              <label className="flex items-center text-[#183558] mb-2 cursor-pointer">
                <input
                  className="mr-2 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={values.timeAtAddress === "More than 2 years"}
                  onClick={() => {
                    setValues({
                      ...values,
                      timeAtAddress: "More than 2 years",
                    });
                  }}
                />
                <span className="">More than 2 years</span>
              </label>
              <label className="flex items-center text-[#183558] mb-2 cursor-pointer ml-6">
                <input
                  className="mr-2 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={values.timeAtAddress === "Less than 2 years"}
                  onClick={() => {
                    setValues({
                      ...values,
                      timeAtAddress: "Less than 2 years",
                    });
                  }}
                />
                <span className="">Less than 2 years</span>
              </label>
            </div>
            {errors.timeAtAddress && (
              <p className="text-[#d32f2f] text-[0.75rem]">
                {errors.timeAtAddress}
              </p>
            )}
          </div>

          <div>
            <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
              YEARS*
            </h1>
            <TextField
              name="years"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.years}
              fullWidth
              error={Boolean(errors.years)}
              helperText={errors.years}
              label=""
              variant="outlined"
              inputProps={{ style: { background: "white" } }}
            />
          </div>

          <div>
            <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
              MONTHS*
            </h1>
            <FormControl fullWidth error={Boolean(errors.months)}>
              <Select
                name="months"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.months}
                fullWidth
                label=""
                variant="outlined"
                sx={{ background: "white" }}
              >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, index) => (
                  <MenuItem key={index} value={item == "None" ? "" : item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.months}</FormHelperText>
            </FormControl>
          </div>
        </div>
      </div>
      {isCoBuyer && (
        <div className="mt-10">
          <h1 className="text-lg font-bold text-[#183558] pb-6 ">
            Co-Buyer's Residential Info
          </h1>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                ADDRESS*
              </h1>
              <TextField
                name="coBuyer.address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.coBuyer?.address}
                fullWidth
                error={Boolean(errors.coBuyer?.address)}
                helperText={errors.coBuyer?.address}
                label=""
                variant="outlined"
                inputProps={{ style: { background: "white" } }}
              />
            </div>

            <div>
              <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                APARTMENT/UNIT
              </h1>
              <TextField
                name="coBuyer.appartment"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.coBuyer?.appartment}
                fullWidth
                error={Boolean(errors.coBuyer?.appartment)}
                helperText={errors.coBuyer?.appartment}
                label=""
                variant="outlined"
                inputProps={{ style: { background: "white" } }}
              />
            </div>

            <div>
              <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                CITY*
              </h1>
              <TextField
                name="coBuyer.city"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.coBuyer?.city}
                fullWidth
                error={Boolean(errors.coBuyer?.city)}
                helperText={errors.coBuyer?.city}
                label=""
                variant="outlined"
                inputProps={{ style: { background: "white" } }}
              />
            </div>

            <div>
              <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                STATE*
              </h1>
              <TextField
                name="coBuyer.state"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.coBuyer?.state}
                fullWidth
                error={Boolean(errors.coBuyer?.state)}
                helperText={errors.coBuyer?.state}
                label=""
                variant="outlined"
                inputProps={{ style: { background: "white" } }}
              />
            </div>

            <div>
              <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                ZIP CODE*
              </h1>
              <TextField
                name="coBuyer.zip"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.coBuyer?.zip}
                fullWidth
                error={Boolean(errors.coBuyer?.zip)}
                helperText={errors.coBuyer?.zip}
                label=""
                variant="outlined"
                inputProps={{ style: { background: "white" } }}
              />
            </div>
            <div className="mt-4 col-span-2 mb-4">
              <h1 className="text-base font-semibold text-[#183558] pb-4 ">
                Buyer’s Residence Type
              </h1>
              <div className="flex items-center">
                <label className="flex items-center text-[#183558] mb-2 cursor-pointer">
                  <input
                    className="mr-2 w-5 h-5 cursor-pointer"
                    type="radio"
                    checked={values.coBuyer?.residenceType === "own"}
                    onClick={() => {
                      setValues({
                        ...values,
                        coBuyer: {
                          ...values.coBuyer,
                          residenceType: "own",
                        },
                      });
                      setResidenceTypeCoBuyer("own");
                    }}
                  />
                  <span className="">I own</span>
                </label>
                <label className="flex items-center text-[#183558] mb-2 cursor-pointer ml-6">
                  <input
                    className="mr-2 w-5 h-5 cursor-pointer"
                    type="radio"
                    checked={values.coBuyer?.residenceType === "rent"}
                    onClick={() => {
                      setValues({
                        ...values,
                        coBuyer: {
                          ...values.coBuyer,
                          residenceType: "rent",
                        },
                      });
                      setResidenceTypeCoBuyer("own");
                    }}
                  />
                  <span className="">I rent</span>
                </label>
                <label className="flex items-center text-[#183558] mb-2 cursor-pointer ml-6">
                  <input
                    className="mr-2 w-5 h-5 cursor-pointer"
                    type="radio"
                    checked={values.coBuyer?.residenceType === "relative"}
                    onClick={() => {
                      setValues({
                        ...values,
                        coBuyer: {
                          ...values.coBuyer,
                          residenceType: "relative",
                        },
                      });
                      setResidenceTypeCoBuyer("relative");
                    }}
                  />
                  <span className="">I live with relatives</span>
                </label>
                <label className="flex items-center text-[#183558] mb-2 cursor-pointer ml-6">
                  <input
                    className="mr-2 w-5 h-5 cursor-pointer"
                    type="radio"
                    checked={values.coBuyer?.residenceType === "other"}
                    onClick={() => {
                      setValues({
                        ...values,
                        coBuyer: {
                          ...values.coBuyer,
                          residenceType: "other",
                        },
                      });
                      setResidenceTypeCoBuyer("other");
                    }}
                  />
                  <span className="">Other</span>
                </label>
              </div>
              {errors.coBuyer?.residenceType && (
                <p className="text-[#d32f2f] text-[0.75rem]">
                  {errors.coBuyer?.residenceType}
                </p>
              )}
            </div>

            <div className="col-span-2 mb-4">
              <h1 className="text-base font-semibold text-[#183558] pb-2.5 ">
                Monthly Rent / Mortgage Payment Amount
              </h1>
              <p className="text-sm mb-4">
                Please enter your monthly rent / mortgage payment below
              </p>
              <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                Monthly Payment
              </h1>
              <div className="w-1/2">
                <TextField
                  name="coBuyer.monthlyPayment"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.coBuyer?.monthlyPayment}
                  fullWidth
                  error={Boolean(errors.coBuyer?.monthlyPayment)}
                  helperText={errors.coBuyer?.monthlyPayment}
                  label=""
                  variant="outlined"
                  inputProps={{ style: { background: "white" } }}
                />
              </div>
            </div>

            <div className="mt-4 col-span-2 mb-4">
              <h1 className="text-base font-semibold text-[#183558] pb-4 ">
                Time at Address
              </h1>
              <div className="flex items-center">
                <label className="flex items-center text-[#183558] mb-2 cursor-pointer">
                  <input
                    className="mr-2 w-5 h-5 cursor-pointer"
                    type="radio"
                    checked={
                      values.coBuyer?.timeAtAddress === "More than 2 years"
                    }
                    onClick={() => {
                      setValues({
                        ...values,
                        coBuyer: {
                          ...values.coBuyer,
                          timeAtAddress: "More than 2 years",
                        },
                      });
                    }}
                  />
                  <span className="">More than 2 years</span>
                </label>
                <label className="flex items-center text-[#183558] mb-2 cursor-pointer ml-6">
                  <input
                    className="mr-2 w-5 h-5 cursor-pointer"
                    type="radio"
                    checked={
                      values.coBuyer?.timeAtAddress === "Less than 2 years"
                    }
                    onClick={() => {
                      setValues({
                        ...values,
                        coBuyer: {
                          ...values.coBuyer,
                          timeAtAddress: "Less than 2 years",
                        },
                      });
                    }}
                  />
                  <span className="">Less than 2 years</span>
                </label>
              </div>
              {errors.coBuyer?.timeAtAddress && (
                <p className="text-[#d32f2f] text-[0.75rem]">
                  {errors.coBuyer?.timeAtAddress}
                </p>
              )}
            </div>

            <div>
              <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                YEARS*
              </h1>
              <TextField
                name="coBuyer.years"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.coBuyer?.years}
                fullWidth
                error={Boolean(errors.coBuyer?.years)}
                helperText={errors.coBuyer?.years}
                label=""
                variant="outlined"
                inputProps={{ style: { background: "white" } }}
              />
            </div>

            <div>
              <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                MONTHS*
              </h1>
              <FormControl fullWidth error={Boolean(errors.coBuyer?.months)}>
                <Select
                  name="coBuyer.months"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.coBuyer?.months}
                  fullWidth
                  label=""
                  variant="outlined"
                  sx={{ background: "white" }}
                >
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, index) => (
                    <MenuItem key={index} value={item == "None" ? "" : item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.coBuyer?.months}</FormHelperText>
              </FormControl>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Residential;
