import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Tabs from "./Tabs";
import Personal from "./Personal";
import Residential from "./Residential";
import Employment from "./Employment";
import Income from "./Income";
import ReviewSubmit from "./ReviewSubmit";

const CreditApp = ({
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
  coBuyer,
  isCredit,
}) => {
  console.log(coBuyer);
  
  const [step, setStep] = useState(1);
  const [isCoBuyer, setIsCoBuyer] = useState(coBuyer);
  const [residenceType, setResidenceType] = useState("");
  const [residenceTypeCoBuyer, setResidenceTypeCoBuyer] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState("");
  const [employmentStatusCoBuyer, setEmploymentStatusCoBuyer] = useState("");

  const employed = [
    "Full-time",
    "Part-time",
    "Selft-Employed",
    "Contract",
    "Temporary",
  ];

  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is mandatory"),
    lastName: Yup.string().required("This field is mandatory"),
    phone: Yup.string().required("This field is mandatory"),
    email: Yup.string().required("This field is mandatory"),
    ssn: Yup.string().required("This field is mandatory"),
    dob: Yup.string().required("This field is mandatory"),
    address: Yup.string().required("This field is mandatory"),
    appartment: Yup.string(),
    city: Yup.string().required("This field is mandatory"),
    state: Yup.string().required("This field is mandatory"),
    zip: Yup.string().required("This field is mandatory"),
    residenceType: Yup.string().required("This field is mandatory"),
    monthlyPayment:
      residenceType === "own" || residenceType === "other"
        ? Yup.string()
        : Yup.string().required("This field is mandatory"),
    timeAtAddress: Yup.string().required("This field is mandatory"),
    years: Yup.string().required("This field is mandatory"),
    months: Yup.string().required("This field is mandatory"),
    employmentStatus: Yup.string().required("This field is mandatory"),
    jobTitle: !employed.includes(employmentStatus)
      ? Yup.string()
      : Yup.string().required("This field is mandatory"),
    employerName: !employed.includes(employmentStatus)
      ? Yup.string()
      : Yup.string().required("This field is mandatory"),
    employerPhone: !employed.includes(employmentStatus)
      ? Yup.string()
      : Yup.string().required("This field is mandatory"),
    employmentDuration: !employed.includes(employmentStatus)
      ? Yup.string()
      : Yup.string(),
    employmentyears: !employed.includes(employmentStatus)
      ? Yup.string()
      : Yup.string().required("This field is mandatory"),
    employmentMonths: !employed.includes(employmentStatus)
      ? Yup.string()
      : Yup.string().required("This field is mandatory"),
    incomeFrequency: Yup.string().required("This field is mandatory"),
    grossIncome: Yup.string().required("This field is mandatory"),
    toc1: Yup.boolean()
      .required("This field is mandatory")
      .oneOf([true], "This field is mandatory"),
    toc2: Yup.boolean()
      .required("This field is mandatory")
      .oneOf([true], "This field is mandatory"),
    toc3: Yup.boolean()
      .required("This field is mandatory")
      .oneOf([true], "This field is mandatory"),
    toc4: Yup.boolean()
      .required("This field is mandatory")
      .oneOf([true], "This field is mandatory"),
    toc5: Yup.boolean()
      .required("This field is mandatory")
      .oneOf([true], "This field is mandatory"),
    coBuyer: !isCoBuyer
      ? Yup.object()
      : Yup.object().shape({
          firstName: Yup.string().required("This field is mandatory"),
          lastName: Yup.string().required("This field is mandatory"),
          phone: Yup.string().required("This field is mandatory"),
          email: Yup.string().required("This field is mandatory"),
          ssn: Yup.string().required("This field is mandatory"),
          dob: Yup.string().required("This field is mandatory"),
          address: Yup.string().required("This field is mandatory"),
          appartment: Yup.string(),
          city: Yup.string().required("This field is mandatory"),
          state: Yup.string().required("This field is mandatory"),
          zip: Yup.string().required("This field is mandatory"),
          residenceType: Yup.string().required("This field is mandatory"),
          monthlyPayment:
            residenceTypeCoBuyer === "own" || residenceTypeCoBuyer === "other"
              ? Yup.string()
              : Yup.string().required("This field is mandatory"),
          timeAtAddress: Yup.string(),
          years: Yup.string().required("This field is mandatory"),
          months: Yup.string().required("This field is mandatory"),
          employmentStatus: Yup.string().required("This field is mandatory"),
          jobTitle: !employed.includes(employmentStatusCoBuyer)
            ? Yup.string()
            : Yup.string().required("This field is mandatory"),
          employerName: !employed.includes(employmentStatusCoBuyer)
            ? Yup.string()
            : Yup.string().required("This field is mandatory"),
          employerPhone: !employed.includes(employmentStatusCoBuyer)
            ? Yup.string()
            : Yup.string().required("This field is mandatory"),
          employmentDuration: !employed.includes(employmentStatusCoBuyer)
            ? Yup.string()
            : Yup.string(),
          employmentyears: !employed.includes(employmentStatusCoBuyer)
            ? Yup.string()
            : Yup.string().required("This field is mandatory"),
          employmentMonths: !employed.includes(employmentStatusCoBuyer)
            ? Yup.string()
            : Yup.string().required("This field is mandatory"),
          incomeFrequency: Yup.string().required("This field is mandatory"),
          grossIncome: Yup.string().required("This field is mandatory"),
        }),
  });

  const simplifiedExpr = isCredit
    ? response
    : response.step3.carvanaFinance?.creditInfo;

  return (
    <>
      <div className="mx-auto">
        <h1 className="text-xl text-[#183558] mb-4 font-bold">
          Credit Application <span className="text-sm">(4 mins)</span>
        </h1>
        <div>
          <p className="text-sm text-[#757989] mb-10">
            Please select your application type to fill in the information below
            for us to process your application
          </p>
        </div>
      </div>
      <Tabs step={step} setStep={setStep} />
      <div className="mt-5">
        <Formik
          initialValues={
            simplifiedExpr
              ? {
                  ...simplifiedExpr,
                  coBuyer: simplifiedExpr?.coBuyer
                    ? {
                        ...simplifiedExpr?.coBuyer,
                      }
                    : !isCoBuyer
                    ? {}
                    : {
                        firstName: "",
                        lastName: "",
                        phone: "",
                        email: "",
                        ssn: "",
                        dob: "",
                        address: "",
                        appartment: "",
                        city: "",
                        state: "",
                        zip: "",
                        residenceType: "",
                        monthlyPayment: "",
                        timeAtAddress: "",
                        years: "",
                        months: "",
                        employmentStatus: "",
                        jobTitle: "",
                        employerName: "",
                        employerPhone: "",
                        employmentDuration: "",
                        employmentyears: "",
                        employmentMonths: "",
                        incomeFrequency: "",
                        grossIncome: "",
                      },
                }
              : {
                  firstName: "",
                  lastName: "",
                  phone: "",
                  email: "",
                  ssn: "",
                  dob: "",
                  address: "",
                  appartment: "",
                  city: "",
                  state: "",
                  zip: "",
                  residenceType: "",
                  monthlyPayment: "",
                  timeAtAddress: "",
                  years: "",
                  months: "",
                  employmentStatus: "",
                  jobTitle: "",
                  employerName: "",
                  employerPhone: "",
                  employmentDuration: "",
                  employmentyears: "",
                  employmentMonths: "",
                  incomeFrequency: "",
                  grossIncome: "",
                  coBuyer: !isCoBuyer
                    ? {}
                    : {
                        firstName: "",
                        lastName: "",
                        phone: "",
                        email: "",
                        ssn: "",
                        dob: "",
                        address: "",
                        appartment: "",
                        city: "",
                        state: "",
                        zip: "",
                        residenceType: "",
                        monthlyPayment: "",
                        timeAtAddress: "",
                        years: "",
                        months: "",
                        employmentStatus: "",
                        jobTitle: "",
                        employerName: "",
                        employerPhone: "",
                        employmentDuration: "",
                        employmentyears: "",
                        employmentMonths: "",
                        incomeFrequency: "",
                        grossIncome: "",
                      },
                }
          }
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            const data = JSON.parse(JSON.stringify(values));
            if (!coBuyer) {
              delete data.coBuyer;
            } else {
              // delete data["coBuyer?"];
            }
            console.log(data);
            handleSaveFormData(
              isCredit
                ? { ...data }
                : {
                    ...response,
                    step3: {
                      ...response.step3,
                      carvanaFinance: {
                        ...response.step3.carvanaFinance,
                        creditInfo: data,
                      },
                      completed: true,
                    },
                  },
              setSubmitting,
              (res) => {
                setCurrentStep((prev) => prev + 1);
                setResponse(res.data);
              }
            );
          }}
          validationSchema={ValidationSchema}
        >
          {(fields) => (
            <Form>
              {step == 1 && <Personal fields={fields} isCoBuyer={isCoBuyer} />}
              {step == 2 && (
                <Residential
                  fields={fields}
                  isCoBuyer={isCoBuyer}
                  setResidenceType={setResidenceType}
                  setResidenceTypeCoBuyer={setResidenceTypeCoBuyer}
                />
              )}
              {step == 3 && (
                <Employment
                  fields={fields}
                  isCoBuyer={isCoBuyer}
                  setEmploymentStatus={setEmploymentStatus}
                  setEmploymentStatusCoBuyer={setEmploymentStatusCoBuyer}
                />
              )}
              {step == 4 && <Income fields={fields} isCoBuyer={isCoBuyer} />}
              {step == 5 && (
                <ReviewSubmit
                  fields={fields}
                  isCoBuyer={isCoBuyer}
                  setIsCoBuyer={setIsCoBuyer}
                  setStep={setStep}
                />
              )}
              {step < 5 ? (
                <button
                  type="button"
                  onClick={() => {
                    if (step < 5) {
                      setStep(step + 1);
                    }
                  }}
                  className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9] mt-10  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                >
                  SAVE & CONTINUE
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={fields.isSubmitting}
                  className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9] mt-10  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                >
                  SUBMIT & PROCEED
                </button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreditApp;
