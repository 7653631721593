import React, { useState } from "react";
import SelfArrangedFinance from "./SelfArrangedFinance";
import CarvanaFinance from "./CarvanaFinance";
import CreditApp from "../../Finance";

const Finance = ({
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
  setEditing,
}) => {
  const [loading, setLoading] = useState();
  const [selectedFinance, setSelectedFinance] = useState(
    response.step3.paymentMethod
  );

  const [showCarvanaForm, setShowCarvanaForm] = useState(
    response.step3.paymentMethod === "Finance with Renaissance"
  );
  const [showSelfForm, setShowSelfForm] = useState(
    response.step3.paymentMethod === "Self-Arranged Finance"
  );
  const [showCreditApp, setShowCreditAPp] = useState();

  return showSelfForm ? (
    <SelfArrangedFinance
      response={response}
      setResponse={setResponse}
      setCurrentStep={setCurrentStep}
      handleSaveFormData={handleSaveFormData}
      setSelectedFinance={setSelectedFinance}
      setShowSelfForm={setShowSelfForm}
    />
  ) : showCarvanaForm ? (
    <CarvanaFinance
      response={response}
      setResponse={setResponse}
      setCurrentStep={setCurrentStep}
      handleSaveFormData={handleSaveFormData}
      setShowCarvanaForm={setShowCarvanaForm}
      setSelectedFinance={setSelectedFinance}
      setEditing={setEditing}
      setShowCreditAPp={setShowCreditAPp}
    />
  ) : showCreditApp ? (
    <div className="min-h-screen w-full pt-5">
      <div className="py-8 px-5 md:px-10 lg:px-[5%] box-border bg-[white] max-w-4xl mx-auto rounded-lg">
        <CreditApp
          response={response}
          setResponse={setResponse}
          setCurrentStep={setCurrentStep}
          handleSaveFormData={handleSaveFormData}
          coBuyer={showCreditApp === 2}
        />
      </div>
    </div>
  ) : (
    showCarvanaForm === false &&
    showSelfForm === false && (
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl text-[#183558] mb-4 font-bold">
          Cash or finance?
        </h1>
        <p className="text-sm text-[#757989] mb-10">
          How would you like to pay for your 2017 Land Rover Range Rover Evoque
          SE Premium Sport Utility 4D? Please make your selection below.
        </p>
        <div className="flex flex-col gap-4">
          <div
            className={`p-8 pt-0 bg-white border hover:border-[#00aed9] rounded-lg ${
              selectedFinance === "Finance with Renaissance"
                ? "border-[#00aed9]"
                : "border-[#d9dde9]"
            }`}
          >
            <div className="pt-6 pb-5 border-b border-[#d9dde9] rounded px-6 mb-6">
              <label className="flex items-center text-[#183558] mb-2 font-bold cursor-pointer">
                <input
                  className="mr-2 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={selectedFinance === "Finance with Renaissance"}
                  onClick={() => {
                    setSelectedFinance("Finance with Renaissance");
                  }}
                  name=""
                />
                <span className="mt-[1px]">Finance with Renaissance</span>
              </label>
            </div>
            <div className="flex items-center px-6">
              <img className="h-7 mr-2" src="/images/carvanapay.png" alt="" />
              <p className="text-[#183558]">
                78% of customers choose this option
              </p>
            </div>
            <div className="px-6">
              <ul className="list-disc list-inside">
                <li className="text-sm text-[#757989] mb-1">
                  Get your terms in less than 2 minutes.
                </li>
                <li className="text-sm text-[#757989] mb-1">
                  Choose a down payment and monthly payments that work for you.
                </li>
                <li className="text-sm text-[#757989] mb-1">
                  Enjoy building your custom deal from the comfort of your home!
                </li>
              </ul>
            </div>
          </div>
          <div
            className={`p-8 pt-0 bg-white border hover:border-[#00aed9] rounded-lg ${
              selectedFinance === "Pay with cash"
                ? "border-[#00aed9]"
                : "border-[#d9dde9]"
            }`}
          >
            <div className="pt-6 pb-5 border-b border-[#d9dde9] rounded px-6 mb-6">
              <label className="flex items-center text-[#183558] mb-2 font-bold cursor-pointer">
                <input
                  className="mr-2 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={selectedFinance === "Pay with cash"}
                  onClick={() => {
                    setSelectedFinance("Pay with cash");
                  }}
                  name=""
                />
                <span className="mt-[1px]">Pay with cash</span>
              </label>
            </div>
            <div className="flex items-center px-6">
              <img className="h-7 mr-2" src="/images/cashpay.png" alt="" />
              <p className="text-[#183558]">
                I plan to pay with funds from my bank account.
              </p>
            </div>
          </div>
          <div
            className={`p-8 pt-0 bg-white border hover:border-[#00aed9] rounded-lg ${
              selectedFinance === "Self-Arranged Finance"
                ? "border-[#00aed9]"
                : "border-[#d9dde9]"
            }`}
          >
            <div className="pt-6 pb-5 border-b border-[#d9dde9] rounded px-6 mb-6">
              <label className="flex items-center text-[#183558] mb-2 font-bold cursor-pointer">
                <input
                  className="mr-2 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={selectedFinance === "Self-Arranged Finance"}
                  onClick={() => {
                    setSelectedFinance("Self-Arranged Finance");
                  }}
                  name=""
                />
                <span className="mt-[1px]">Self-Arranged Finance</span>
              </label>
            </div>
            <div className="flex items-center px-6">
              <img className="h-7 mr-2" src="/images/selffinance.png" alt="" />
              <p className="text-[#183558]">
                I am pre-approved for financing from another lender.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <button
            disabled={!selectedFinance || loading}
            onClick={() => {
              setLoading(true);
              handleSaveFormData(
                {
                  ...response,
                  step3: {
                    ...response.step3,
                    completed:
                      selectedFinance === "Pay with cash" ? true : false,
                    paymentMethod: selectedFinance,
                  },
                },
                setLoading,
                (res) => {
                  if (selectedFinance === "Pay with cash") {
                    setCurrentStep((prev) => prev + 1);
                  } else if (selectedFinance === "Finance with Renaissance") {
                    setShowCarvanaForm(true);
                  } else {
                    setShowSelfForm(true);
                  }
                  setResponse(res.data.data);
                }
              );
            }}
            className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
          >
            SAVE & CONTINUE
          </button>
        </div>
      </div>
    )
  );
};

export default Finance;
