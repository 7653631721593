import React from "react";

const Tabs = ({ step, setStep }) => {
  return (
    <div>
      {" "}
      <div className="flex font-semibold text-gray-600 gap-10 border-b border-gray-400">
        <div
          className={`${
            step === 1 ? "border-b-4 border-blue-500" : ""
          } pb-1 cursor-pointer select-none`}
          onClick={() => {
            setStep(1);
          }}
        >
          Personal
        </div>
        <div
          className={`${
            step === 2 ? "border-b-4 border-blue-500" : ""
          } pb-1 cursor-pointer select-none`}
          onClick={() => {
            setStep(2);
          }}
        >
          Residential
        </div>
        <div
          className={`${
            step === 3 ? "border-b-4 border-blue-500" : ""
          } pb-1 cursor-pointer select-none`}
          onClick={() => {
            setStep(3);
          }}
        >
          Employment
        </div>
        <div
          className={`${
            step === 4 ? "border-b-4 border-blue-500" : ""
          } pb-1 cursor-pointer select-none`}
          onClick={() => {
            setStep(4);
          }}
        >
          Income
        </div>
        <div
          className={`${
            step === 5 ? "border-b-4 border-blue-500" : ""
          } pb-1 cursor-pointer select-none`}
          onClick={() => {
            setStep(5);
          }}
        >
          Review & Submit
        </div>
      </div>
    </div>
  );
};

export default Tabs;
