import React from "react";

const Sidebar = ({
  id,
  currentStep,
  setCurrentStep,
  response,
  setOpenPopup,
}) => {
  const isAllPreviousCompleted = (step) => {
    console.log(step);
    let count = 0;
    for (let i = 1; i <= step; i++) {
      count += response["step" + i].completed;
    }
    if (!response["step" + step].completed) {
      count++;
    }
    return step === count;
  };

  return (
    <div className="w-96 pb-20 bg-white">
      <div className="py-7 px-8 flex items-center">
        <img
          className="w-16 h-16 rounded-full object-cover"
          src="/images/car.webp"
          alt=""
        />
        <div className="text-[#183558] ml-4">
          <p className="font-bold">2019 Honda Civic Type R</p>
          <p className="text-sm my-1">Touring Hatchback Sedan 4D</p>
          <p className="text-sm text-[#00AED9]">View vehicle details</p>
        </div>
      </div>
      <div className="flex justify-between text-sm px-8 py-4 border-b border-[rgb(217, 221, 233)]">
        <h1 className="uppercase text-[#183558] font-bold">PURCHASE PROCESS</h1>
        <p className="text-[#757989]">In progress</p>
      </div>

      <div
        onClick={() => {
          setCurrentStep(1);
        }}
        className={`flex items-center border-b border-[rgb(217, 221, 233)] px-8 py-4  cursor-pointer text-[#183558] ${
          currentStep === 1 ? "bg-[#f4f8fa]" : ""
        }`}
      >
        <img
          className="mr-4 w-4 h-4"
          src={
            response.step1?.completed
              ? "/icons/completed.svg"
              : "/icons/current.svg"
          }
          alt=""
        />
        <div className="">
          <p className="font-medium">Personal Details</p>
          <p className=" text-sm mt-2">
            {response.step1?.completed ? "Complete" : "About 4 minutes"}
          </p>
        </div>
        <div className="ml-auto">
          <img
            className="w-2.5 h-4"
            src={
              response.step1?.completed
                ? "/icons/completedArrow.svg"
                : "/icons/currentArrow.svg"
            }
            alt=""
          />
        </div>
      </div>
      <div
        onClick={() => {
          if (isAllPreviousCompleted(2)) {
            setCurrentStep(2);
          }
        }}
        className={`flex items-center border-b border-[rgb(217, 221, 233)] px-8 py-4 ${
          response.step2?.completed
            ? "cursor-pointer text-[#183558]"
            : response.step1?.completed
            ? "cursor-pointer text-[#183558]"
            : "cursor-not-allowed text-[#aab1c7]"
        } ${currentStep === 2 ? "bg-[#f4f8fa]" : ""} ${
          isAllPreviousCompleted(2) ? "" : "!cursor-not-allowed !text-[#aab1c7]"
        }`}
      >
        <img
          className="mr-4 w-4 h-4"
          src={
            response.step2?.completed
              ? "/icons/completed.svg"
              : isAllPreviousCompleted(2)
              ? "/icons/current.svg"
              : "/icons/incomplete.svg"
          }
          alt=""
        />
        <div className="">
          <p className="font-medium">Trade-in</p>
          <p className=" text-sm mt-2">
            {" "}
            {response.step2?.completed ? "Complete" : "About 4 minutes"}
          </p>
        </div>
        <div className="ml-auto">
          <img
            className="w-2.5 h-4"
            src={
              response.step2?.completed
                ? "/icons/completedArrow.svg"
                : isAllPreviousCompleted(2)
                ? "/icons/currentArrow.svg"
                : ""
            }
            alt=""
          />
        </div>
      </div>
      <div
        onClick={() => {
          if (isAllPreviousCompleted(3)) {
            setCurrentStep(3);
          }
        }}
        className={`flex items-center border-b border-[rgb(217, 221, 233)] px-8 py-4 ${
          response.step3?.completed
            ? "cursor-pointer text-[#183558]"
            : response.step2?.completed
            ? "cursor-pointer text-[#183558]"
            : "cursor-not-allowed text-[#aab1c7]"
        } ${currentStep === 3 ? "bg-[#f4f8fa]" : ""} ${
          isAllPreviousCompleted(3) ? "" : "!cursor-not-allowed !text-[#aab1c7]"
        }`}
      >
        <img
          className="mr-4 w-4 h-4"
          src={
            response.step3?.completed
              ? "/icons/completed.svg"
              : isAllPreviousCompleted(3)
              ? "/icons/current.svg"
              : "/icons/incomplete.svg"
          }
          alt=""
        />
        <div className="">
          <p className="font-medium">Cash or Finance</p>
          <p className=" text-sm mt-2">
            {" "}
            {response.step3?.completed ? "Complete" : "About 2 minutes"}
          </p>
        </div>
        <div className="ml-auto">
          <img
            className="w-2.5 h-4"
            src={
              response.step3?.completed
                ? "/icons/completedArrow.svg"
                : isAllPreviousCompleted(3)
                ? "/icons/currentArrow.svg"
                : ""
            }
            alt=""
          />
        </div>
      </div>
      <div
        onClick={() => {
          if (isAllPreviousCompleted(4)) {
            setCurrentStep(4);
          }
        }}
        className={`flex items-center border-b border-[rgb(217, 221, 233)] px-8 py-4 ${
          response.step4?.completed
            ? "cursor-pointer text-[#183558]"
            : response.step3?.completed
            ? "cursor-pointer text-[#183558]"
            : "cursor-not-allowed text-[#aab1c7]"
        } ${currentStep === 4 ? "bg-[#f4f8fa]" : ""} ${
          isAllPreviousCompleted(4) ? "" : "!cursor-not-allowed !text-[#aab1c7]"
        }`}
      >
        <img
          className="mr-4 w-4 h-4"
          src={
            response.step4?.completed
              ? "/icons/completed.svg"
              : isAllPreviousCompleted(4)
              ? "/icons/current.svg"
              : "/icons/incomplete.svg"
          }
          alt=""
        />
        <div className="">
          <p className="font-medium">Delivery Options</p>
          <p className=" text-sm mt-2">
            {" "}
            {response.step4?.completed ? "Complete" : "About 4 minutes"}
          </p>
        </div>
        <div className="ml-auto">
          <img
            className="w-2.5 h-4"
            src={
              response.step4?.completed
                ? "/icons/completedArrow.svg"
                : isAllPreviousCompleted(4)
                ? "/icons/currentArrow.svg"
                : ""
            }
            alt=""
          />
        </div>
      </div>
      <div
        onClick={() => {
          if (isAllPreviousCompleted(5)) {
            setCurrentStep(5);
          }
        }}
        className={`flex items-center border-b border-[rgb(217, 221, 233)] px-8 py-4 ${
          response.step5?.completed
            ? "cursor-pointer text-[#183558]"
            : response.step4?.completed
            ? "cursor-pointer text-[#183558]"
            : "cursor-not-allowed text-[#aab1c7]"
        } ${currentStep === 5 ? "bg-[#f4f8fa]" : ""} ${
          isAllPreviousCompleted(5) ? "" : "!cursor-not-allowed !text-[#aab1c7]"
        }`}
      >
        <img
          className="mr-4 w-4 h-4"
          src={
            response.step5?.completed
              ? "/icons/completed.svg"
              : isAllPreviousCompleted(5)
              ? "/icons/current.svg"
              : "/icons/incomplete.svg"
          }
          alt=""
        />
        <div className="">
          <p className="font-medium">Renaissance Protection</p>
          <p className=" text-sm mt-2">
            {" "}
            {response.step5?.completed ? "Complete" : "About 4 minute"}
          </p>
        </div>
        <div className="ml-auto">
          <img
            className="w-2.5 h-4"
            src={
              response.step5?.completed
                ? "/icons/completedArrow.svg"
                : isAllPreviousCompleted(5)
                ? "/icons/currentArrow.svg"
                : ""
            }
            alt=""
          />
        </div>
      </div>
      <div
        onClick={() => {
          if (isAllPreviousCompleted(6)) {
            setCurrentStep(6);
          }
        }}
        className={`flex items-center border-b border-[rgb(217, 221, 233)] px-8 py-4 ${
          response.step6?.completed
            ? "cursor-pointer text-[#183558]"
            : response.step5?.completed
            ? "cursor-pointer text-[#183558]"
            : "cursor-not-allowed text-[#aab1c7]"
        } ${currentStep === 6 ? "bg-[#f4f8fa]" : ""} ${
          isAllPreviousCompleted(6) ? "" : "!cursor-not-allowed !text-[#aab1c7]"
        }`}
      >
        <img
          className="mr-4 w-4 h-4"
          src={
            response.step6?.completed
              ? "/icons/completed.svg"
              : isAllPreviousCompleted(6)
              ? "/icons/current.svg"
              : "/icons/incomplete.svg"
          }
          alt=""
        />
        <div className="">
          <p className="font-medium">Driver's License</p>
          <p className=" text-sm mt-2">
            {" "}
            {response.step6?.completed ? "Complete" : "About 4 minutes"}
          </p>
        </div>
        <div className="ml-auto">
          <img
            className="w-2.5 h-4"
            src={
              response.step6?.completed
                ? "/icons/completedArrow.svg"
                : isAllPreviousCompleted(6)
                ? "/icons/currentArrow.svg"
                : ""
            }
            alt=""
          />
        </div>
      </div>
      <div
        onClick={() => {
          if (isAllPreviousCompleted(7)) {
            setCurrentStep(7);
          }
        }}
        className={`flex items-center border-b border-[rgb(217, 221, 233)] px-8 py-4 ${
          response.step7?.completed
            ? "cursor-pointer text-[#183558]"
            : response.step6?.completed
            ? "cursor-pointer text-[#183558]"
            : "cursor-not-allowed text-[#aab1c7]"
        } ${currentStep === 7 ? "bg-[#f4f8fa]" : ""} ${
          isAllPreviousCompleted(7) ? "" : "!cursor-not-allowed !text-[#aab1c7]"
        }`}
      >
        <img
          className="mr-4 w-4 h-4"
          src={
            response.step7?.completed
              ? "/icons/completed.svg"
              : isAllPreviousCompleted(7)
              ? "/icons/current.svg"
              : "/icons/incomplete.svg"
          }
          alt=""
        />
        <div className="">
          <p className="font-medium">Payment</p>
          <p className=" text-sm mt-2">
            {" "}
            {response.step7?.completed ? "Complete" : "About 5 minutes"}
          </p>
        </div>
        <div className="ml-auto">
          <img
            className="w-2.5 h-4"
            src={
              response.step7?.completed
                ? "/icons/completedArrow.svg"
                : isAllPreviousCompleted(7)
                ? "/icons/currentArrow.svg"
                : ""
            }
            alt=""
          />
        </div>
      </div>
      <div
        onClick={() => {
          if (isAllPreviousCompleted(8)) {
            setCurrentStep(8);
          }
        }}
        className={`flex items-center border-b border-[rgb(217, 221, 233)] px-8 py-4 ${
          response.step8?.completed
            ? "cursor-pointer text-[#183558]"
            : response.step7?.completed
            ? "cursor-pointer text-[#183558]"
            : "cursor-not-allowed text-[#aab1c7]"
        } ${currentStep === 8 ? "bg-[#f4f8fa]" : ""} ${
          isAllPreviousCompleted(8) ? "" : "!cursor-not-allowed !text-[#aab1c7]"
        }`}
      >
        <img
          className="mr-4 w-4 h-4"
          src={
            response.step8?.completed
              ? "/icons/completed.svg"
              : isAllPreviousCompleted(8)
              ? "/icons/current.svg"
              : "/icons/incomplete.svg"
          }
          alt=""
        />
        <div className="">
          <p className="font-medium">Review Order</p>
          <p className=" text-sm mt-2">
            {" "}
            {response.step8?.completed ? "Complete" : "About 2 minutes"}
          </p>
        </div>
        <div className="ml-auto">
          <img
            className="w-2.5 h-4"
            src={
              response.step8?.completed
                ? "/icons/completedArrow.svg"
                : isAllPreviousCompleted(8)
                ? "/icons/currentArrow.svg"
                : ""
            }
            alt=""
          />
        </div>
      </div>
      <div
        onClick={() => {
          if (isAllPreviousCompleted(9)) {
            setCurrentStep(9);
          }
        }}
        className={`flex items-center border-b border-[rgb(217, 221, 233)] px-8 py-4 ${
          response.step9?.completed
            ? "cursor-pointer text-[#183558]"
            : response.step8?.completed
            ? "cursor-pointer text-[#183558]"
            : "cursor-not-allowed text-[#aab1c7]"
        } ${currentStep === 9 ? "bg-[#f4f8fa]" : ""} ${
          isAllPreviousCompleted(9) ? "" : "!cursor-not-allowed !text-[#aab1c7]"
        }`}
      >
        <img
          className="mr-4 w-4 h-4"
          src={
            response.step9?.completed
              ? "/icons/completed.svg"
              : isAllPreviousCompleted(9)
              ? "/icons/current.svg"
              : "/icons/incomplete.svg"
          }
          alt=""
        />
        <div className="">
          <p className="font-medium">Bank Account Verification</p>
          <p className=" text-sm mt-2">
            {" "}
            {response.step9?.completed ? "Complete" : "About 2 minutes"}
          </p>
        </div>
        <div className="ml-auto">
          <img
            className="w-2.5 h-4"
            src={
              response.step9?.completed
                ? "/icons/completedArrow.svg"
                : isAllPreviousCompleted(9)
                ? "/icons/currentArrow.svg"
                : ""
            }
            alt=""
          />
        </div>
      </div>

      <div className="border-b border-[rgb(217, 221, 233)] px-8 py-4">
        <p className="text-sm uppercase text-[#183558] font-bold">
          FINALIZE PURCHASE
        </p>
      </div>
      <div className="px-8 py-4">
        {id && (
          <p
            className="inline-block text-sm text-[#00AED9] font-medium cursor-pointer"
            onClick={() => {
              setOpenPopup(true);
            }}
          >
            Want to cancel your order?
          </p>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
