import React, { useState } from "react";
import { Checkbox, FormHelperText, InputLabel, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const Condition = ({ setData, data, setStep, handleSubmit }) => {
  const [accident, setAccident] = useState("");

  const ValidationSchema = Yup.object().shape({
    accident: Yup.string().required("Field is required"),
    drivable: Yup.string().required("Field is required"),
    mechAndElecIssues: Yup.array().min(1, "At least one option is required."),
    engineIssues: Yup.array().min(1, "At least one option is required."),
    exteriorIssues: Yup.array().min(1, "At least one option is required."),
    ineriorIssues: Yup.array().min(1, "At least one option is required."),
    modification: Yup.string().required("Field is required."),
    smoked: Yup.string().required("Field is required."),
    keys: Yup.string().required("Field is required."),
    condition: Yup.string().required("Field is required."),
    repaired:
      accident !== "No Accidents"
        ? Yup.string().required("Field is required")
        : Yup.string(),
    costOfRepair:
      accident !== "No Accidents"
        ? Yup.string().required("Field is required")
        : Yup.string(),
  });

  return (
    <div className="bg-white w-[calc(100%-40px-256px)]">
      <h1 className="text-[#183558] text-lg font-bold mb-5 mt-8">
        Condition & History
      </h1>
      <p className="text-[#757989] text-lg mb-5">
        Please select the options that most accurately describe the condition of
        your vehicle.
      </p>
      <div>
        <Formik
          initialValues={
            data.condition
              ? data.condition
              : {
                  accident: "",
                  drivable: "",
                  repaired: "",
                  costOfRepair: "",
                  mechAndElecIssues: [],
                  mechAndElecIssuesDetail: "",
                  engineIssues: [],
                  engineIssuesDetail: "",
                  exteriorIssues: [],
                  exteriorIssuesDetail: "",
                  ineriorIssues: [],
                  ineriorIssuesDetail: "",
                  modification: "",
                  smoked: "",
                  keys: "",
                  condition: "",
                }
          }
          enableReinitialize
          validationSchema={ValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setData({ ...data, condition: values });
            handleSubmit({ ...data, condition: values }, setSubmitting);
          }}
        >
          {({
            isSubmitting,
            errors,
            values,
            setValues,
            setFieldValue,
            handleChange,
            handleBlur,
            touched,
          }) => (
            <Form>
              <h1 className="text-base  mt-8 mb-4 text-[#183558]">
                Has your vehicle been in an accident?
              </h1>
              <div className="w-[95%]">
                <div className="flex mt-5 w-full gap-2">
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("accident", "No Accidents");
                      setAccident("No Accidents");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.accident == "No Accidents"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    No Accidents
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("accident", "1 Accident");
                      setAccident("1 Accident");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.accident === "1 Accident"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    1 Accident
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("accident", "2+ Accident");
                      setAccident("2+ Accident");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.accident === "2+ Accident"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    2+ Accident
                  </button>
                </div>
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {errors?.accident}
                </FormHelperText>

                {(values.accident === "1 Accident" ||
                  values.accident === "2+ Accident") && (
                  <div>
                    <h1 className="text-base  mt-8 mb-4 text-[#183558]">
                      Has any accident damage to your vehicle been fully
                      repaired?
                    </h1>
                    <div className="grid grid-cols-3 mt-5 w-full gap-2">
                      <button
                        type="button"
                        onClick={() => {
                          setFieldValue("repaired", "Repaired");
                        }}
                        className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                          values.repaired == "Repaired"
                            ? "!bg-[#00619e] !text-white "
                            : ""
                        }`}
                      >
                        Repaired
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setFieldValue("repaired", "Not Repaired");
                        }}
                        className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                          values.repaired === "Not Repaired"
                            ? "!bg-[#00619e] !text-white "
                            : ""
                        }`}
                      >
                        Not Repaired
                      </button>
                    </div>
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {errors?.repaired}
                    </FormHelperText>

                    <div className="w-1/2">
                      <h1 className="text-base  mt-8 mb-4 text-[#183558]">
                        What is the estimated cost of the repair?
                      </h1>
                      <TextField
                        name="costOfRepair"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.costOfRepair}
                        fullWidth
                        error={Boolean(errors?.costOfRepair)}
                        helperText={errors?.costOfRepair}
                        label="AMOUNT"
                        variant="outlined"
                        inputProps={{ style: { background: "white" } }}
                      />
                    </div>
                  </div>
                )}

                <div className="">
                  <h1 className="text-base  mt-8 mb-4 text-[#183558]">
                    Does your vehicle have any issues that would stop us from
                    safely driving it?
                  </h1>
                  <div className="grid grid-cols-3 mt-5 w-full gap-2">
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("drivable", "Drivable");
                      }}
                      className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                        values.drivable == "Drivable"
                          ? "!bg-[#00619e] !text-white "
                          : ""
                      }`}
                    >
                      Drivable
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("drivable", "Not Drivable");
                      }}
                      className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                        values.drivable === "Not Drivable"
                          ? "!bg-[#00619e] !text-white "
                          : ""
                      }`}
                    >
                      Not Drivable
                    </button>
                  </div>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.drivable}
                  </FormHelperText>
                </div>

                <h1 className="text-base  mt-8 mb-4 text-[#183558]">
                  Does your vehicle have any of the issues listed below?
                </h1>

                <div>
                  <h1 className="text-base font-semibold mt-8 mb-4 text-[#183558]">
                    Mechanical & Electrical Issues
                  </h1>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.mechAndElecIssues}
                  </FormHelperText>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="mechAndElecIssues1"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.mechAndElecIssues.includes(
                            "Air Conditioning"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("mechAndElecIssues", [
                                ...values.mechAndElecIssues.filter(
                                  (item) =>
                                    item !==
                                    "No Mechanical or Electrical Issues"
                                ),
                                ,
                                "Air Conditioning",
                              ]);
                            } else {
                              setFieldValue(
                                "mechAndElecIssues",
                                values.mechAndElecIssues.filter(
                                  (item) => item !== "Air Conditioning"
                                )
                              );
                            }
                          }}
                          labelId="mechAndElecIssues1"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Air Conditioning</p>
                          <p className="text-[#757989] text-sm">
                            Doesn't blow cold air, fan broken, etc.
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="mechAndElecIssues2"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.mechAndElecIssues.includes(
                            "Transmission"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("mechAndElecIssues", [
                                ...values.mechAndElecIssues.filter(
                                  (item) =>
                                    item !==
                                    "No Mechanical or Electrical Issues"
                                ),
                                ,
                                "Transmission",
                              ]);
                            } else {
                              setFieldValue(
                                "mechAndElecIssues",
                                values.mechAndElecIssues.filter(
                                  (item) => item !== "Transmission"
                                )
                              );
                            }
                          }}
                          labelId="mechAndElecIssues2"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Transmission</p>
                          <p className="text-[#757989] text-sm">
                            Drivetrain issues, shifts poorly, etc.
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="mechAndElecIssues3"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.mechAndElecIssues.includes(
                            "Tire Pressure"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("mechAndElecIssues", [
                                ...values.mechAndElecIssues.filter(
                                  (item) =>
                                    item !==
                                    "No Mechanical or Electrical Issues"
                                ),
                                ,
                                "Tire Pressure",
                              ]);
                            } else {
                              setFieldValue(
                                "mechAndElecIssues",
                                values.mechAndElecIssues.filter(
                                  (item) => item !== "Tire Pressure"
                                )
                              );
                            }
                          }}
                          labelId="mechAndElecIssues3"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Tire Pressure</p>
                          <p className="text-[#757989] text-sm">
                            Tire pressure light / TPMS.
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="mechAndElecIssues4"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.mechAndElecIssues.includes(
                            "Electrical"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("mechAndElecIssues", [
                                ...values.mechAndElecIssues.filter(
                                  (item) =>
                                    item !==
                                    "No Mechanical or Electrical Issues"
                                ),
                                "Electrical",
                              ]);
                            } else {
                              setFieldValue(
                                "mechAndElecIssues",
                                values.mechAndElecIssues.filter(
                                  (item) => item !== "Electrical"
                                )
                              );
                            }
                          }}
                          labelId="mechAndElecIssues4"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Electrical</p>
                          <p className="text-[#757989] text-sm">
                            Airbag / SRS light, dim headlights, etc.
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="mechAndElecIssues5"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.mechAndElecIssues.includes(
                            "No Mechanical or Electrical Issues"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("mechAndElecIssues", [
                                "No Mechanical or Electrical Issues",
                              ]);
                            } else {
                              setFieldValue("mechAndElecIssues", []);
                            }
                          }}
                          labelId="mechAndElecIssues5"
                          sx={{
                            marginBottom: "0px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">
                            No Mechanical or Electrical Issues
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                  </div>
                  <div className="py-6 px-4 bg-[#f4f8fa] mt-4">
                    <h1 className="text-base mb-4 text-[#183558]">
                      Share details about what you’ve selected.
                    </h1>
                    <TextField
                      name="mechAndElecIssuesDetail"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.mechAndElecIssuesDetail}
                      multiline
                      rows={2}
                      fullWidth
                      error={Boolean(errors?.mechAndElecIssuesDetail)}
                      helperText={errors?.mechAndElecIssuesDetail}
                      placeholder="Tell us about needed repairs or warning lights."
                      variant="outlined"
                      sx={{ background: "white" }}
                      inputProps={{
                        maxLength: 300,
                        style: { background: "white" },
                      }}
                    />
                    <p className="text-xs text-[#757989] mt-4">
                      Remaining: {300 - values.mechAndElecIssuesDetail.length}
                    </p>
                  </div>
                </div>

                <div>
                  <h1 className="text-base font-semibold mt-8 mb-4 text-[#183558]">
                    Engine Issues
                  </h1>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.engineIssues}
                  </FormHelperText>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="engineIssues1"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.engineIssues.includes(
                            "Check Engine Light"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("engineIssues", [
                                ...values.engineIssues.filter(
                                  (item) => item !== "No Engine Issues"
                                ),
                                ,
                                "Check Engine Light",
                              ]);
                            } else {
                              setFieldValue(
                                "engineIssues",
                                values.engineIssues.filter(
                                  (item) => item !== "Check Engine Light"
                                )
                              );
                            }
                          }}
                          labelId="engineIssues1"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Check Engine Light</p>
                          <p className="text-[#757989] text-sm">
                            Light visible on dashboard
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="engineIssues2"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.engineIssues.includes(
                            "Strange Noises"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("engineIssues", [
                                ...values.engineIssues.filter(
                                  (item) => item !== "No Engine Issues"
                                ),
                                ,
                                "Strange Noises",
                              ]);
                            } else {
                              setFieldValue(
                                "engineIssues",
                                values.engineIssues.filter(
                                  (item) => item !== "Strange Noises"
                                )
                              );
                            }
                          }}
                          labelId="engineIssues2"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Strange Noises</p>
                          <p className="text-[#757989] text-sm">
                            Knocking, clicking, screeching etc.
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="engineIssues3"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.engineIssues.includes(
                            "Engine Vibration"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("engineIssues", [
                                ...values.engineIssues.filter(
                                  (item) => item !== "No Engine Issues"
                                ),
                                ,
                                "Engine Vibration",
                              ]);
                            } else {
                              setFieldValue(
                                "engineIssues",
                                values.engineIssues.filter(
                                  (item) => item !== "Engine Vibration"
                                )
                              );
                            }
                          }}
                          labelId="engineIssues3"
                          sx={{
                            marginBottom: "0px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Engine Vibration</p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="engineIssues4"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.engineIssues.includes(
                            "Smoke or Steam"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("engineIssues", [
                                ...values.engineIssues.filter(
                                  (item) => item !== "No Engine Issues"
                                ),
                                "Smoke or Steam",
                              ]);
                            } else {
                              setFieldValue(
                                "engineIssues",
                                values.engineIssues.filter(
                                  (item) => item !== "Smoke or Steam"
                                )
                              );
                            }
                          }}
                          labelId="engineIssues4"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Smoke or Steam</p>
                          <p className="text-[#757989] text-sm">
                            From under the hood or the exhaust
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="engineIssues5"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.engineIssues.includes(
                            "Other Engine Issues"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("engineIssues", [
                                ...values.engineIssues.filter(
                                  (item) => item !== "No Engine Issues"
                                ),
                                "Other Engine Issues",
                              ]);
                            } else {
                              setFieldValue(
                                "engineIssues",
                                values.engineIssues.filter(
                                  (item) => item !== "Other Engine Issues"
                                )
                              );
                            }
                          }}
                          labelId="engineIssues5"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Other Engine Issues</p>
                          <p className="text-[#757989] text-sm">
                            Including needed service or repair
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="engineIssues6"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.engineIssues.includes(
                            "No Engine Issues"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("engineIssues", [
                                "No Engine Issues",
                              ]);
                            } else {
                              setFieldValue("engineIssues", []);
                            }
                          }}
                          labelId="engineIssues6"
                          sx={{
                            marginBottom: "0px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">No Engine Issues</p>
                        </div>
                      </InputLabel>
                    </div>
                  </div>
                  <div className="py-6 px-4 bg-[#f4f8fa] mt-4">
                    <h1 className="text-base mb-4 text-[#183558]">
                      Share details about what you’ve selected.
                    </h1>
                    <TextField
                      name="engineIssuesDetail"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.engineIssuesDetail}
                      multiline
                      rows={2}
                      fullWidth
                      error={Boolean(errors?.engineIssuesDetail)}
                      helperText={errors?.engineIssuesDetail}
                      placeholder="Tell us about your engine issues."
                      variant="outlined"
                      sx={{ background: "white" }}
                      inputProps={{
                        maxLength: 300,
                        style: { background: "white" },
                      }}
                    />
                    <p className="text-xs text-[#757989] mt-4">
                      Remaining: {300 - values.engineIssuesDetail.length}
                    </p>
                  </div>
                </div>

                <div>
                  <h1 className="text-base font-semibold mt-8 mb-4 text-[#183558]">
                    Exterior Damage
                  </h1>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.exteriorIssues}
                  </FormHelperText>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="exteriorIssues1"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.exteriorIssues.includes(
                            "Minor Damage"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("exteriorIssues", [
                                ...values.exteriorIssues.filter(
                                  (item) => item !== "No Exterior Damage"
                                ),
                                ,
                                "Minor Damage",
                              ]);
                            } else {
                              setFieldValue(
                                "exteriorIssues",
                                values.exteriorIssues.filter(
                                  (item) => item !== "Minor Damage"
                                )
                              );
                            }
                          }}
                          labelId="exteriorIssues1"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Minor Damage</p>
                          <p className="text-[#757989] text-sm">
                            Scuffs, scratches, or chips
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="exteriorIssues2"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.exteriorIssues.includes(
                            "Fading Paint"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("exteriorIssues", [
                                ...values.exteriorIssues.filter(
                                  (item) => item !== "No Exterior Damage"
                                ),
                                ,
                                "Fading Paint",
                              ]);
                            } else {
                              setFieldValue(
                                "exteriorIssues",
                                values.exteriorIssues.filter(
                                  (item) => item !== "Fading Paint"
                                )
                              );
                            }
                          }}
                          labelId="exteriorIssues2"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Fading Paint</p>
                          <p className="text-[#757989] text-sm">
                            Visibly dull or rough
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="exteriorIssues3"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.exteriorIssues.includes(
                            "Dents or Scrapes"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("exteriorIssues", [
                                ...values.exteriorIssues.filter(
                                  (item) => item !== "No Exterior Damage"
                                ),
                                ,
                                "Dents or Scrapes",
                              ]);
                            } else {
                              setFieldValue(
                                "exteriorIssues",
                                values.exteriorIssues.filter(
                                  (item) => item !== "Dents or Scrapes"
                                )
                              );
                            }
                          }}
                          labelId="exteriorIssues3"
                          sx={{
                            marginBottom: "0px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Dents or Scrapes</p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="exteriorIssues4"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.exteriorIssues.includes("Rust")}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("exteriorIssues", [
                                ...values.exteriorIssues.filter(
                                  (item) => item !== "No Exterior Damage"
                                ),
                                "Rust",
                              ]);
                            } else {
                              setFieldValue(
                                "exteriorIssues",
                                values.exteriorIssues.filter(
                                  (item) => item !== "Rust"
                                )
                              );
                            }
                          }}
                          labelId="exteriorIssues4"
                          sx={{
                            marginBottom: "0px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Rust</p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="exteriorIssues5"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.exteriorIssues.includes(
                            "Hail Damage"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("exteriorIssues", [
                                ...values.exteriorIssues.filter(
                                  (item) => item !== "No Exterior Damage"
                                ),
                                "Hail Damage",
                              ]);
                            } else {
                              setFieldValue(
                                "exteriorIssues",
                                values.exteriorIssues.filter(
                                  (item) => item !== "Hail Damage"
                                )
                              );
                            }
                          }}
                          labelId="exteriorIssues5"
                          sx={{
                            marginBottom: "0px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Hail Damage</p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="exteriorIssues6"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.exteriorIssues.includes(
                            "No Exterior Damage"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("exteriorIssues", [
                                "No Exterior Damage",
                              ]);
                            } else {
                              setFieldValue("exteriorIssues", []);
                            }
                          }}
                          labelId="exteriorIssues6"
                          sx={{
                            marginBottom: "0px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">No Exterior Damage</p>
                        </div>
                      </InputLabel>
                    </div>
                  </div>
                  <div className="py-6 px-4 bg-[#f4f8fa] mt-4">
                    <h1 className="text-base mb-4 text-[#183558]">
                      Share details about what you’ve selected.
                    </h1>
                    <TextField
                      name="exteriorIssuesDetail"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.exteriorIssuesDetail}
                      multiline
                      rows={2}
                      fullWidth
                      error={Boolean(errors?.exteriorIssuesDetail)}
                      helperText={errors?.exteriorIssuesDetail}
                      placeholder="Tell us about your exterior damage."
                      variant="outlined"
                      sx={{ background: "white" }}
                      inputProps={{
                        maxLength: 300,
                        style: { background: "white" },
                      }}
                    />
                    <p className="text-xs text-[#757989] mt-4">
                      Remaining: {300 - values.exteriorIssuesDetail.length}
                    </p>
                  </div>
                </div>

                <div>
                  <h1 className="text-base font-semibold mt-8 mb-4 text-[#183558]">
                    Interior Damage
                  </h1>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.ineriorIssues}
                  </FormHelperText>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="ineriorIssues1"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.ineriorIssues.includes(
                            "Noticeable Stains"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("ineriorIssues", [
                                ...values.ineriorIssues.filter(
                                  (item) => item !== "No Interior Damage"
                                ),
                                ,
                                "Noticeable Stains",
                              ]);
                            } else {
                              setFieldValue(
                                "ineriorIssues",
                                values.ineriorIssues.filter(
                                  (item) => item !== "Noticeable Stains"
                                )
                              );
                            }
                          }}
                          labelId="ineriorIssues1"
                          sx={{
                            marginBottom: "0px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Noticeable Stains</p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="ineriorIssues2"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.ineriorIssues.includes(
                            "Rips or Tears in Seats"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("ineriorIssues", [
                                ...values.ineriorIssues.filter(
                                  (item) => item !== "No Interior Damage"
                                ),
                                ,
                                "Rips or Tears in Seats",
                              ]);
                            } else {
                              setFieldValue(
                                "ineriorIssues",
                                values.ineriorIssues.filter(
                                  (item) => item !== "Rips or Tears in Seats"
                                )
                              );
                            }
                          }}
                          labelId="ineriorIssues2"
                          sx={{
                            marginBottom: "0px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">
                            Rips or Tears in Seats
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="ineriorIssues3"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.ineriorIssues.includes(
                            "Persistent Odors"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("ineriorIssues", [
                                ...values.ineriorIssues.filter(
                                  (item) => item !== "No Interior Damage"
                                ),
                                ,
                                "Persistent Odors",
                              ]);
                            } else {
                              setFieldValue(
                                "ineriorIssues",
                                values.ineriorIssues.filter(
                                  (item) => item !== "Persistent Odors"
                                )
                              );
                            }
                          }}
                          labelId="ineriorIssues3"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Persistent Odors</p>
                          <p className="text-[#757989] text-sm">
                            From pets, smoking, mildew, etc
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="ineriorIssues4"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                        }}
                      >
                        <Checkbox
                          checked={values.ineriorIssues.includes(
                            "Damaged A/V Systems"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("ineriorIssues", [
                                ...values.ineriorIssues.filter(
                                  (item) => item !== "No Interior Damage"
                                ),
                                "Damaged A/V Systems",
                              ]);
                            } else {
                              setFieldValue(
                                "ineriorIssues",
                                values.ineriorIssues.filter(
                                  (item) => item !== "Damaged A/V Systems"
                                )
                              );
                            }
                          }}
                          labelId="ineriorIssues4"
                          sx={{
                            marginBottom: "6px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">Damaged A/V Systems</p>
                          <p className="text-[#757989] text-sm">
                            Entertainment, navigation, etc
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="ineriorIssues5"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.ineriorIssues.includes(
                            "Damaged Dashboard or Interior Panels"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("ineriorIssues", [
                                ...values.ineriorIssues.filter(
                                  (item) => item !== "No Interior Damage"
                                ),
                                "Damaged Dashboard or Interior Panels",
                              ]);
                            } else {
                              setFieldValue(
                                "ineriorIssues",
                                values.ineriorIssues.filter(
                                  (item) =>
                                    item !==
                                    "Damaged Dashboard or Interior Panels"
                                )
                              );
                            }
                          }}
                          labelId="ineriorIssues5"
                          sx={{
                            marginBottom: "0px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">
                            Damaged Dashboard or Interior Panels
                          </p>
                        </div>
                      </InputLabel>
                    </div>
                    <div className="border border-[#d9dde9] pl-2 pr-5 py-4 rounded-md inline-block mt-2">
                      <InputLabel
                        id="ineriorIssues6"
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={values.ineriorIssues.includes(
                            "No Interior Damage"
                          )}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("ineriorIssues", [
                                "No Interior Damage",
                              ]);
                            } else {
                              setFieldValue("ineriorIssues", []);
                            }
                          }}
                          labelId="ineriorIssues6"
                          sx={{
                            marginBottom: "0px",
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        <div>
                          <p className="text-[#183558]">No Interior Damage</p>
                        </div>
                      </InputLabel>
                    </div>
                  </div>
                  <div className="py-6 px-4 bg-[#f4f8fa] mt-4">
                    <h1 className="text-base mb-4 text-[#183558]">
                      Share details about what you’ve selected.
                    </h1>
                    <TextField
                      name="ineriorIssuesDetail"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.ineriorIssuesDetail}
                      multiline
                      rows={2}
                      fullWidth
                      error={Boolean(errors?.ineriorIssuesDetail)}
                      helperText={errors?.ineriorIssuesDetail}
                      placeholder="Tell us about your interior damage."
                      variant="outlined"
                      sx={{ background: "white" }}
                      inputProps={{
                        maxLength: 300,
                        style: { background: "white" },
                      }}
                    />
                    <p className="text-xs text-[#757989] mt-4">
                      Remaining: {300 - values.ineriorIssuesDetail.length}
                    </p>
                  </div>
                </div>

                <div className="">
                  <h1 className="text-base  mt-8 mb-4 text-[#183558]">
                    Does your vehicle have any modifications (e.g. suspension,
                    engine, etc.)?
                  </h1>
                  <div className="grid grid-cols-3 mt-5 w-full gap-2">
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("modification", "Modifications");
                      }}
                      className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                        values.modification == "Modifications"
                          ? "!bg-[#00619e] !text-white "
                          : ""
                      }`}
                    >
                      Modifications
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("modification", "No Modifications");
                      }}
                      className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                        values.modification === "No Modifications"
                          ? "!bg-[#00619e] !text-white "
                          : ""
                      }`}
                    >
                      No Modifications
                    </button>
                  </div>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.modification}
                  </FormHelperText>
                </div>
                <div className="">
                  <h1 className="text-base  mt-8 mb-4 text-[#183558]">
                    Has your vehicle been smoked in?
                  </h1>
                  <div className="grid grid-cols-3 mt-5 w-full gap-2">
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("smoked", "Smoked In");
                      }}
                      className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                        values.smoked == "Smoked In"
                          ? "!bg-[#00619e] !text-white "
                          : ""
                      }`}
                    >
                      Smoked In
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("smoked", "Not Smoked In");
                      }}
                      className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                        values.smoked === "Not Smoked In"
                          ? "!bg-[#00619e] !text-white "
                          : ""
                      }`}
                    >
                      Not Smoked In
                    </button>
                  </div>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.smoked}
                  </FormHelperText>
                </div>
                <div className="">
                  <h1 className="text-base  mt-8 mb-4 text-[#183558]">
                    How many keys do you have for this vehicle?
                  </h1>
                  <div className="grid grid-cols-3 mt-5 w-full gap-2">
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("keys", "1 Key");
                      }}
                      className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                        values.keys == "1 Key"
                          ? "!bg-[#00619e] !text-white "
                          : ""
                      }`}
                    >
                      1 Key
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("keys", "2+ Keys");
                      }}
                      className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                        values.keys === "2+ Keys"
                          ? "!bg-[#00619e] !text-white "
                          : ""
                      }`}
                    >
                      2+ Keys
                    </button>
                  </div>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.keys}
                  </FormHelperText>
                </div>
                <div className="">
                  <h1 className="text-base  mt-8 mb-4 text-[#183558]">
                    What is the best way to describe your vehicle's overall
                    condition?
                  </h1>
                  <div className="grid grid-cols-3 mt-5 w-full gap-2">
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("condition", "Pretty Great");
                      }}
                      className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                        values.condition == "Pretty Great"
                          ? "!bg-[#00619e] !text-white "
                          : ""
                      }`}
                    >
                      Pretty Great
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("condition", "Just Okay");
                      }}
                      className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                        values.condition === "Just Okay"
                          ? "!bg-[#00619e] !text-white "
                          : ""
                      }`}
                    >
                      Just Okay
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("condition", "Kind Of Rough");
                      }}
                      className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                        values.condition === "Kind Of Rough"
                          ? "!bg-[#00619e] !text-white "
                          : ""
                      }`}
                    >
                      Kind Of Rough
                    </button>
                  </div>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.condition}
                  </FormHelperText>
                </div>
              </div>
              <div className="my-10 flex justify-between gap-4 w-[95%]">
                <button
                  type="button"
                  onClick={() => {
                    setStep(2);
                  }}
                  className="rounded w-1/2 text-[#00aed9] bg-[#fff] hover:bg-[#00aed9] hover:text-white  font-medium min-h-14  border border-[#00aed9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                >
                  BACK
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded w-1/2 text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                >
                  SUBMIT
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Condition;
