import axios from "axios";

// Create a function that returns the Axios instance with dynamic headers
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  },
});
const updateInstance = (token) => {
  // Interceptor to update Authorization header whenever the token changes
  instance.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return instance;
};

export default updateInstance;

export const login = async (body) => {
  return await instance.post(`user/login`, body);
};

export const getSavedFormData = async (id) => {
  return await instance.get(`user/order?id=${id}`);
};

export const getSavedCreditData = async (id) => {
  return await instance.get(`admin/credit?_id=${id}`);
};

export const saveFormData = async (body) => {
  return await instance.post(`user/update-order`, body);
};

export const saveCreditData = async (body, id) => {
  return await instance.post(
    `user/update_credit${id ? "?_id=" + id : ""}`,
    body
  );
};

export const uploadDriverLicense = async (body) => {
  return await instance.post(`user/upload-driving-license`, body);
};

export const uploadCustomerDetails = async (body) => {
  return await instance.post(`user/upload-customer-details`, body);
};

export const uploadEmployementInfo = async (body) => {
  return await instance.post(`user/upload-employment-info`, body);
};

export const uploadInsuranceInfo = async (body) => {
  return await instance.post(`user/upload-insurance-info`, body);
};

export const getDriverLicenseUrl = async (id) => {
  return await instance.post(`admin/user/generate-link`, {
    orderId: id,
  });
};

export const getEmploymentUrl = async (id) => {
  return await instance.post(`admin/user/generate-link-employment-info`, {
    orderId: id,
  });
};

export const getInsuranceUrl = async (id) => {
  return await instance.post(`admin/user/generate-link-insurance-info`, {
    orderId: id,
  });
};

export const getCustomerDetail = async (id) => {
  return await instance.post(`admin/user/generate-link-customer-details`, {
    orderId: id,
  });
};

export const saveTradeId = async (id, body) => {
  return await instance.post(`user/save-tradeIn/${id}`, body);
};

export const deleteSavedFormData = async (id, body) => {
  return await instance.post(`user/delete-order?orderId=${id}`, body);
};

export const getOrders = async () => {
  return await instance.get(`admin/order/all`);
};

export const getCredits = async () => {
  return await instance.get(`admin/credit/all`);
};

export const getOrderDetail = async (id) => {
  return await instance.get(`admin/order/${id}`);
};
