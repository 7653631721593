import React, { useState } from "react";
import CreditComponent from "../Finance";
import { saveCreditData } from "../../api";
import { toast } from "react-toastify";

const CreditApp = () => {
  const [showCreditApp, setShowCreditAPp] = useState();

  const handleSaveFormData = async (values, setIsLoading, onSuccess) => {
    await saveCreditData({ creditInfo: values })
      .then((res) => {
        setIsLoading(false);
        toast.success("Info Added Successfully");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.error);
      });
  };

  return (
    <div className="pt-10">
      {!showCreditApp ? (
        <>
          {" "}
          <div className="py-8 px-5 md:px-10 lg:px-[5%] box-border bg-[white] max-w-4xl mx-auto rounded-lg">
            <div className="mx-auto">
              <h1 className="text-xl text-[#183558] mb-4 font-bold">
                Credit Application <span className="text-sm">(4 mins)</span>
              </h1>
              <div>
                <p className="text-sm text-[#757989] mb-10">
                  Please select your application type to fill in the information
                  below for us to process your application
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5 px-[5%]">
              <div
                className="border border-[#DCDDDF] cursor-pointer flex flex-col justify-between"
                onClick={() => {
                  setShowCreditAPp(1);
                }}
              >
                <img src="/images/individual.jfif" />
                <button className="w-full bg-[#EAF1FB] text-[#171717] h-[3.5rem] font-medium">
                  Individual applicant
                </button>
              </div>
              <div
                className="border border-[#DCDDDF] cursor-pointer flex flex-col justify-between"
                onClick={() => {
                  setShowCreditAPp(2);
                }}
              >
                <img src="/images/co-buyer.jfif" />
                <button className="w-full bg-[#EAF1FB] text-[#171717] h-[3.5rem] font-medium">
                  Include co-buyer
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="min-h-screen w-full pt-5">
          <div className="py-8 px-5 md:px-10 lg:px-[5%] box-border bg-[white] max-w-4xl mx-auto rounded-lg">
            <CreditComponent
              response={{}}
              setResponse={() => {}}
              setCurrentStep={() => {}}
              handleSaveFormData={handleSaveFormData}
              coBuyer={showCreditApp === 2}
              isCredit={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditApp;
