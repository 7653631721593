import React from "react";
import { TextField } from "@mui/material";

const Income = ({ fields, isCoBuyer }) => {
  const {
    errors,
    values,
    setValues,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
  } = fields;
  return (
    <div>
      <div className="border-b border-gray-300 pb-10">
        <h1 className="text-lg font-bold text-[#183558] pb-6 ">
          Buyer's Income Info
        </h1>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 mb-4">
            <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
              Income Frequency*
            </h1>
            <div className="flex">
              {["Annual", "Monthly", "Biweekly", "Weekly"].map((item) => (
                <div
                  className={`text-[#6F6D6F] text-sm cursor-pointer border border-[#DCDDDF] py-2 px-4 mb-4 flex items-center justify-center rounded-[5rem] mr-4 ${
                    values.incomeFrequency === item
                      ? "text-black font-semibold bg-[#EAF1FB] border-[#EAF1FB]"
                      : ""
                  }`}
                  onClick={() => {
                    setFieldValue("incomeFrequency", item);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
            {errors.incomeFrequency && (
              <p className="text-[#d32f2f] text-[0.75rem]">
                {errors.incomeFrequency}
              </p>
            )}
          </div>
          <div>
            <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
              What is your gross income?
            </h1>
            <TextField
              name="grossIncome"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.grossIncome}
              fullWidth
              error={Boolean(errors.grossIncome)}
              helperText={errors.grossIncome}
              label=""
              variant="outlined"
              inputProps={{ style: { background: "white" } }}
            />
          </div>
        </div>
      </div>
      {isCoBuyer && (
        <div className="mt-10">
          <h1 className="text-lg font-bold text-[#183558] pb-6 ">
            Co-Buyer's Income Info
          </h1>
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2 mb-4">
              <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                Income Frequency*
              </h1>
              <div className="flex">
                {["Annual", "Monthly", "Biweekly", "Weekly"].map((item) => (
                  <div
                    className={`text-[#6F6D6F] text-sm cursor-pointer border border-[#DCDDDF] py-2 px-4 mb-4 flex items-center justify-center rounded-[5rem] mr-4 ${
                      values.coBuyer?.incomeFrequency === item
                        ? "text-black font-semibold bg-[#EAF1FB] border-[#EAF1FB]"
                        : ""
                    }`}
                    onClick={() => {
                      setFieldValue("coBuyer.incomeFrequency", item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
              {errors.coBuyer?.incomeFrequency && (
                <p className="text-[#d32f2f] text-[0.75rem]">
                  {errors.coBuyer?.incomeFrequency}
                </p>
              )}
            </div>
            <div>
              <h1 className="text-sm font-semibold text-[#183558] pb-2.5 ">
                What is your gross income?
              </h1>
              <TextField
                name="coBuyer.grossIncome"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.coBuyer?.grossIncome}
                fullWidth
                error={Boolean(errors.coBuyer?.grossIncome)}
                helperText={errors.coBuyer?.grossIncome}
                label=""
                variant="outlined"
                inputProps={{ style: { background: "white" } }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Income;
