import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import {
  getCustomerDetail,
  getDriverLicenseUrl,
  getEmploymentUrl,
  getInsuranceUrl,
  getOrderDetail,
} from "../../../api";
import Sidebar from "../Components/Sidebar";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { uploadDriverLicense } from "../../../api";

const validationSchema = Yup.object({
  frontImage: Yup.mixed()
    .required("Front view of the license is required")
    .test("fileFormat", "Only image files are accepted", (value) =>
      value
        ? ["image/jpeg", "image/png", "image/gif"].includes(value.type)
        : false
    ),
  backImage: Yup.mixed()
    .required("Back view of the license is required")
    .test("fileFormat", "Only image files are accepted", (value) =>
      value
        ? ["image/jpeg", "image/png", "image/gif"].includes(value.type)
        : false
    ),
});

const OrderDetail = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const [response, setResponse] = useState();
  const [getloading, setGetLoading] = useState(true);

  const [activeTab, setActiveTab] = useState("Order Details");

  const token = urlParams.get("token");
  const [frontPreview, setFrontPreview] = useState(null);
  const [backPreview, setBackPreview] = useState(null);

  const formik = useFormik({
    initialValues: {
      frontImage: null,
      backImage: null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      const formdata = new FormData();
      formdata.append("frontImage", values.frontImage);
      formdata.append("backImage", values.backImage);
      formdata.append("orderId", id);
      formdata.append("isAdmin", true);
      await uploadDriverLicense(formdata)
        .then((res) => {
          setSubmitting(false);
        })
        .catch((err) => {
          toast.error(err.response.data.error);
          setSubmitting(false);
        });
    },
  });

  const getUploadDrivingLicenseUrl = async () => {
    await getDriverLicenseUrl(id)
      .then((res) => {
        handleCopy(res.data.link);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  const getUploadCustomerDetailsUrl = async () => {
    await getCustomerDetail(id)
      .then((res) => {
        handleCopy(res.data.link);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  const getUploadEmployementInfoUrl = async () => {
    await getEmploymentUrl(id)
      .then((res) => {
        handleCopy(res.data.link);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  const getUploadInsuranceInfoUrl = async () => {
    await getInsuranceUrl(id)
      .then((res) => {
        handleCopy(res.data.link);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  const handleCopy = async (url) => {
    await navigator.clipboard.writeText(url);
    toast.success("Link copied");
  };

  useEffect(() => {
    const fetchData = async () => {
      setGetLoading(true);
      try {
        const res = await getOrderDetail(id);

        setResponse(res.data.data);
        setFrontPreview(res.data.data?.step6?.driverLicense?.frontImage);
        setBackPreview(res.data.data?.step6?.driverLicense?.backImage);
      } catch (err) {
        toast.error(err.response.data.error);
      } finally {
        setGetLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFileChange = (event, field) => {
    const file = event.currentTarget.files[0];
    if (file) {
      formik.setFieldValue(field, file);
      const previewURL = URL.createObjectURL(file);
      if (field === "frontImage") {
        setFrontPreview(previewURL);
      } else if (field === "backImage") {
        setBackPreview(previewURL);
      }
    }
  };

  return (
    <div>
      <Sidebar />
      <div className="p-4 sm:ml-64">
        {getloading ? (
          <div className="min-h-screen flex justify-center items-center">
            <div>
              <ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#000"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                wrapperClassName="spinner"
                visible={true}
              />
            </div>
          </div>
        ) : (
          <div className="max-w-4xl mx-auto">
            {response.step3?.carvanaFinance?.creditInfo && (
              <div className="flex justify-end">
                <h1 className="text-lg text-[#183558] py-4 font-bold border-b border-[#d9dde9] inline-flex ml-auto justify-end">
                  <Link
                    className="flex justify-end cursor-pointer"
                    to={"/credit-info?id=" + id}
                  >
                    Credit Info{" "}
                    <span className="font-bold text-xl">&#x2192;</span>
                  </Link>
                </h1>
              </div>
            )}
            <div className="flex mb-2">
              <div
                onClick={() => {
                  setActiveTab("Order Details");
                }}
                className={`cursor-pointer flex items-center h-8 border rounded-md px-3 text-sm mr-1 ${
                  activeTab === "Order Details"
                    ? "bg-[#00619e] border-[#00619e]  text-[white]"
                    : "bg-white border-[#d9dde9] text-[#183558]"
                }`}
              >
                Order Details
              </div>
              <div
                onClick={() => {
                  setActiveTab("Document Attachments");
                }}
                className={`cursor-pointer flex items-center h-8 border rounded-md px-3 text-sm mr-1 ${
                  activeTab === "Document Attachments"
                    ? "bg-[#00619e] border-[#00619e]  text-[white]"
                    : "bg-white border-[#d9dde9] text-[#183558]"
                }`}
              >
                Document Attachments
              </div>
            </div>
            {activeTab == "Document Attachments" ? (
              <div>
                <div className="flex gap-2 mb-5">
                  <button
                    className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9] font-medium min-h-8  border border-[#00aed9] px-5 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                    onClick={getUploadDrivingLicenseUrl}
                  >
                    Get Driving License Url
                  </button>
                  <button
                    className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9] font-medium min-h-8  border border-[#00aed9] px-5 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                    onClick={getUploadCustomerDetailsUrl}
                  >
                    Get Customer Details Url
                  </button>
                  <button
                    className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9] font-medium min-h-8  border border-[#00aed9] px-5 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                    onClick={getUploadEmployementInfoUrl}
                  >
                    Get Employement Info Url
                  </button>
                  <button
                    className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9] font-medium min-h-8  border border-[#00aed9] px-5 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                    onClick={getUploadInsuranceInfoUrl}
                  >
                    Get Insurance Info Url
                  </button>
                </div>
                <div className="mx-auto mb-10">
                  <div className="grid grid-cols-3 gap-10 mt-10">
                    {response?.step6?.customerDetails && (
                      <a
                        href={response?.step6?.customerDetails}
                        target="_blank"
                      >
                        <div className="flex flex-col justify-center items-center bg-white p-5 rounded-lg cursor-pointer">
                          <img src="/icons/document.svg" className="" />
                          <p className="font-medium mt-2">Customer Details</p>
                        </div>
                      </a>
                    )}
                    {response?.step6?.employmentInfo && (
                      <a href={response?.step6?.employmentInfo} target="_blank">
                        <div className="flex flex-col justify-center items-center bg-white p-5 rounded-lg cursor-pointer">
                          <img src="/icons/document.svg" className="" />
                          <p className="font-medium mt-2">Employement Info</p>
                        </div>
                      </a>
                    )}
                    {response?.step6?.insuranceInfo && (
                      <a href={response?.step6?.insuranceInfo} target="_blank">
                        <div className="flex flex-col justify-center items-center bg-white p-5 rounded-lg cursor-pointer">
                          <img src="/icons/document.svg" className="" />
                          <p className="font-medium mt-2">Insurance Info</p>
                        </div>
                      </a>
                    )}
                  </div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-2 gap-10 mt-10">
                      <div>
                        <div className="relative flex items-center justify-center w-full">
                          <label
                            htmlFor="frontImage"
                            className="relative flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                          >
                            {frontPreview ? (
                              <img
                                src={frontPreview}
                                alt="Front Preview"
                                className="absolute inset-0 w-full h-full object-contain rounded-lg"
                              />
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg
                                  className="w-8 h-8 mb-4 text-gray-500"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 16"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                  />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500">
                                  <span className="font-semibold">
                                    Click to upload
                                  </span>
                                </p>
                                <p className="text-xs text-gray-500">
                                  Add front view of driving license
                                </p>
                              </div>
                            )}
                            <input
                              id="frontImage"
                              name="frontImage"
                              type="file"
                              accept="image/*"
                              className="hidden"
                              onChange={(event) =>
                                handleFileChange(event, "frontImage")
                              }
                            />
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white font-medium text-lg opacity-0 hover:opacity-100 rounded-lg">
                              Change front view of driving license
                            </div>
                          </label>
                        </div>
                        {formik.touched.frontImage &&
                        formik.errors.frontImage ? (
                          <div className="text-red-500 text-sm mt-2">
                            {formik.errors.frontImage}
                          </div>
                        ) : null}
                      </div>

                      <div>
                        <div className="relative flex items-center justify-center w-full">
                          <label
                            htmlFor="backImage"
                            className="relative flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                          >
                            {backPreview ? (
                              <img
                                src={backPreview}
                                alt="Back Preview"
                                className="absolute inset-0 w-full h-full object-contain rounded-lg"
                              />
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg
                                  className="w-8 h-8 mb-4 text-gray-500"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 16"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                  />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500">
                                  <span className="font-semibold">
                                    Click to upload
                                  </span>
                                </p>
                                <p className="text-xs text-gray-500">
                                  Add back view of driving license
                                </p>
                              </div>
                            )}
                            <input
                              id="backImage"
                              name="backImage"
                              type="file"
                              accept="image/*"
                              className="hidden"
                              onChange={(event) =>
                                handleFileChange(event, "backImage")
                              }
                            />
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white font-medium text-lg opacity-0 hover:opacity-100 rounded-lg">
                              Change back view of driving license
                            </div>
                          </label>
                        </div>
                        {formik.touched.backImage && formik.errors.backImage ? (
                          <div className="text-red-500 text-sm mt-2">
                            {formik.errors.backImage}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {(formik.values.frontImage || formik.values.backImage) && (
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          disabled={formik.isSubmitting}
                          className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9] mt-10  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                        >
                          Upload
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            ) : (
              <div
                className={`p-8 pt-0 bg-white border rounded-lg border-[#d9dde9] grid grid-cols-2 gap-6`}
              >
                <div>
                  <h1 className="text-lg text-[#183558] py-4 font-bold border-b border-[#d9dde9]">
                    Order Details
                  </h1>

                  <div className="border-b border-[#d9dde9] py-4">
                    {/* TODO PRODUCT INFO */}
                    <h1 className="text-[#183558] font-bold">
                      2015 GMC Terrain
                    </h1>
                    <p className="text-[#183558] text-sm">
                      SLE-1 Sporty Utility 4D
                    </p>
                    <p className="text-[#183558] text-sm">4-Cyl, 2.4L</p>
                    <p className="text-[#183558] text-sm">33,631 miles</p>
                  </div>

                  <div className="py-4">
                    <h1 className="text-[#183558] font-bold">
                      Delivery Address
                    </h1>
                    <p className="text-[#183558] text-sm">
                      {response.step1.deliveryAddress.line1}
                    </p>
                    <p className="text-[#183558] text-sm">
                      {response.step1.deliveryAddress.city}{" "}
                      {response.step1.deliveryAddress.state}{" "}
                      {response.step1.deliveryAddress.zipCode}
                    </p>
                  </div>

                  <div className="pb-4">
                    <h1 className="text-[#183558] font-bold border-b border-[#d9dde9] py-4">
                      Account Details
                    </h1>
                    <p className="text-[#183558] flex justify-between text-sm mt-4">
                      <span>Legal Name:</span>
                      <span className="text-right">
                        {response.step1.personalDetail.firstName}{" "}
                        {response.step1.personalDetail.lastName}
                      </span>
                    </p>
                    <p className="text-[#183558] flex justify-between text-sm mt-1">
                      <span>Mobile Number:</span>
                      <span className="text-right">
                        {response.step1.personalDetail.phone}
                      </span>
                    </p>
                    <p className="text-[#183558] flex justify-between text-sm mt-1">
                      <span>Email Address:</span>
                      <span className="text-right">
                        {response.step1.personalDetail.email}
                      </span>
                    </p>

                    <h1 className="text-[#183558] font-bold mt-4">
                      Home Address
                    </h1>

                    <p className="text-[#183558] flex justify-between text-sm mt-1">
                      <span>Street:</span>
                      <span className="text-right">
                        {response.step1.homeAddress.line1}
                      </span>
                    </p>

                    <p className="text-[#183558] flex justify-between text-sm mt-1">
                      <span>City, State, ZIP:</span>
                      <span className="text-right">
                        {response.step1.homeAddress.city}{" "}
                        {response.step1.homeAddress.state}{" "}
                        {response.step1.homeAddress.zipCode}
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <h1 className="text-lg text-[#183558] py-4 font-bold border-b border-[#d9dde9]">
                    Cost Breakdown
                  </h1>
                  <p className="text-[#183558] flex justify-between text-sm mt-1">
                    <span>Caravana Price:</span>
                    <span className="text-right">$18,590</span>
                  </p>
                  <p className="text-[#183558] flex justify-between text-sm mt-2">
                    <span>Tax:</span>
                    <span className="text-right">$425</span>
                  </p>
                  <p className="text-sm text-[#183558] my-2 bg-[#f4f8fa] p-4">
                    Includes $150 in North Carolina sales tax saving for trading
                    in your vehicle
                  </p>
                  <p className="text-[#183558] flex justify-between text-sm mt-2">
                    <span>Total title and registration:</span>
                    <span className="text-right">$107</span>
                  </p>
                  <p className="text-[#183558] flex justify-between text-sm mt-2">
                    <span>Shipping (Nonrefundable):</span>
                    <span className="text-right">$0.00</span>
                  </p>
                  <p className="text-xs text-[#183558]">
                    Shipping the car to a location near to you
                  </p>
                  {response.step4.deliveryOption.type ===
                    "Have it delivered" && (
                    <>
                      <p className="text-[#183558] flex justify-between text-sm mt-2">
                        <span>Delivery:</span>
                        <span className="text-right">$590</span>
                      </p>
                      <p className="text-xs text-[#183558]">
                        Delivering the car from our location near to you
                      </p>
                    </>
                  )}
                  <p className="text-[#183558] flex justify-between text-sm mt-2">
                    <span>Dealer Fee:</span>
                    <span className="text-right">NEVER</span>
                  </p>
                  <p className="text-[#183558] flex justify-between text-sm mt-2">
                    <span>Hidden Fee:</span>
                    <span className="text-right">NEVER</span>
                  </p>
                  <div className="my-4 py-4 border-y border-[#d9dde9]">
                    {response.step5.protection.type && (
                      <p className="text-[#183558] flex justify-between text-sm">
                        <span>
                          RenaissanceCare {response.step5.protection.type}:
                        </span>
                        <span className="text-right">
                          {response.step5.protection.type === "ESSENTIAL"
                            ? "$1800"
                            : ""}
                          {response.step5.protection.type === "PLUS"
                            ? "$2900"
                            : ""}
                          {response.step5.protection.type === "PREMIER"
                            ? "$3700"
                            : ""}{" "}
                        </span>
                      </p>
                    )}
                  </div>
                  <p className="text-[#183558] flex justify-between items-start mt-2">
                    <span className="font-bold">
                      Subtotal
                      <br />
                      <span className="text-right text-xs font-normal">
                        (Including taxes, fees, upgrade)
                      </span>
                    </span>
                    <span className="text-base font-bold">$22813.15</span>
                  </p>

                  {response.step2.trade_in && (
                    <>
                      <p className="text-[#183558] flex justify-between text-sm mt-2">
                        <span>Trade-In:</span>
                        <span className="text-right">
                          {response.step2.blackBookData.model_year}{" "}
                          {response.step2.blackBookData.make}{" "}
                          {response.step2.blackBookData.model_year}
                        </span>
                      </p>
                      <p className="text-[#183558] flex justify-between text-sm mt-2">
                        <span>Your Renaissance Offer:</span>
                        <span className="text-right">
                          ${response.step2.blackBookData?.base_tradein_clean}
                        </span>
                      </p>
                      <p className="text-[#183558] flex justify-between text-sm mt-2">
                        <span>Trade-in loan balance:</span>
                        <span className="text-right">$0</span>
                      </p>
                      <p className="text-[#183558] flex justify-between text-sm mt-2">
                        <span>Trade-in value:</span>
                        <span className="text-right">
                          ${response.step2.blackBookData?.base_tradein_clean}
                        </span>
                      </p>
                      <p className="text-[#183558] text-xs mt-1">
                        Your trade-in value of $5000 has been applied to your
                        purchase as a down payment, reducing the total amount
                        you need to financr
                      </p>
                    </>
                  )}

                  <p className="text-[#183558] flex justify-between items-start mt-2">
                    <span className="font-bold">
                      Total Price
                      <br />
                      <span className="text-right text-xs font-normal">
                        (Including taxes, fees, upgrade)
                      </span>
                    </span>
                    <span className="text-base font-bold">$22813.15</span>
                  </p>
                  <p className="text-[#183558] flex justify-between items-start mt-6">
                    <span className="font-bold">Due at order</span>
                    <span className="text-base font-bold">$22813.15</span>
                  </p>
                  <p className="text-[#183558] flex justify-between items-start mt-6">
                    <span className="font-bold">Total Finance</span>
                    <span className="text-base font-bold">$22813.15</span>
                  </p>
                  <p className="text-[#183558] mt-8 pb-2 border-b border-[#d9dde9]">
                    <span className="font-bold">Payment Method & Terms</span>
                  </p>
                  <p className="text-[#183558] flex justify-between text-sm mt-2">
                    <span>Payment Method:</span>
                    <span className="text-right">
                      {response.step3.paymentMethod}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetail;
