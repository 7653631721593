import React, { useEffect, useState } from "react";
import { PlaidLink } from "react-plaid-link";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";

const BankVerification = ({
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
}) => {
  const [linkToken, setLinkToken] = useState("");

  const handleOnSuccess = async (public_token, metadata) => {
    // send token to client server
    var data = {
      public_token: public_token,
    };
    var res = await axios.post(
      `${process.env.REACT_APP_BASEURL}user/exchange_public_token`,
      data
    );
    console.log(res);
    //to do set accessToken into sessionStorage then move onto UI calls in other components.
    sessionStorage.setItem("accessToken", res.data["access_token"]);
  };

  useEffect(() => {
    const getToken = async () => {
      var res = await axios.post(
        `${process.env.REACT_APP_BASEURL}user/create_link_token`,
        {
          id: response._id,
          name:
            response.step1?.personalDetail.firstName +
            " " +
            response.step1?.personalDetail.lastName,
        }
      );
      setLinkToken(res.data.data["link_token"]);
    };
    getToken();
  }, []);

  return linkToken ? (
    <div className="">
      <h1 className="text-3xl text-[#183558] mb-4 font-bold">
        Verify your bank account
      </h1>
      <div className="mt-6 p-8 text-sm bg-white rounded-lg">
        <p className="text-sm pb-4 mb-4 border-b border-[#d9dde9]">
          We need to verify your bank account ending in{" "}
          {response.step7.number.slice(-4)} to complete your order. Please
          follow the instructions provided by Plaid. We'll use this account for
          your payments moving forward.
        </p>
        <p className="font-semibold">
          <span className="text-[10px] font-bold">SECURED BY</span> PLAID
        </p>
      </div>
      <p className="text-xs my-6 max-w-md text-center mx-auto">
        By clicking "Verify My Account", I give Renaissance written consent to
        verify the account. I provided by obtaining a consumer report from a
        consumer reporting agency.
      </p>
      <div className="flex justify-center my-4">
        <button
          type="submit"
          className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] plaidbutton"
        >
          {linkToken ? (
            <PlaidLink
              token={linkToken.toString()}
              env="sandbox"
              onSuccess={handleOnSuccess}
              style={{ background: "tranparent" }}
              onExit={() => {
                console.log("Exited");
              }}
            >
              VERIFY MY ACCOUNT
            </PlaidLink>
          ) : null}
        </button>
      </div>
    </div>
  ) : (
    <div className="min-h-screen flex justify-center">
      <div>
        <ThreeDots
          height="40"
          width="80"
          radius="9"
          color="#000"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
          }}
          wrapperClassName="spinner"
          visible={true}
        />
      </div>
    </div>
  );
};

export default BankVerification;
