import React, { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { States, Suffix } from "../../../constants";

const phoneRegExp = /^\+?\d{6,15}$/;

const ValidationSchema = Yup.object().shape({
  personalDetail: Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    middleName: Yup.string(),
    suffix: Yup.string(),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid"),
    houseOwner: Yup.string().required("Status is required"),
    maritalStatus: Yup.string().required("Status is required"),
    militaryStatus: Yup.string().required("Status is required"),
  }),
  homeAddress: Yup.object().shape({
    line1: Yup.string().required("Address line 1 is required"),
    line2: Yup.string(),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Zip code is required"),
    city: Yup.string().required("City is required"),
  }),
  deliveryAddress: Yup.object().shape({
    line1: Yup.string().required("Address line 1 is required"),
    line2: Yup.string(),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Zip code is required"),
    city: Yup.string().required("City is required"),
  }),
  mailingAddress: Yup.object().shape({
    line1: Yup.string().required("Address line 1 is required"),
    line2: Yup.string(),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Zip code is required"),
    city: Yup.string().required("City is required"),
  }),
});

const PersonalDetails = ({
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
  editing,
}) => {
  const [loading, setLoading] = useState();
  const [isEdit, setIsEdit] = useState(editing);
  const [isDeliverySame, setIsDeliverySame] = useState(
    response.step1.isDeliverySame
  );
  const [isMailingSame, setIsMailingSame] = useState(
    response.step1.isMailingSame
  );
  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-3xl text-[#183558] mb-4 font-bold">
        Personal Details
      </h1>
      {!isEdit ? (
        <div>
          <p className="text-sm text-[#757989] mb-10">
            This is the personal information that Renaissance will use for
            purchasing and registering your car.
          </p>
          <div className="pb-6 bg-white border border-[#d9dde9] rounded px-6 mb-10">
            <div className="flex items-center justify-between border-b border-[#d9dde9] py-5">
              <h1 className="text-lg  font-bold text-[#183558]">
                Verify Details
              </h1>
              <p
                className="text-[#00aed9] font-medium cursor-pointer"
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                EDIT
              </p>
            </div>
            <div className="grid grid-cols-2 pt-3">
              <div>
                <div className="pb-4">
                  <p className="font-bold text-[#183558]">Legal Name</p>
                  <p className="text-[#333333]">
                    {response.step1?.personalDetail?.suffix}{" "}
                    {response.step1?.personalDetail.firstName}{" "}
                    {response.step1?.personalDetail.middleName}{" "}
                    {response.step1?.personalDetail.lastName}
                  </p>
                </div>
                <div className="pb-4">
                  <p className="font-bold text-[#183558]">Phone</p>
                  <p className="text-[#333333]">
                    {response.step1?.personalDetail.phone}
                  </p>
                </div>
              </div>
              <div>
                <div className="pb-4">
                  <p className="font-bold text-[#183558]">Address</p>
                  <p className="text-[#333333]">
                    {response.step1?.homeAddress?.line1}{" "}
                    {response.step1?.homeAddress?.line2}
                  </p>
                  <p className="text-[#333333]">
                    {response.step1?.homeAddress?.city}{" "}
                    {response.step1?.homeAddress?.state}{" "}
                    {response.step1?.homeAddress?.zipCode}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              disabled={loading}
              onClick={() => {
                setLoading(true);
                handleSaveFormData(
                  {
                    ...response,
                    step1: { ...response.step1, completed: true },
                  },
                  setLoading,
                  (res) => {
                    setCurrentStep((prev) => prev + 1);
                    setResponse(res.data.data);
                  }
                );
              }}
              className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-64 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
            >
              Continue
            </button>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-sm text-[#757989] mb-10">
            Your name should match your driver’s license.
          </p>
          <Formik
            initialValues={{
              ...response.step1,
            }}
            enableReinitialize
            onSubmit={async (values, { setSubmitting, isSubmitting }) => {
              handleSaveFormData(
                {
                  ...response,
                  step1: {
                    ...response.step1,
                    ...values,
                    completed: true,
                    isDeliverySame,
                    isMailingSame,
                  },
                },
                setSubmitting,
                (res) => {
                  setCurrentStep((prev) => prev + 1);
                  setResponse(res.data.data);
                }
              );
            }}
            validationSchema={ValidationSchema}
          >
            {({
              isSubmitting,
              errors,
              values,
              setValues,
              setFieldValue,
              handleChange,
              handleBlur,
              touched,
            }) => (
              <Form>
                <div className="grid gap-4">
                  <TextField
                    name="personalDetail.firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.personalDetail?.firstName}
                    fullWidth
                    error={Boolean(errors.personalDetail?.firstName)}
                    helperText={errors.personalDetail?.firstName}
                    label="FIRST NAME"
                    variant="outlined"
                    inputProps={{ style: { background: "white" } }}
                  />

                  <TextField
                    name="personalDetail.middleName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.personalDetail?.middleName}
                    fullWidth
                    error={Boolean(errors.personalDetail?.middleName)}
                    helperText={errors.personalDetail?.middleName}
                    label="MIDDLE NAME (OPTIONAL)"
                    variant="outlined"
                    inputProps={{ style: { background: "white" } }}
                  />
                </div>
                <div className="grid md:grid-cols-3 gap-2">
                  <div className="mt-4">
                    <TextField
                      name="personalDetail.lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.personalDetail?.lastName}
                      fullWidth
                      error={Boolean(errors.personalDetail?.lastName)}
                      helperText={errors.personalDetail?.lastName}
                      label="LAST NAME"
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                  </div>
                  <div className="mt-4">
                    <FormControl
                      fullWidth
                      error={Boolean(errors.personalDetail?.suffix)}
                    >
                      <InputLabel id="select">SUFFIX</InputLabel>
                      <Select
                        name="personalDetail.suffix"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.personalDetail?.suffix}
                        fullWidth
                        labelId="select"
                        label="SUFFIX"
                        variant="outlined"
                        sx={{ background: "white" }}
                      >
                        {Suffix.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item == "None" ? "" : item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors.personalDetail?.suffix}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div className="mt-4">
                    <TextField
                      name="personalDetail.phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.personalDetail?.phone}
                      fullWidth
                      error={Boolean(errors.personalDetail?.phone)}
                      helperText={errors.personalDetail?.phone}
                      label="MOBILE NUMBER"
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                  </div>
                </div>
                <div className="my-4">
                  <TextField
                    name="personalDetail.email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.personalDetail?.email}
                    fullWidth
                    error={Boolean(errors.personalDetail?.email)}
                    helperText={errors.personalDetail?.email}
                    label="EMAIL"
                    variant="outlined"
                    inputProps={{ style: { background: "white" } }}
                  />
                </div>

                <div>
                  <p className="text-xs uppercase text-[#757989] my-2 font-medium">
                    MILITARY STATUS
                  </p>
                  <div className="flex">
                    <div
                      onClick={() => {
                        setFieldValue("personalDetail.militaryStatus", "None");
                      }}
                      className={`cursor-pointer flex items-center h-8 border rounded-md px-3 text-sm mr-1 ${
                        values.personalDetail.militaryStatus === "None"
                          ? "bg-[#00619e] border-[#00619e]  text-[white]"
                          : "bg-white border-[#d9dde9] text-[#183558]"
                      }`}
                    >
                      None
                    </div>
                    <div
                      onClick={() => {
                        setFieldValue(
                          "personalDetail.militaryStatus",
                          "Active"
                        );
                      }}
                      className={`cursor-pointer flex items-center h-8 border rounded-md px-3 text-sm mr-1 ${
                        values.personalDetail.militaryStatus === "Active"
                          ? "bg-[#00619e] border-[#00619e]  text-[white]"
                          : "bg-white border-[#d9dde9] text-[#183558]"
                      }`}
                    >
                      Active
                    </div>
                    <div
                      onClick={() => {
                        setFieldValue(
                          "personalDetail.militaryStatus",
                          "Veteran"
                        );
                      }}
                      className={`cursor-pointer flex items-center h-8 border rounded-md  px-3 text-sm mr-1 ${
                        values.personalDetail.militaryStatus === "Veteran"
                          ? "bg-[#00619e] border-[#00619e]  text-[white]"
                          : "bg-white border-[#d9dde9] text-[#183558]"
                      }`}
                    >
                      Veteran
                    </div>
                  </div>
                  <p className="text-xs uppercase text-[#757989] my-2 font-medium">
                    MARITAL STATUS
                  </p>
                  <div className="flex">
                    <div
                      onClick={() => {
                        setFieldValue(
                          "personalDetail.maritalStatus",
                          "Married"
                        );
                      }}
                      className={`cursor-pointer flex items-center h-8  border  rounded-md px-3 text-sm mr-1 ${
                        values.personalDetail.maritalStatus === "Married"
                          ? "bg-[#00619e] border-[#00619e]  text-[white]"
                          : "bg-white border-[#d9dde9] text-[#183558]"
                      }`}
                    >
                      Married
                    </div>
                    <div
                      onClick={() => {
                        setFieldValue("personalDetail.maritalStatus", "Single");
                      }}
                      className={`cursor-pointer flex items-center h-8  border rounded-md  px-3 text-sm mr-1 ${
                        values.personalDetail.maritalStatus === "Single"
                          ? "bg-[#00619e] border-[#00619e]  text-[white]"
                          : "bg-white border-[#d9dde9] text-[#183558]"
                      }`}
                    >
                      Single
                    </div>
                    <div
                      onClick={() => {
                        setFieldValue(
                          "personalDetail.maritalStatus",
                          "Widowed"
                        );
                      }}
                      className={`cursor-pointer flex items-center h-8  border  rounded-md px-3 text-sm mr-1 ${
                        values.personalDetail.maritalStatus === "Widowed"
                          ? "bg-[#00619e] border-[#00619e]  text-[white]"
                          : "bg-white border-[#d9dde9] text-[#183558]"
                      }`}
                    >
                      Widowed
                    </div>
                  </div>
                  <p className="text-xs uppercase text-[#757989] my-2 font-medium">
                    HOME OWNERSHIP STATUS
                  </p>
                  <div className="flex">
                    <div
                      onClick={() => {
                        setFieldValue("personalDetail.houseOwner", "Own");
                      }}
                      className={`cursor-pointer flex items-center h-8  border rounded-md  px-3 text-sm mr-1 ${
                        values.personalDetail.houseOwner === "Own"
                          ? "bg-[#00619e] border-[#00619e]  text-[white]"
                          : "bg-white border-[#d9dde9] text-[#183558]"
                      }`}
                    >
                      Own
                    </div>
                    <div
                      onClick={() => {
                        setFieldValue("personalDetail.houseOwner", "Rent");
                      }}
                      className={`cursor-pointer flex items-center h-8  border rounded-md  px-3 text-sm mr-1 ${
                        values.personalDetail.houseOwner === "Rent"
                          ? "bg-[#00619e] border-[#00619e]  text-[white]"
                          : "bg-white border-[#d9dde9] text-[#183558]"
                      }`}
                    >
                      Rent
                    </div>
                  </div>
                </div>

                <div>
                  <p className="text-[#183558] mb-2 mt-10 font-bold">
                    HOME ADDRESS
                  </p>
                  <p className="text-sm text-[#757989] mb-5">
                    We’ll register your vehicle here and it’s normally the
                    address on your driver’s license. This can’t be changed
                    after you accept the vehicle.
                  </p>
                  <div className="grid gap-4">
                    <TextField
                      name="homeAddress.line1"
                      onChange={(e) => {
                        handleChange(e);
                        if (isDeliverySame) {
                          setFieldValue(
                            "deliveryAddress.line1",
                            e.target.value
                          );
                        }
                        if (isMailingSame) {
                          setFieldValue("mailingAddress.line1", e.target.value);
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.homeAddress?.line1}
                      fullWidth
                      error={Boolean(errors.homeAddress?.line1)}
                      helperText={errors.homeAddress?.line1}
                      label="ADDRESS, LINE 1"
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                    <TextField
                      name="homeAddress.line2"
                      onChange={(e) => {
                        handleChange(e);
                        if (isDeliverySame) {
                          setFieldValue(
                            "deliveryAddress.line2",
                            e.target.value
                          );
                        }
                        if (isMailingSame) {
                          setFieldValue("mailingAddress.line2", e.target.value);
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.homeAddress?.line2}
                      fullWidth
                      error={Boolean(errors.homeAddress?.line2)}
                      helperText={errors.homeAddress?.line2}
                      label="ADDRESS, LINE 2 (Optional)"
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                  </div>
                  <div className="grid md:grid-cols-4 gap-2">
                    <div className="mt-4 md:col-span-2">
                      <TextField
                        name="homeAddress.city"
                        onChange={(e) => {
                          handleChange(e);
                          if (isDeliverySame) {
                            setFieldValue(
                              "deliveryAddress.city",
                              e.target.value
                            );
                          }
                          if (isMailingSame) {
                            setFieldValue(
                              "mailingAddress.city",
                              e.target.value
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.homeAddress?.city}
                        fullWidth
                        error={Boolean(errors.homeAddress?.city)}
                        helperText={errors.homeAddress?.city}
                        label="CITY"
                        variant="outlined"
                        inputProps={{ style: { background: "white" } }}
                      />
                    </div>
                    <div className="mt-4">
                      <FormControl
                        fullWidth
                        error={Boolean(errors.homeAddress?.state)}
                      >
                        <InputLabel id="select">STATE</InputLabel>
                        <Select
                          name="homeAddress.state"
                          onChange={(e) => {
                            handleChange(e);
                            if (isDeliverySame) {
                              setFieldValue(
                                "deliveryAddress.state",
                                e.target.value
                              );
                            }
                            if (isMailingSame) {
                              setFieldValue(
                                "mailingAddress.state",
                                e.target.value
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.homeAddress?.state}
                          labelId="select"
                          label="STATE"
                          sx={{ background: "white" }}
                        >
                          {States.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.homeAddress?.state}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div className="mt-4">
                      <TextField
                        name="homeAddress.zipCode"
                        onChange={(e) => {
                          handleChange(e);
                          if (isDeliverySame) {
                            setFieldValue(
                              "deliveryAddress.zipCode",
                              e.target.value
                            );
                          }
                          if (isMailingSame) {
                            setFieldValue(
                              "mailingAddress.zipCode",
                              e.target.value
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.homeAddress?.zipCode}
                        fullWidth
                        error={Boolean(errors.homeAddress?.zipCode)}
                        helperText={errors.homeAddress?.zipCode}
                        label="ZIP CODE"
                        variant="outlined"
                        inputProps={{ style: { background: "white" } }}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="text-[#183558] mb-2 mt-10 font-bold">
                      DELIVERY ADDRESS
                    </p>
                    <p className="text-sm text-[#757989] mb-5">
                      This is where you want to get your car. If you pick your
                      car up, we'll use it to help you find a pickup location.
                    </p>
                    <div className="flex items-center -ml-2">
                      <InputLabel
                        id="shipping"
                        sx={{ cursor: "pointer", marginBottom: "8px" }}
                      >
                        <Checkbox
                          checked={isDeliverySame}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            setIsDeliverySame(e.target.checked);
                            if (e.target.checked) {
                              setValues({
                                ...values,
                                deliveryAddress: { ...values.homeAddress },
                              });
                            }
                          }}
                          labelId="shipping"
                          sx={{
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        Same as home address
                      </InputLabel>
                    </div>
                    {!isDeliverySame && (
                      <>
                        <div className="grid gap-4">
                          <TextField
                            name="deliveryAddress.line1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.deliveryAddress?.line1}
                            fullWidth
                            error={Boolean(errors.deliveryAddress?.line1)}
                            helperText={errors.deliveryAddress?.line1}
                            label="ADDRESS, LINE 1"
                            variant="outlined"
                            inputProps={{ style: { background: "white" } }}
                          />
                          <TextField
                            name="deliveryAddress.line2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.deliveryAddress?.line2}
                            fullWidth
                            error={Boolean(errors.deliveryAddress?.line2)}
                            helperText={errors.deliveryAddress?.line2}
                            label="ADDRESS, LINE 2 (Optional)"
                            variant="outlined"
                            inputProps={{ style: { background: "white" } }}
                          />
                        </div>
                        <div className="grid md:grid-cols-4 gap-2">
                          <div className="mt-4 md:col-span-2">
                            <TextField
                              name="deliveryAddress.city"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.deliveryAddress?.city}
                              fullWidth
                              error={Boolean(errors.deliveryAddress?.city)}
                              helperText={errors.deliveryAddress?.city}
                              label="CITY"
                              variant="outlined"
                              inputProps={{ style: { background: "white" } }}
                            />
                          </div>
                          <div className="mt-4">
                            <FormControl
                              fullWidth
                              error={Boolean(errors.deliveryAddress?.state)}
                            >
                              <InputLabel id="select">STATE</InputLabel>
                              <Select
                                name="deliveryAddress.state"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.deliveryAddress?.state}
                                labelId="select"
                                label="STATE"
                                sx={{ background: "white" }}
                              >
                                {States.map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {errors.deliveryAddress?.state}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <div className="mt-4">
                            <TextField
                              name="deliveryAddress.zipCode"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.deliveryAddress?.zipCode}
                              fullWidth
                              error={Boolean(errors.deliveryAddress?.zipCode)}
                              helperText={errors.deliveryAddress?.zipCode}
                              label="ZIP CODE"
                              variant="outlined"
                              inputProps={{ style: { background: "white" } }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <p className="text-[#183558] mb-2 mt-10 font-bold">
                      MAILING ADDRESS
                    </p>
                    <p className="text-sm text-[#757989] mb-5">
                      This is where we’ll send your license plates.
                    </p>
                    <div className="flex items-center -ml-2">
                      <InputLabel
                        id="mailing"
                        sx={{ cursor: "pointer", marginBottom: "8px" }}
                      >
                        <Checkbox
                          checked={isMailingSame}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          onChange={(e) => {
                            setIsMailingSame(e.target.checked);
                            if (e.target.checked) {
                              setValues({
                                ...values,
                                mailingAddress: { ...values.homeAddress },
                              });
                            }
                          }}
                          labelId="mailing"
                          sx={{
                            color: "blue",
                            "&.Mui-checked": {
                              color: "blue",
                            },
                          }}
                        />
                        Same as home address
                      </InputLabel>
                    </div>
                    {!isMailingSame && (
                      <>
                        <div className="grid gap-4">
                          <TextField
                            name="mailingAddress.line1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailingAddress?.line1}
                            fullWidth
                            error={Boolean(errors.mailingAddress?.line1)}
                            helperText={errors.mailingAddress?.line1}
                            label="ADDRESS, LINE 1"
                            variant="outlined"
                            inputProps={{ style: { background: "white" } }}
                          />
                          <TextField
                            name="mailingAddress.line2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailingAddress?.line2}
                            fullWidth
                            error={Boolean(errors.mailingAddress?.line2)}
                            helperText={errors.mailingAddress?.line2}
                            label="ADDRESS, LINE 2 (Optional)"
                            variant="outlined"
                            inputProps={{ style: { background: "white" } }}
                          />
                        </div>
                        <div className="grid md:grid-cols-4 gap-2">
                          <div className="mt-4 md:col-span-2">
                            <TextField
                              name="mailingAddress.city"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mailingAddress?.city}
                              fullWidth
                              error={Boolean(errors.mailingAddress?.city)}
                              helperText={errors.mailingAddress?.city}
                              label="CITY"
                              variant="outlined"
                              inputProps={{ style: { background: "white" } }}
                            />
                          </div>
                          <div className="mt-4">
                            <FormControl
                              fullWidth
                              error={Boolean(errors.mailingAddress?.state)}
                            >
                              <InputLabel id="select">STATE</InputLabel>
                              <Select
                                name="mailingAddress.state"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mailingAddress?.state}
                                labelId="select"
                                label="STATE"
                                sx={{ background: "white" }}
                              >
                                {States.map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {errors.mailingAddress?.state}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <div className="mt-4">
                            <TextField
                              name="mailingAddress.zipCode"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mailingAddress?.zipCode}
                              fullWidth
                              error={Boolean(errors.mailingAddress?.zipCode)}
                              helperText={errors.mailingAddress?.zipCode}
                              label="ZIP CODE"
                              variant="outlined"
                              inputProps={{ style: { background: "white" } }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="flex justify-center mt-10">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded uppercase text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-64 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                  >
                    {isSubmitting ? (
                      <ThreeDots
                        height="20"
                        width="60"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "block",
                        }}
                        wrapperClassName="spinner"
                        visible={true}
                      />
                    ) : (
                      "Save & Continue"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default PersonalDetails;
