import { Checkbox } from "@mui/material";
import React, { useState } from "react";

const Review = ({
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
  setEditing,
}) => {
  const [loading, setLoading] = useState();
  const [term, setTerm] = useState(false);
  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl text-[#183558] mb-4 font-bold">Review Order</h1>
      <p className="text-sm text-[#757989] mb-10">
        Please crafeully review and confirm your order details below
        <br />
        You will pick your specific date & time for your delivery after
        completing the required verification.
      </p>
      <div
        className={`p-8 pt-0 bg-white border rounded-lg border-[#d9dde9] grid grid-cols-2 gap-6`}
      >
        <div>
          <h1 className="text-lg text-[#183558] py-4 font-bold border-b border-[#d9dde9]">
            Order Details
          </h1>
          <div className="border-b border-[#d9dde9] py-4">
            {/* TODO PRODUCT INFO */}
            <h1 className="text-[#183558] font-bold">2015 GMC Terrain</h1>
            <p className="text-[#183558] text-sm">SLE-1 Sporty Utility 4D</p>
            <p className="text-[#183558] text-sm">4-Cyl, 2.4L</p>
            <p className="text-[#183558] text-sm">33,631 miles</p>
          </div>

          <div className="py-4">
            <h1 className="text-[#183558] font-bold">Delivery Address</h1>
            <p className="text-[#183558] text-sm">
              {response.step1.deliveryAddress.line1}
            </p>
            <p className="text-[#183558] text-sm">
              {response.step1.deliveryAddress.city}{" "}
              {response.step1.deliveryAddress.state}{" "}
              {response.step1.deliveryAddress.zipCode}
            </p>
            <p
              className="text-[#00aed9] text-sm cursor-pointer mt-4"
              onClick={() => {
                setCurrentStep(1);
                setEditing(true);
              }}
            >
              Edit Delivery Details
            </p>
          </div>

          <div className="pb-4">
            <h1 className="text-[#183558] font-bold border-b border-[#d9dde9] py-4">
              Account Details
            </h1>
            <p className="text-[#183558] flex justify-between text-sm mt-4">
              <span>Legal Name:</span>
              <span className="text-right">
                {response.step1.personalDetail.firstName}{" "}
                {response.step1.personalDetail.lastName}
              </span>
            </p>
            <p className="text-[#183558] flex justify-between text-sm mt-1">
              <span>Mobile Number:</span>
              <span className="text-right">
                {response.step1.personalDetail.phone}
              </span>
            </p>
            <p className="text-[#183558] flex justify-between text-sm mt-1">
              <span>Email Address:</span>
              <span className="text-right">
                {response.step1.personalDetail.email}
              </span>
            </p>

            <h1 className="text-[#183558] font-bold mt-4">Home Address</h1>

            <p className="text-[#183558] flex justify-between text-sm mt-1">
              <span>Street:</span>
              <span className="text-right">
                {response.step1.homeAddress.line1}
              </span>
            </p>

            <p className="text-[#183558] flex justify-between text-sm mt-1">
              <span>City, State, ZIP:</span>
              <span className="text-right">
                {response.step1.homeAddress.city}{" "}
                {response.step1.homeAddress.state}{" "}
                {response.step1.homeAddress.zipCode}
              </span>
            </p>
            <p
              className="text-[#00aed9] text-sm cursor-pointer mt-4"
              onClick={() => {
                setCurrentStep(1);
                setEditing(true);
              }}
            >
              Edit Account Details
            </p>
          </div>
        </div>
        <div>
          <h1 className="text-lg text-[#183558] py-4 font-bold border-b border-[#d9dde9]">
            Cost Breakdown
          </h1>
          <p className="text-[#183558] flex justify-between text-sm mt-1">
            <span>Caravana Price:</span>
            <span className="text-right">$18,590</span>
          </p>
          <p className="text-[#183558] flex justify-between text-sm mt-2">
            <span>Tax:</span>
            <span className="text-right">$425</span>
          </p>
          <p className="text-sm text-[#183558] my-2 bg-[#f4f8fa] p-4">
            Includes $150 in North Carolina sales tax saving for trading in your
            vehicle
          </p>
          <p className="text-[#183558] flex justify-between text-sm mt-2">
            <span>Total title and registration:</span>
            <span className="text-right">$107</span>
          </p>
          <p className="text-[#183558] flex justify-between text-sm mt-2">
            <span>Shipping (Nonrefundable):</span>
            <span className="text-right">$0.00</span>
          </p>
          <p className="text-xs text-[#183558]">
            Shipping the car to a location near to you
          </p>
          {response.step4.deliveryOption.type === "Have it delivered" && (
            <>
              <p className="text-[#183558] flex justify-between text-sm mt-2">
                <span>Delivery:</span>
                <span className="text-right">$590</span>
              </p>
              <p className="text-xs text-[#183558]">
                Delivering the car from our location near to you
              </p>
            </>
          )}
          <p className="text-[#183558] flex justify-between text-sm mt-2">
            <span>Dealer Fee:</span>
            <span className="text-right">NEVER</span>
          </p>
          <p className="text-[#183558] flex justify-between text-sm mt-2">
            <span>Hidden Fee:</span>
            <span className="text-right">NEVER</span>
          </p>
          <div className="my-4 py-4 border-y border-[#d9dde9]">
            {response.step5.protection.type && (
              <p className="text-[#183558] flex justify-between text-sm">
                <span>RenaissanceCare {response.step5.protection.type}:</span>
                <span className="text-right">
                  {response.step5.protection.type === "ESSENTIAL"
                    ? "$1800"
                    : ""}
                  {response.step5.protection.type === "PLUS" ? "$2900" : ""}
                  {response.step5.protection.type === "PREMIER"
                    ? "$3700"
                    : ""}{" "}
                </span>
              </p>
            )}
            <p
              className="text-[#00aed9] text-sm cursor-pointer mt-4"
              onClick={() => {
                setCurrentStep(5);
              }}
            >
              Edit Protection Details
            </p>
          </div>
          <p className="text-[#183558] flex justify-between items-start mt-2">
            <span className="font-bold">
              Subtotal
              <br />
              <span className="text-right text-xs font-normal">
                (Including taxes, fees, upgrade)
              </span>
            </span>
            <span className="text-base font-bold">$22813.15</span>
          </p>

          {response.step2.trade_in && (
            <>
              <p className="text-[#183558] flex justify-between text-sm mt-2">
                <span>Trade-In:</span>
                <span className="text-right">
                  {response.step2.blackBookData.model_year}{" "}
                  {response.step2.blackBookData.make}{" "}
                  {response.step2.blackBookData.model_year}
                </span>
              </p>
              <p className="text-[#183558] flex justify-between text-sm mt-2">
                <span>Your Renaissance Offer:</span>
                <span className="text-right">
                  ${response.step2.blackBookData?.base_tradein_clean}
                </span>
              </p>
              <p className="text-[#183558] flex justify-between text-sm mt-2">
                <span>Trade-in loan balance:</span>
                <span className="text-right">$0</span>
              </p>
              <p className="text-[#183558] flex justify-between text-sm mt-2">
                <span>Trade-in value:</span>
                <span className="text-right">
                  ${response.step2.blackBookData?.base_tradein_clean}
                </span>
              </p>
              <p className="text-[#183558] text-xs mt-1">
                Your trade-in value of $5000 has been applied to your purchase
                as a down payment, reducing the total amount you need to financr
              </p>
            </>
          )}
          <div className="my-4 py-4 border-y border-[#d9dde9]">
            <p
              className="text-[#00aed9] text-sm cursor-pointer"
              onClick={() => {
                setCurrentStep(2);
              }}
            >
              Edit Trade-In Details
            </p>
          </div>
          <p className="text-[#183558] flex justify-between items-start mt-2">
            <span className="font-bold">
              Total Price
              <br />
              <span className="text-right text-xs font-normal">
                (Including taxes, fees, upgrade)
              </span>
            </span>
            <span className="text-base font-bold">$22813.15</span>
          </p>
          <p className="text-[#183558] flex justify-between items-start mt-6">
            <span className="font-bold">Due at order</span>
            <span className="text-base font-bold">$22813.15</span>
          </p>
          <p className="text-[#183558] flex justify-between items-start mt-6">
            <span className="font-bold">Total Finance</span>
            <span className="text-base font-bold">$22813.15</span>
          </p>
          <p className="text-[#183558] mt-8 pb-2 border-b border-[#d9dde9]">
            <span className="font-bold">Payment Method & Terms</span>
          </p>
          <p className="text-[#183558] flex justify-between text-sm mt-2">
            <span>Payment Method:</span>
            <span className="text-right">{response.step3.paymentMethod}</span>
          </p>
          <p
            className="text-[#00aed9] text-sm mt-4 cursor-pointer"
            onClick={() => {
              setCurrentStep(7);
            }}
          >
            Edit Payment Details
          </p>
        </div>
      </div>
      <div
        className={`p-4 pt-0 bg-white border rounded-lg border-[#d9dde9] mt-10`}
      >
        <p id="agree" className="cursor-pointer flex items-center mt-4 w-full">
          <Checkbox
            checked={term}
            inputProps={{ "aria-label": "Checkbox demo" }}
            onChange={(e) => {
              if (e.target.checked) {
                setTerm(true);
              } else {
                setTerm(false);
              }
            }}
            labelId="agree"
            sx={{
              color: "blue",
              "&.Mui-checked": {
                color: "blue",
              },
            }}
          />
          <span className="mt-2 ml-4 text-sm w-full text-[#757989]">
            {" "}
            By checking the box, you consent to Jumio, our vendor (and its
            authorized service providers) collecting, processing, and sharing
            your personal information, which may include biometric information,
            for the purpose of verification services, fraud prevention, and
            long-term proof of inspection of your provided form of
            identification pursuant to its Privacy Policy. Jumio will store your
            biometric information for no longer than the earlier of (a) when the
            purposer collection has been satisfied or (b) 3 years following your
            last interaction with us. You agree that Jumio may share personal
            information collected from you with Renaissance; however, Renaissance will
            not receive or use any of your biometric information.
          </span>
        </p>
      </div>
      <div className="flex justify-center mt-10">
        <button
          disabled={loading || !term}
          onClick={() => {
            setLoading(true);
            handleSaveFormData(
              {
                ...response,
                step8: {
                  ...response.step8,
                  completed: true,
                },
              },
              setLoading,
              (res) => {
                setCurrentStep((prev) => prev + 1);
                setResponse(res.data.data);
              }
            );
          }}
          className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
        >
          CONTINUE
        </button>
      </div>
    </div>
  );
};

export default Review;
