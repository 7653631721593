import React from "react";
import Sidebar from "./Components/Sidebar";
import Table from "./Components/Table";

const Dashboard = () => {
  return (
    <>
      <Sidebar />
      <Table />
    </>
  );
};

export default Dashboard;
