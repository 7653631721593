import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { getCredits } from "../../../../api";

const Table = () => {
  const [response, setResponse] = useState();
  const [getloading, setGetLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setGetLoading(true);
      try {
        const res = await getCredits();
        console.log(res.data);
        setResponse(res.data.data);
      } catch (err) {
        toast.error(err.response.data.error);
      } finally {
        setGetLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div class="p-4 sm:ml-64">
      <div class="p-4">
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
              <tr>
                <th scope="col" class="px-6 py-3 text-sm">
                  Customer Name
                </th>
                <th scope="col" class="px-6 py-3 text-sm">
                  Customer Email
                </th>
                <th scope="col" class="px-6 py-3 text-sm">
                  Co-Buyer Name
                </th>
                <th scope="col" class="px-6 py-3 text-sm">
                  Co-buyer Email
                </th>
                <th scope="col" class="px-6 py-3 text-sm">
                  Detail
                </th>
              </tr>
            </thead>
            <tbody>
              {getloading ? (
                <tr className="bg-white">
                  <td className="py-4" colSpan={6}>
                    <ThreeDots
                      height="40"
                      width="80"
                      radius="9"
                      color="#000"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  </td>
                </tr>
              ) : response && response.length > 0 ? (
                response.map((credit, index) => (
                  <tr
                    key={index}
                    class="bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b"
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {credit?.creditInfo?.firstName}{" "}
                      {credit?.creditInfo?.lastName}{" "}
                    </th>
                    <td class="px-6 py-4">{credit?.creditInfo?.email}</td>
                    <td class="px-6 py-4">
                      {" "}
                      {credit?.creditInfo?.coBuyer?.firstName &&
                      credit?.creditInfo?.coBuyer?.lastName
                        ? credit?.creditInfo?.coBuyer?.firstName +
                          " " +
                          credit?.creditInfo?.coBuyer?.lastName
                        : "-"}
                    </td>
                    <td class="px-6 py-4">
                      {credit?.creditInfo?.coBuyer?.email ?? "-"}
                    </td>
                    <td class="px-6 py-4">
                      <Link
                        to={`/credit-info?id=${credit._id}&isCredit=true`}
                        class="font-medium text-blue-600"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center py-4 bg-white" colSpan={5}>
                    No orders found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
