import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
import Basics from "./Basics";
import Features from "./Features";
import Condition from "./Condition";
import { saveFormData, saveTradeId } from "../../api";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";

const GET_USED_VEHICLES = gql`
  query GetUsedVehicles($vin: String!) {
    usedvehicles(vin: $vin) {
      usedvehicles {
        model_year
        make
        model
        series
        style
        base_whole_avg
        fuel_type
        hwy_mpg
        city_mpg
        adjusted_retail_rough
        base_tradein_clean
        mileage_tradein_clean
        add_deduct_tradein_clean
        regional_tradein_clean
        adjusted_tradein_clean
        base_tradein_avg
        mileage_tradein_avg
        add_deduct_tradein_avg
        regional_tradein_avg
        adjusted_tradein_avg
        base_tradein_rough
        mileage_tradein_rough
        add_deduct_tradein_rough
        regional_tradein_rough
        adjusted_tradein_rough
      }
    }
  }
`;

const TradeInOffer = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  const [vin, setVin] = useState("");
  const [tradeInForm, setTradeInForm] = useState(false);
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    basics: null,
    features: null,
    condition: null,
  });

  const [getUsedVehicles, { loading, data: carData, error }] =
    useLazyQuery(GET_USED_VEHICLES);

  const handleBlackBook = async () => {
    getUsedVehicles({
      variables: {
        vin: vin,
      },
    });
  };

  const handleSubmit = async (values) => {
    await saveTradeId(id, {
      completed: false,
      trade_in: true,
      ...values,
      blackBookData: { ...carData.usedvehicles.usedvehicles[0], vin: vin },
    })
      .then((res) => {
        navigate(`/?id=${id}`);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  return (
    <div className="min-h-screen bg-white">
      {!tradeInForm ? (
        <div className="bg-[#0d375e] min-h-screen pt-20">
          <div className="flex justify-between items-center w-3/4 mx-auto">
            <div className="w-[calc(100%-350px)]">
              <h1 className="text-6xl font-bold text-white max-w-[600px]">
                GET A REAL OFFER IN 2 MINUTES
              </h1>
              <p className="text-xl text-white mt-4">
                Sell your car 100% online. No haggling, no headaches.
              </p>
            </div>
            <div className="w-80 mx-auto">
              <h1 className="text-[#183558] text-xl text-center font-bold mb-5 pt-8">
                Enter Vin
              </h1>
              <div>
                <TextField
                  name="mileage"
                  type="text"
                  onChange={(e) => {
                    setVin(e.target.value);
                  }}
                  value={vin}
                  fullWidth
                  label="VIN"
                  variant="outlined"
                  sx={{ background: "transparent" }}
                  inputProps={{ style: { background: "white" } }}
                />
                <div className="my-5 flex justify-between w-full">
                  <button
                    onClick={() => {
                      if (vin) {
                        setTradeInForm(true);
                        handleBlackBook(vin);
                      }
                    }}
                    className="w-full rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                  >
                    GET MY OFFER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        !loading && (
          <div className="flex flex-row-reverse md:flex-row justify-between w-[88%] ml-auto">
            {step === 1 && (
              <Basics setStep={setStep} data={data} setData={setData} />
            )}
            {step === 2 && (
              <Features setStep={setStep} data={data} setData={setData} />
            )}
            {step === 3 && (
              <Condition
                setStep={setStep}
                data={data}
                setData={setData}
                handleSubmit={handleSubmit}
              />
            )}
            <div className="md:pl-10 bg-[#e4ecf0] w-[50%] min-h-screen">
              <div className=" w-64">
                <div className="mb-6 bg-white border border-[#d9dde9] mt-8 p-6 rounded-md">
                  <img
                    src="https://vexstockimages.fastly.carvana.io/stockimages/2014_HONDA_ACCORD_SPORT%20SEDAN%204D_GRAY_stock_desktop_1920x1080.png?v=1645466828.304"
                    alt=""
                    className="w-full"
                  />
                  <h1 className="text-[#183558] font-bold">
                    {carData.usedvehicles.usedvehicles[0].model_year}{" "}
                    {carData.usedvehicles.usedvehicles[0].make}{" "}
                    {carData.usedvehicles.usedvehicles[0].model}{" "}
                    {carData.usedvehicles.usedvehicles[0]?.series}{" "}
                  </h1>
                  <p className="text-[#183558]">
                    {carData.usedvehicles.usedvehicles[0].style}{" "}
                  </p>
                  <p className="text-[#183558]">
                    <span className="font-bold">VIN</span>: {vin}
                  </p>
                </div>
                <div>
                  <div
                    className={` ${
                      step > 1 ? "text-[#183558]" : "text-[#aab1c7]"
                    }`}
                  >
                    <div className="grid grid-cols-2 text-sm my-2">
                      <p className="font-bold">Mileage</p>{" "}
                      <p>{data.basics?.mileage ?? "—"}</p>
                    </div>
                    <div className="grid grid-cols-2 text-sm my-2">
                      <p className="font-bold">Color</p>{" "}
                      <p>{data.basics?.color ?? "—"}</p>
                    </div>
                    <div className="grid grid-cols-2 text-sm my-2">
                      <p className="font-bold">Transmission</p>{" "}
                      <p>{data.basics?.transmission ?? "—"}</p>
                    </div>
                    <div className="grid grid-cols-2 text-sm my-2">
                      <p className="font-bold">Drivetrain</p>{" "}
                      <p>{data.basics?.drivetrain ?? "—"}</p>
                    </div>
                    <div className="grid grid-cols-2 text-sm my-2">
                      <p className="font-bold">Engine</p>{" "}
                      <p>{data.basics?.engine ?? "—"}</p>
                    </div>
                  </div>
                  <br />
                  <div
                    className={` ${
                      step > 2 ? "text-[#183558]" : "text-[#aab1c7]"
                    }`}
                  >
                    <div className="grid grid-cols-2  text-sm my-2">
                      <p className="font-bold">Additional Features</p>{" "}
                      <p>
                        {data.features?.wheelsAndTyres}
                        <br />
                        {data.features?.entertainment.length > 0
                          ? data.features?.entertainment.map((item) => item)
                          : ""}
                        {data.features?.wheelsAndTyres === "" &&
                        data.features?.entertainment.length === 0
                          ? "—"
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default TradeInOffer;
