import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { uploadDriverLicense } from "../../api";

const validationSchema = Yup.object({
  frontImage: Yup.mixed()
    .required("Front view of the license is required")
    .test("fileFormat", "Only image files are accepted", (value) =>
      value
        ? ["image/jpeg", "image/png", "image/gif"].includes(value.type)
        : false
    ),
  backImage: Yup.mixed()
    .required("Back view of the license is required")
    .test("fileFormat", "Only image files are accepted", (value) =>
      value
        ? ["image/jpeg", "image/png", "image/gif"].includes(value.type)
        : false
    ),
});

const UploadLicense = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const token = urlParams.get("token");
  const [uploaded, setUploaded] = useState(false);
  const [frontPreview, setFrontPreview] = useState(null);
  const [backPreview, setBackPreview] = useState(null);

  const formik = useFormik({
    initialValues: {
      frontImage: null,
      backImage: null,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const formdata = new FormData();
      formdata.append("frontImage", values.frontImage);
      formdata.append("backImage", values.backImage);
      formdata.append("orderId", id);
      formdata.append("token", token);
      await uploadDriverLicense(formdata)
        .then((res) => {
          setUploaded(true);
          setSubmitting(false);
        })
        .catch((err) => {
          toast.error(err.response.data.error);
          setSubmitting(false);
        });
    },
  });

  const handleFileChange = (event, field) => {
    const file = event.currentTarget.files[0];
    if (file) {
      formik.setFieldValue(field, file);
      const previewURL = URL.createObjectURL(file);
      if (field === "frontImage") {
        setFrontPreview(previewURL);
      } else if (field === "backImage") {
        setBackPreview(previewURL);
      }
    }
  };

  return uploaded ? (
    <div className="w-screen bg-overlay h-full flex justify-center items-center fixed top-0 right-0 z-10">
      <div className="absolute bottom-0 md:bottom-auto  bg-white md:max-w-2xl w-full max-h-svh md:max-h-[90svh] overflow-hidden z-10 md:rounded-xl flex flex-col justify-between">
        <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <div class="w-12 h-12 mt-5 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
            <svg
              aria-hidden="true"
              class="w-8 h-8 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Success</span>
          </div>
          <p class="mb-4 text-lg font-semibold text-gray-900 dark:text-white mt-5">
            Successfully uploaded driving license.
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div className="w-3/4 mx-auto">
      <h1 className="text-center font-semibold text-3xl py-10">
        Upload Driving License
      </h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-2 gap-10 mt-10">
          <div>
            <div className="relative flex items-center justify-center w-full">
              <label
                htmlFor="frontImage"
                className="relative flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
              >
                {frontPreview ? (
                  <img
                    src={frontPreview}
                    alt="Front Preview"
                    className="absolute inset-0 w-full h-full object-contain rounded-lg"
                  />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="font-semibold">Click to upload</span>
                    </p>
                    <p className="text-xs text-gray-500">
                      Add front view of driving license
                    </p>
                  </div>
                )}
                <input
                  id="frontImage"
                  name="frontImage"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(event) => handleFileChange(event, "frontImage")}
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white font-medium text-lg opacity-0 hover:opacity-100 rounded-lg">
                  Change front view of driving license
                </div>
              </label>
            </div>
            {formik.touched.frontImage && formik.errors.frontImage ? (
              <div className="text-red-500 text-sm mt-2">
                {formik.errors.frontImage}
              </div>
            ) : null}
          </div>

          <div>
            <div className="relative flex items-center justify-center w-full">
              <label
                htmlFor="backImage"
                className="relative flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
              >
                {backPreview ? (
                  <img
                    src={backPreview}
                    alt="Back Preview"
                    className="absolute inset-0 w-full h-full object-contain rounded-lg"
                  />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="font-semibold">Click to upload</span>
                    </p>
                    <p className="text-xs text-gray-500">
                      Add back view of driving license
                    </p>
                  </div>
                )}
                <input
                  id="backImage"
                  name="backImage"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(event) => handleFileChange(event, "backImage")}
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white font-medium text-lg opacity-0 hover:opacity-100 rounded-lg">
                  Change back view of driving license
                </div>
              </label>
            </div>
            {formik.touched.backImage && formik.errors.backImage ? (
              <div className="text-red-500 text-sm mt-2">
                {formik.errors.backImage}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9] mt-10  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
          >
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  );
};

export default UploadLicense;
