import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const TradeIn = ({
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [selectedTradeIn, setSelectedTradeIn] = useState(
    response.step2?.trade_in
  );
  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-3xl text-[#183558] mb-4 font-bold">Add a trade-in</h1>
      <p className="text-sm text-[#757989] mb-10">
        Share a few details about your trade-in and unlock a personalized offer.
      </p>
      <div>
        {response.step2.completed ||
        response.step2?.blackBookData?.__typename ? (
          <>
            <div
              className={`p-8 bg-white border hover:border-[#00aed9] rounded-lg mb-10 ${
                selectedTradeIn === true
                  ? "border-[#00aed9]"
                  : "border-[#d9dde9]"
              }`}
            >
              <div className="pb-5 border-b border-[#d9dde9] rounded px-6 mb-6">
                <label className="flex items-center text-[#183558] mb-2 font-bold cursor-pointer">
                  <input
                    className="mr-2 w-5 h-5 cursor-pointer"
                    type="radio"
                    checked={selectedTradeIn}
                    onClick={() => {
                      setSelectedTradeIn(true);
                    }}
                    name=""
                  />
                  <span className="mt-[1px]">
                    {response.step2?.blackBookData?.__typename
                      ? "Selected trade-in"
                      : "Get trade-in offer"}
                  </span>
                </label>
              </div>

              {response.step2?.blackBookData?.__typename ? (
                <div className="lg:flex">
                  <div>
                    <h1 className="text-[#183558] font-bold text-sm">
                      {response.step2.blackBookData.model_year}{" "}
                      {response.step2.blackBookData.make}{" "}
                      {response.step2.blackBookData.model}{" "}
                      {response.step2.blackBookData?.series}{" "}
                    </h1>
                    <p className="text-[#183558] text-sm">
                      {response.step2.blackBookData.style}{" "}
                    </p>
                    <p className="text-[#757989] text-xs">
                      {response.step2.basics?.mileage} miles
                    </p>
                    <p className="text-[#757989] text-xs">
                      <span>VIN</span>: {response.step2.blackBookData?.vin}
                    </p>
                  </div>
                  <div className="mt-10 lg:mt-0 lg:w-[calc(100%-192px)] flex flex-col gap-4 ml-4">
                    <p className="text-[#183558] font-medium text-sm flex justify-between">
                      Your Renaissance Offer:{" "}
                      <span>
                        ${response.step2.blackBookData?.base_tradein_clean}
                      </span>
                    </p>
                    <p className="bg-[#d9dde9] p-4 text-sm text-[#183558]">
                      Your trade-in value of $
                      {response.step2.blackBookData?.base_tradein_clean} will be
                      applied to your purchase, reducing your down payment and
                      the amount you need to finance
                    </p>
                  </div>
                </div>
              ) : (
                <div className="lg:flex items-center">
                  <div className="flex justify-center items-center">
                    <div className=" w-48 h-48 rounded-full">
                      <img src="/images/trade.webp" alt="" />
                    </div>
                  </div>
                  <div className="mt-10 lg:mt-0 lg:w-[calc(100%-192px)] flex flex-col gap-4 ml-4">
                    <div className="flex items-center">
                      <img
                        className="w-4 h-4 mr-1"
                        src="/icons/tick.svg"
                        alt=""
                      />
                      <p className="text-[#757989]">
                        Get a real offer in 2 minutes
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img
                        className="w-4 h-4 mr-1"
                        src="/icons/tick.svg"
                        alt=""
                      />
                      <p className="text-[#757989]">
                        Reduce or eliminate down and monthly payments
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img
                        className="w-4 h-4 mr-1"
                        src="/icons/tick.svg"
                        alt=""
                      />
                      <p className="text-[#757989]">
                        Receive instant trade-in tax savings in most states
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`p-8 bg-white border hover:border-[#00aed9] rounded-lg mb-10 ${
                selectedTradeIn === false
                  ? "border-[#00aed9]"
                  : "border-[#d9dde9]"
              }`}
            >
              <div className="rounded px-6">
                <label className="flex items-center text-[#183558] mb-2 font-bold cursor-pointer">
                  <input
                    className="mr-2 w-5 h-5 cursor-pointer"
                    type="radio"
                    checked={!selectedTradeIn}
                    onClick={() => {
                      setSelectedTradeIn(false);
                    }}
                    name=""
                  />
                  <span className="mt-[1px]">I don't have a trade-in</span>
                </label>
              </div>
            </div>
            <p
              onClick={() => {
                navigate(`/tradein-offer?id=${response._id}`);
              }}
              className="text-sm text-center text-[#00aed9] font-semibold cursor-pointer mb-8"
            >
              Get a trade-In on a different car
            </p>
            <div className="flex flex-col justify-center items-center">
              <button
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  handleSaveFormData(
                    {
                      ...response,
                      step2: {
                        ...response.step2,
                        completed:
                          selectedTradeIn &&
                          !response.step2?.blackBookData?.__typename
                            ? false
                            : true,
                        trade_in: selectedTradeIn,
                      },
                    },
                    setLoading,
                    (res) => {
                      setCurrentStep((prev) => prev + 1);
                      setResponse(res.data.data);
                      if (
                        selectedTradeIn &&
                        !response.step2?.blackBookData?.__typename
                      ) {
                        navigate(`/tradein-offer?id=${response._id}`);
                      }
                    }
                  );
                }}
                className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
              >
                CONTINUE
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="p-8 bg-white rounded-lg mb-10">
              <div className="lg:flex items-center">
                <div className="flex justify-center items-center">
                  <div className="w-48 h-48 rounded-full">
                    <img src="/images/trade.webp" alt="" />
                  </div>
                </div>
                <div className="mt-10 lg:mt-0 lg:w-[calc(100%-192px)] flex flex-col gap-4 ml-4">
                  <div className="flex items-center">
                    <img
                      className="w-4 h-4 mr-1"
                      src="/icons/tick.svg"
                      alt=""
                    />
                    <p className="text-[#757989]">
                      Get a real offer in 2 minutes
                    </p>
                  </div>
                  <div className="flex items-center">
                    <img
                      className="w-4 h-4 mr-1"
                      src="/icons/tick.svg"
                      alt=""
                    />
                    <p className="text-[#757989]">
                      Reduce or eliminate down and monthly payments
                    </p>
                  </div>
                  <div className="flex items-center">
                    <img
                      className="w-4 h-4 mr-1"
                      src="/icons/tick.svg"
                      alt=""
                    />
                    <p className="text-[#757989]">
                      Receive instant trade-in tax savings in most states
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <button
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  handleSaveFormData(
                    {
                      ...response,
                      step2: {
                        ...response.step2,
                        completed: false,
                        trade_in: true,
                      },
                    },
                    setLoading,
                    (res) => {
                      setCurrentStep((prev) => prev + 1);
                      setResponse(res.data.data);
                      navigate(`/tradein-offer?id=${response._id}`);
                    }
                  );
                }}
                className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
              >
                GET TRADE-IN OFFER
              </button>
              <button
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  handleSaveFormData(
                    {
                      ...response,
                      step2: {
                        ...response.step2,
                        completed: true,
                        trade_in: false,
                      },
                    },
                    setLoading,
                    (res) => {
                      setCurrentStep((prev) => prev + 1);
                      setResponse(res.data.data);
                    }
                  );
                }}
                className="rounded  text-[#00aed9] bg-white hover:bg-[#00aed9] hover:text-white  mt-4 font-medium min-h-14 border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
              >
                I DON'T HAVE A TRADE-IN
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TradeIn;
