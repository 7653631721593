import React, { useEffect, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Field, ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import updateInstance, { login } from "../../api";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
});

const Login = () => {
  const axiosInstance = updateInstance(localStorage.getItem("token"));
  const navigate = useNavigate();

  return (
    <section class="bg-gray-50">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto min-h-screen lg:py-0">
        <Link
          to="/"
          class="flex items-center mb-6 text-2xl font-semibold text-gray-900"
        >
          <h1>LOGO HERE</h1>
          {/* <img
            class="w-8 h-8 mr-2"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg"
            alt="logo"
          /> */}
        </Link>
        <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Sign in to your account
            </h1>
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={async (values, { resetForm, setSubmitting }) => {
                await login(values)
                  .then((res) => {
                    setSubmitting(false);
                    resetForm();
                    const authToken = res.data.token;
                    const user = res.data.data;

                    axiosInstance.defaults.headers[
                      "Authorization"
                    ] = `Bearer ${res.data.token}`;

                    localStorage.setItem("authToken", authToken);
                    localStorage.setItem("user", JSON.stringify(user));

                    if (user.type === "admin") {
                      navigate("/dashboard");
                    }
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    toast.error(err.response.data.error);
                  });
              }}
              validationSchema={ValidationSchema}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div class="space-y-4 md:space-y-6">
                    <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900">
                        Your email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 outline-blue-600"
                        placeholder="name@company.com"
                        required=""
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error text-red-600"
                      />
                    </div>
                    <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900">
                        Password
                      </label>
                      <Field
                        type="password"
                        name="password"
                        placeholder="••••••••"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 outline-blue-600"
                        required=""
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error text-red-600"
                      />
                    </div>
                    {/* <div class="flex items-center justify-between">
               <div class="flex items-start">
                 <div class="flex items-center h-5">
                   <Field
                     id="remember"
                     aria-describedby="remember"
                     type="checkbox"
                     class="w-4 h-4 border border-gray-300 rounded bg-gray-50"
                     required=""
                   />
                 </div>
                 <div class="ml-3 text-sm">
                     Remember me
                   </label>
                 </div>
               </div>
               <a
                 href="#"
                 class="text-sm font-medium text-primary-600 hover:underline"
               >
                 Forgot password?
               </a>
             </div> */}
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      class="w-full flex justify-center text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                    >
                      {isSubmitting ? (
                        <ThreeDots
                          height="20"
                          width="60"
                          radius="9"
                          color="#fff"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            display: "block",
                          }}
                          wrapperClassName="spinner"
                          visible={true}
                        />
                      ) : (
                        "Sign in"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
