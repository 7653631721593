import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";

const ValidationSchema = Yup.object().shape({
  type: Yup.string().required("Account type is required"),
  routing: Yup.number().required("Routing number is required"),
  number: Yup.number().required("Account number is required"),
  confirmNumber: Yup.number()
    .required("Confirm account number is required is required")
    .oneOf([Yup.ref("number")], "Account number must match."),
});

const CardDetails = ({
  setOpenPopup,
  response,
  setResponse,
  handleSaveFormData,
  isClose,
}) => {
  const [loading, setLoading] = useState();
  return (
    <div className="w-screen bg-[rgba(0,0,0,0.3)] h-screen flex justify-center items-center fixed top-0 right-0 z-10">
      <div className="absolute bottom-0 md:bottom-auto  bg-white md:max-w-[901px] w-full max-h-screen md:max-h-[90vh] overflow-auto z-10 rounded-t-lg md:rounded flex flex-col justify-between">
        <div
          className="flex items-center justify-between py-4 px-4"
          style={{ boxShadow: "rgba(25, 41, 61, 0.18) 0px 2px 4px -1px" }}
        >
          <div></div>
          <div className="text-[#183558] font-bold">
            EDIT ACCOUNT INFO MANUALLY
          </div>
          <div>
            {isClose && (
              <img
                onClick={() => {
                  if (!loading) {
                    setOpenPopup(false);
                  }
                }}
                className="w-4 cursor-pointer"
                src="/icons/close.svg"
                alt=""
              />
            )}
          </div>
        </div>
        <div className="pt-8 pb-10 px-12">
          <Formik
            initialValues={{
              type: "checking",
              routing: "",
              number: "",
              confirmNumber: "",
            }}
            enableReinitialize
            validationSchema={ValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setLoading(true);
              handleSaveFormData(
                {
                  ...response,
                  step7: {
                    ...response.step7,
                    ...values,
                    completed: false,
                  },
                },
                setLoading,
                (res) => {
                  setResponse(res.data.data);
                  setOpenPopup(false);
                }
              );
            }}
          >
            {({
              isSubmitting,
              errors,
              values,
              setValues,
              setFieldValue,
              handleChange,
              handleBlur,
              touched,
            }) => (
              <Form>
                <div>
                  <div className="flex items-center mb-4">
                    <label className="flex items-center text-[#183558] mb-2 cursor-pointer">
                      <input
                        className="mr-2 w-5 h-5 cursor-pointer"
                        type="radio"
                        checked={values.type === "checking"}
                        onClick={() => {
                          setValues({ ...values, type: "checking" });
                        }}
                      />
                      <span className="">Checking Account</span>
                    </label>
                    <label className="flex items-center text-[#183558] mb-2 cursor-pointer ml-6">
                      <input
                        className="mr-2 w-5 h-5 cursor-pointer"
                        type="radio"
                        checked={values.type === "saving"}
                        onClick={() => {
                          setValues({ ...values, type: "saving" });
                        }}
                      />
                      <span className="">Saving Account</span>
                    </label>
                  </div>
                  <div className="mb-4">
                    <TextField
                      type="number"
                      name="routing"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.routing}
                      fullWidth
                      error={Boolean(errors.routing)}
                      helperText={errors.routing}
                      label="BANK ROUTING NUMBER"
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                  </div>
                  <div className="mb-4">
                    <TextField
                      type="number"
                      name="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.number}
                      fullWidth
                      error={Boolean(errors.number)}
                      helperText={errors.number}
                      label="BANK ACCOUNT NUMBER"
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                  </div>
                  <div className="mb-4">
                    <TextField
                      type="number"
                      name="confirmNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmNumber}
                      fullWidth
                      error={Boolean(errors.confirmNumber)}
                      helperText={errors.confirmNumber}
                      label="CONFIRM BANK ACCOUNT NUMBER"
                      variant="outlined"
                      inputProps={{ style: { background: "white" } }}
                    />
                  </div>
                  <p className="my-6 text-xs">
                    By providing this information and clicking "Submit," I
                    understand that Renaissance will use this account information to
                    verify the availability of funds for my purchase and set up
                    my future payment transaction. I authorize my checking or
                    savings account payment information shown above ("Deposit
                    Account") to be securely stored by Renaissance until such time
                    that I place my final order and authorize payment.
                  </p>
                  <div className="flex justify-center my-4">
                    <button
                      type="submit"
                      disabled={loading}
                      className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                    >
                      SUBMIT
                    </button>
                  </div>
                  {/* <p className="text-sm text-center text-[#00aed9] font-medium cursor-pointer">
                    Connect to my bank with Plaid
                  </p> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
