import React from "react";
import Table from "./Components/Table";
import Sidebar from "../Components/Sidebar";

const Credits = () => {
  return (
    <>
      <Sidebar />
      <Table />
    </>
  );
};

export default Credits;
