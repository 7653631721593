import React, { useState } from "react";
import CardDetails from "./CardDetails";

const Payment = ({
  id,
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
}) => {
  const [openPopup, setOpenPopup] = useState(!response.step7?.type);
  const [loading, setLoading] = useState();
  return (
    <div>
      {openPopup && (
        <CardDetails
          setOpenPopup={setOpenPopup}
          response={response}
          setResponse={setResponse}
          handleSaveFormData={handleSaveFormData}
          isClose={response.step7?.type}
        />
      )}
      <div className="">
        <h1 className="text-3xl text-[#183558] mb-4 font-bold">
          Payment Details
        </h1>
        <div>
          <p className="text-sm text-[#757989]">
            We will be using the information you provide on this step to process
            these payments when you place your order. We aren't processing your
            payment yet, and we'll get your consent when we do.
          </p>
          <div className="mt-6 px-8 text-sm bg-white rounded-lg">
            <p className="py-4 flex justify-between border-b border-[#d9dde9]">
              <span>Total vehicle price</span>
              <span>${"17800"}</span>
            </p>
            <p className="py-4 flex justify-between">
              <span className="font-bold">Due at order</span>
              <span className="font-bold">${"17800"}</span>
            </p>
          </div>
          <div>
            <h1 className="text-3xl text-[#183558] mb-4 font-bold mt-6">
              Your Bank Account
            </h1>
            <div className="flex flex-col gap-4">
              {openPopup ? (
                <></>
              ) : (
                <div
                  className={`p-6 bg-white border hover:border-[#00aed9] rounded-lg ${
                    openPopup === false
                      ? "border-[#00aed9]"
                      : "border-[#d9dde9]"
                  }`}
                >
                  <div className="rounded">
                    <label className="flex text-[#183558] mb-2 font-bold cursor-pointer">
                      <input
                        className="mr-2 w-5 h-5 cursor-pointer"
                        type="radio"
                        checked={openPopup === false}
                        name=""
                      />
                      <span className="mt-[-4px]">
                        Use my saved account
                        <br />
                        <span className="font-normal text-sm">
                          Account Number XXXXXXXXX
                          {response.step7.number.slice(-3)}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              )}
              <div
                className={`p-6 bg-white border hover:border-[#00aed9] rounded-lg ${
                  openPopup ? "border-[#00aed9]" : "border-[#d9dde9]"
                }`}
              >
                <div className="rounded">
                  <label className="flex text-[#183558] mb-2 font-bold cursor-pointer">
                    <input
                      className="mr-2 w-5 h-5 cursor-pointer"
                      type="radio"
                      checked={openPopup}
                      onClick={() => {
                        setOpenPopup(true);
                      }}
                      name=""
                    />
                    <span className="mt-[-4px]">
                      Use a different account
                      <br />
                      <span className="font-normal text-sm">
                        Add a new account by connecting to your bank or entering
                        your information manually
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center my-4 mt-8">
            <button
              disabled={loading}
              onClick={() => {
                setLoading(true);
                handleSaveFormData(
                  {
                    ...response,
                    step7: {
                      ...response.step7,
                      completed: true,
                    },
                  },
                  setLoading,
                  (res) => {
                    setCurrentStep((prev) => prev + 1);
                    setResponse(res.data.data);
                    setOpenPopup(false);
                  }
                );
              }}
              className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
            >
              SAVE & CONTINUE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
