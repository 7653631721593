import React, { useState } from "react";
import { deleteSavedFormData } from "../../../api";
import { toast } from "react-toastify";

const CancelOrder = ({ id, setOpenPopup, setCancelledPopup }) => {
  const [reason, setReason] = useState("");
  const [text, setText] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const reasons = [
    " Decided not to buy a car right now",
    "Switching to another Renaissance car",
    "No longer fits my budget",
    "Delivery Date has been delayed",
    "Poor customer experience",
    "Found a car elsewhere",
    "Other",
  ];

  const handleSubmit = async () => {
    setSubmitting(true);
    await deleteSavedFormData(id, {
      reason: reason === "Other" ? text : reason,
    })
      .then((res) => {
        setSubmitting(false);
        setCancelledPopup(true);
        setOpenPopup(false);
      })
      .catch((err) => {
        setSubmitting(false);
        console.log(err);
        toast.error(err.response.data.error);
      });
  };

  return (
    <div className="w-screen bg-[rgba(0,0,0,0.3)] h-screen flex justify-center items-center fixed top-0 right-0 z-10">
      <div className="absolute bottom-0 md:bottom-auto  bg-white md:max-w-[901px] w-full max-h-screen md:max-h-[90vh] overflow-auto z-10 rounded-t-lg md:rounded flex flex-col justify-between">
        <div
          className="flex items-center justify-between py-4 px-4"
          style={{ boxShadow: "rgba(25, 41, 61, 0.18) 0px 2px 4px -1px" }}
        >
          <div></div>
          <div className="text-[#183558] font-bold">CANCEL ORDER</div>
          <div>
            <img
              onClick={() => {
                if (!submitting) {
                  setOpenPopup(false);
                }
              }}
              className="w-4 cursor-pointer"
              src="/icons/close.svg"
              alt=""
            />
          </div>
        </div>
        <div className="pt-8 pb-10 px-12">
          <div className="flex flex-col justify-between items-center w-[80%] mx-auto">
            <img className="w-14" src="/icons/warning.svg" alt="" />
            <h1 className="my-2 text-2xl font-bold text-[#183558]">
              Are you sure you want to cancel your order?
            </h1>
            <p className="text-[#757989] ">
              This vehicle will be immediately available for other buyers and
              cannot be undone.
            </p>
            <p className="text-[#757989] ">Select a reason:</p>
          </div>
          <div className="w-[80%] mx-auto mt-5">
            <div>
              {reasons.map((item, index) => (
                <label
                  key={index}
                  className="flex items-center text-[#183558] mb-2 cursor-pointer"
                >
                  <input
                    id={index}
                    className="mr-2 w-5 h-5 cursor-pointer"
                    type="radio"
                    checked={item === reason}
                    onClick={() => {
                      setReason(item);
                    }}
                    name=""
                  />
                  <span className="mt-[1px]">{item}</span>
                </label>
              ))}

              {reason === "Other" && (
                <textarea
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  value={text}
                  placeholder="Add a message here"
                  className="w-full mt-2 border border-[#d3d3d3] text-[#183558] p-2.5 rounded resize-none"
                  rows={3}
                />
              )}
            </div>
            <div className="mt-10 md:flex justify-center">
              <button
                disabled={!reason || submitting}
                onClick={handleSubmit}
                className="rounded text-[#00aed9] bg-white hover:bg-[#00aed9] hover:text-white disabled:text-white disabled:border-none disabled:bg-[#d9dde9]  mt-4 md:mt-0 md:mr-4 font-medium min-h-14 border border-[#00aed9] md:w-64"
              >
                Yes, cancel
              </button>
              <button
                disabled={submitting}
                onClick={() => {
                  setOpenPopup(false);
                }}
                className="rounded text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-64 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
              >
                No, go back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelOrder;
