export const stepsdata = {
  step1: {
    personalDetail: {
      militaryStatus: "None",
      maritalStatus: "Single",
      houseOwner: "Rent",
    },
    isDeliverySame: true,
    isMailingSame: true,
    completed: false,
  },
  step2: {
    condition: {
      mechAndElecIssues: [],
      engineIssues: [],
      exteriorIssues: [],
      ineriorIssues: [],
    },
    completed: false,
  },
  step3: {
    completed: false,
  },
  step4: {
    completed: false,
  },
  step5: {
    completed: false,
  },
  step6: {
    completed: false,
  },
  step7: {
    completed: false,
  },
  step8: {
    completed: false,
  },
  step9: {
    completed: false,
  },
};
