import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "../Common/ScrollToTop";
import ProtectedRoutes from "../Common/ProtectedRoutes";
import Login from "../Pages/Login";
import Order from "../Pages/Order";
import Dashboard from "../Pages/Dashboard";
import TradeInOffer from "../Pages/TradeInOffer/TradeInOffer";
import UploadLicense from "../Pages/UploadLicense";
import UploadCustomerDetails from "../Pages/UploadCustomerDetails";
import UploadEmploymentInfo from "../Pages/UploadEmploymentInfo";
import UploadInsuranceInfo from "../Pages/UploadInsuranceInfo";
import CreditInfo from "../Pages/Dashboard/CreditInfo";
import Credits from "../Pages/Dashboard/Credits";
import OrderDetail from "../Pages/Dashboard/OrderDetail";
import CreditApp from "../Pages/CreditApp";

const WebRoutes = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/credit-app" element={<CreditApp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Order />} />
        <Route path="/tradein-offer" element={<TradeInOffer />} />
        <Route path="" element={<UploadLicense />} />

        <Route
          path="/dashboard"
          element={<ProtectedRoutes role="admin" component={<Dashboard />} />}
        />

        <Route
          path="/credit-records"
          element={<ProtectedRoutes role="admin" component={<Credits />} />}
        />
        <Route
          path="/orders"
          element={<ProtectedRoutes role="admin" component={<OrderDetail />} />}
        />
        <Route
          path="/credit-info"
          element={<ProtectedRoutes role="admin" component={<CreditInfo />} />}
        />
        <Route path="/upload-driving-license" element={<UploadLicense />} />
        <Route
          path="/upload-customer-details"
          element={<UploadCustomerDetails />}
        />
        <Route
          path="/upload-employment-info"
          element={<UploadEmploymentInfo />}
        />
        <Route
          path="/upload-insurance-info"
          element={<UploadInsuranceInfo />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default WebRoutes;
