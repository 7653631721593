import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoutes = (props) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("authToken");

  let Component = props.component;
  useEffect(() => {
    if (!token || user?.type !== props.role) {
      navigate("/login");
    }
  }, [token]);
  return <>{token && user?.type === props.role && Component}</>;
};
export default ProtectedRoutes;
