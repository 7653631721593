import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const Basics = ({ setStep, data, setData }) => {
  const [loanLease, setLoanLease] = useState("");

  const ValidationSchema = Yup.object().shape({
    mileage: Yup.string().required("Field is required"),
    zip: Yup.string().required("Field is required"),
    color: Yup.string().required("Field is required"),
    transmission: Yup.string().required("Field is required"),
    engine: Yup.string().required("Field is required."),
    loanOrLease: Yup.string().required("Field is required."),
    sellOrTrade: Yup.string().required("Field is required."),
    loan:
      loanLease === "Loan"
        ? Yup.object().shape({
            company: Yup.string().required("Field is required"),
            balance: Yup.string().required("Field is required"),
            payment: Yup.string().required("Field is required"),
          })
        : Yup.object().shape({}),
    lease:
      loanLease == "Lease"
        ? Yup.object().shape({
            company: Yup.string().required("Field is required"),
            amount: Yup.string().required("Field is required"),
            payment: Yup.string().required("Field is required"),
          })
        : Yup.object().shape({}),
  });

  return (
    <div className="bg-white w-[calc(100%-40px-256px)]">
      <h1 className="text-[#183558] text-lg font-bold mb-5 mt-8">The Basics</h1>
      <p className="text-[#757989] text-lg mb-5">
        Great! We’ve found your vehicle. Please help us fill in a few more key
        details.
      </p>
      <div>
        <Formik
          initialValues={
            data.basics
              ? data.basics
              : {
                  mileage: "",
                  zip: "",
                  color: "",
                  transmission: "Auto, CVT w/Sport Mode",
                  drivetrain: "Front wheel drive",
                  engine: "4-Cyl, i-VTEC, 2.4 Liter",
                  loanOrLease: "",
                  sellOrTrade: "Not Sure",
                  loan: {
                    company: "",
                    balance: "",
                    payment: "",
                  },
                  lease: {
                    company: "",
                    amount: "",
                    payment: "",
                  },
                }
          }
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            setStep(2);
            setData({ ...data, basics: values });
          }}
          validationSchema={ValidationSchema}
        >
          {({
            isSubmitting,
            errors,
            values,
            setValues,
            setFieldValue,
            handleChange,
            handleBlur,
            touched,
          }) => (
            <Form>
              <div className="w-1/2 mb-4">
                <TextField
                  name="mileage"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.mileage}
                  fullWidth
                  error={Boolean(errors?.mileage)}
                  helperText={errors?.mileage}
                  label="MILEAGE"
                  variant="outlined"
                  inputProps={{ style: { background: "white" } }}
                />
              </div>
              <div className="w-1/2">
                <TextField
                  name="zip"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.zip}
                  fullWidth
                  error={Boolean(errors?.zip)}
                  helperText={errors?.zip}
                  label="ZIP CODE"
                  variant="outlined"
                  inputProps={{ style: { background: "white" } }}
                />
              </div>
              <h1 className="text-base font-semibold text-[#183558] mt-8 mb-4">
                Color
              </h1>
              <div className="w-1/2">
                <FormControl fullWidth error={Boolean(errors?.color)}>
                  <InputLabel id="color">COLOR</InputLabel>
                  <Select
                    name="color"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.color}
                    fullWidth
                    labelId="color"
                    label="COLOR"
                    variant="outlined"
                    sx={{ background: "white", textTransform: "uppercase" }}
                  >
                    <MenuItem value={"Black"}>Black</MenuItem>
                    <MenuItem value={"Gray"}>Gray</MenuItem>
                    <MenuItem value={"Silver"}>Silver</MenuItem>
                    <MenuItem value={"White"}>White</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.color}</FormHelperText>
                </FormControl>
              </div>
              <h1 className="text-base font-semibold text-[#183558] mt-8 mb-4">
                Transmission
              </h1>
              <div className="w-1/2">
                <div className="flex flex-col mt-5 w-full gap-2">
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("transmission", "Manual, 6-Spd");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.transmission == "Manual, 6-Spd"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    Manual, 6-Spd
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("transmission", "Auto, CVT w/Sport Mode");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.transmission === "Auto, CVT w/Sport Mode"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    Auto, CVT w/Sport Mode
                  </button>
                </div>
              </div>
              <h1 className="text-base font-semibold text-[#183558] mt-8 mb-2">
                Drivetrain
              </h1>
              <p className="">Front wheel drive</p>
              <h1 className="text-base font-semibold mt-8 mb-4 text-[#183558]">
                Engine
              </h1>
              <div className="w-1/2">
                <div className="flex flex-col gap-2 mt-5 w-full">
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("engine", "4-Cyl, i-VTEC, 2.4 Liter");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.engine == "4-Cyl, i-VTEC, 2.4 Liter"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    4-Cyl, i-VTEC, 2.4 Liter
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("engine", "4-Cyl, PZEV, 2.4 Liter");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.engine === "4-Cyl, PZEV, 2.4 Liter"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    4-Cyl, PZEV, 2.4 Liter
                  </button>
                </div>
              </div>
              <h1 className="text-base font-semibold  mt-8 mb-4 text-[#183558]">
                Sell or Trade In
              </h1>
              <p>
                Sell us your car or trade it in with your purchase. We’ll guide
                you through it.
              </p>
              <div className="w-[95%]">
                <div className="flex mt-5 w-full gap-2">
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("sellOrTrade", "Sell");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.sellOrTrade == "Sell"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    Sell
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("sellOrTrade", "Trade In");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.sellOrTrade === "Trade In"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    Trade In
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("sellOrTrade", "Not Sure");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.sellOrTrade === "Not Sure"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    Not Sure
                  </button>
                </div>
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {errors?.sellOrTrade}
                </FormHelperText>
              </div>
              <h1 className="text-base font-semibold  mt-8 mb-4 text-[#183558]">
                Loan or Lease
              </h1>
              <p>Do you currently have a loan or lease on your vehicle?</p>
              <div className="w-[95%]">
                <div className="flex mt-5 w-full gap-2">
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("loanOrLease", "Loan");
                      setLoanLease("Loan");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.loanOrLease == "Loan"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    Loan
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("loanOrLease", "Lease");
                      setLoanLease("Lease");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.loanOrLease === "Lease"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    Lease
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("loanOrLease", "Neither");
                      setLoanLease("Neither");
                    }}
                    className={`w-full rounded  text-[#183558]  bg-[#fff]   font-medium min-h-10  border border-[#d9dde9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] ${
                      values.loanOrLease === "Neither"
                        ? "!bg-[#00619e] !text-white "
                        : ""
                    }`}
                  >
                    Neither
                  </button>
                </div>
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {errors?.loanOrLease}
                </FormHelperText>
                {values.loanOrLease === "Loan" && (
                  <div className="flex flex-col gap-6 mt-6">
                    <div className="w-1/2">
                      <FormControl
                        fullWidth
                        error={Boolean(errors?.loan?.company)}
                      >
                        <InputLabel id="loan?.company">LOAN COMPANY</InputLabel>
                        <Select
                          name="loan.company"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.loan?.company}
                          fullWidth
                          labelId="loan?.company"
                          label="LOAN COMPANY"
                          variant="outlined"
                          sx={{
                            background: "white",
                            textTransform: "uppercase",
                          }}
                        >
                          <MenuItem value={"Black"}>Black</MenuItem>
                          <MenuItem value={"Gray"}>Gray</MenuItem>
                          <MenuItem value={"Silver"}>Silver</MenuItem>
                          <MenuItem value={"White"}>White</MenuItem>
                        </Select>
                        <FormHelperText>{errors?.loan?.company}</FormHelperText>
                      </FormControl>
                    </div>
                    <div className="w-1/2">
                      <TextField
                        name="loan.balance"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.loan?.balance}
                        fullWidth
                        error={Boolean(errors?.loan?.balance)}
                        helperText={errors?.loan?.balance}
                        label="REMAINING BALANCE"
                        variant="outlined"
                        inputProps={{ style: { background: "white" } }}
                      />
                    </div>
                    <div className="w-1/2">
                      <TextField
                        name="loan.payment"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.loan?.payment}
                        fullWidth
                        error={Boolean(errors?.loan?.payment)}
                        helperText={errors?.loan?.payment}
                        label="MONTHLY PAYMENT"
                        variant="outlined"
                        inputProps={{ style: { background: "white" } }}
                      />
                    </div>
                  </div>
                )}
                {values.loanOrLease === "Lease" && (
                  <div className="flex flex-col gap-6 mt-6">
                    <div className="w-1/2">
                      <FormControl
                        fullWidth
                        error={Boolean(errors?.lease?.company)}
                      >
                        <InputLabel id="color">LEASE COMPANY</InputLabel>
                        <Select
                          name="lease.company"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.lease?.company}
                          fullWidth
                          labelId="lease?.company"
                          label="LEASE COMPANY"
                          variant="outlined"
                          sx={{
                            background: "white",
                            textTransform: "uppercase",
                          }}
                        >
                          <MenuItem value={"Black"}>Black</MenuItem>
                          <MenuItem value={"Gray"}>Gray</MenuItem>
                          <MenuItem value={"Silver"}>Silver</MenuItem>
                          <MenuItem value={"White"}>White</MenuItem>
                        </Select>
                        <FormHelperText>
                          {errors?.lease?.company}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div className="w-1/2">
                      <TextField
                        name="lease.amount"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.lease?.amount}
                        fullWidth
                        error={Boolean(errors?.lease?.amount)}
                        helperText={errors?.lease?.amount}
                        label="LEASE PAYOFF AMOUNT"
                        variant="outlined"
                        inputProps={{ style: { background: "white" } }}
                      />
                    </div>
                    <div className="w-1/2">
                      <TextField
                        name="lease.payment"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.lease?.payment}
                        fullWidth
                        error={Boolean(errors?.lease?.payment)}
                        helperText={errors?.lease?.payment}
                        label="MONTHLY PAYMENT"
                        variant="outlined"
                        inputProps={{ style: { background: "white" } }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="my-10 flex justify-between gap-4 w-[95%]">
                <button
                  type="submit"
                  className="w-1/2 rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                >
                  NEXT
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Basics;
