import React, { useEffect, useState } from "react";
import CreditApp from "../../Finance";
import {
  getSavedCreditData,
  getSavedFormData,
  saveCreditData,
  saveFormData,
} from "../../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import { ThreeDots } from "react-loader-spinner";

const CreditInfo = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const isCredit = urlParams.get("isCredit");

  const [response, setResponse] = useState();
  const [getloading, setGetLoading] = useState(true);

  const handleSaveFormData = async (values, setIsLoading, onSuccess) => {
    if (!isCredit) {
      await saveFormData(values)
        .then((res) => {
          setIsLoading(false);
          toast.success("Info Updated Successfully");
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.error);
        });
    } else {
      await saveCreditData({ creditInfo: values }, response._id)
        .then((res) => {
          setIsLoading(false);
          toast.success("Info Updated Successfully");
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.error);
        });
    }
  };

  useEffect(() => {
    const fetchData = async (id) => {
      setGetLoading(true);
      try {
        if (!isCredit) {
          const res = await getSavedFormData(id);
          setResponse(res.data.data);
        } else {
          const res = await getSavedCreditData(id);
          setResponse(res.data.data);
        }
      } catch (err) {
        toast.error(err.response.data.error);
      } finally {
        setGetLoading(false);
      }
    };

    fetchData(id);
  }, []);

  return (
    <div class="p-4 sm:ml-64 pt-5">
      <Sidebar />
      {getloading ? (
        <div className="min-h-screen flex justify-center items-center">
          <div>
            <ThreeDots
              height="40"
              width="80"
              radius="9"
              color="#000"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
              }}
              wrapperClassName="spinner"
              visible={true}
            />
          </div>
        </div>
      ) : (
        <div className="py-8 px-5 md:px-10 lg:px-[5%] box-border bg-[white] max-w-4xl mx-auto rounded-lg">
          <p
            className="text-left cursor-pointer mb-3 border-b inline-block border-black"
            onClick={() => {
              navigate(-1);
            }}
          >
            <span className="font-bold text-xl">&#x2190; </span> Back
          </p>
          <CreditApp
            response={response.creditInfo}
            setResponse={setResponse}
            setCurrentStep={() => {}}
            handleSaveFormData={handleSaveFormData}
            coBuyer={
              isCredit
                ? response?.creditInfo?.coBuyer
                : response.step3?.carvanaFinance?.creditInfo?.coBuyer
            }
            isCredit={isCredit}
          />
        </div>
      )}
    </div>
  );
};

export default CreditInfo;
