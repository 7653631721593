import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { States, Suffix } from "../../../constants";
import { useNavigate } from "react-router-dom";

const CarvanaFinance = ({
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
  setShowCarvanaForm,
  setSelectedFinance,
  setEditing,
  setShowCreditAPp,
}) => {
  const [cosigner, setCosigner] = useState(
    response.step3?.carvanaFinance?.cosigner
  );
  const [isSameAddress, setisSameAddress] = useState(
    response.step3?.carvanaFinance?.isSameAddress
  );

  const ValidationSchema = Yup.object().shape({
    birdthdate: Yup.string().required("Birthdate is required"),
    income: Yup.string().required("Income is required"),
    status: Yup.string().required("Status is required"),
    agree: Yup.string().required("This selection is required."),
    cosigner: Yup.string().required("Please select an option."),
    cosignerDetail:
      cosigner === false
        ? Yup.object()
        : Yup.object().shape({
            firstName: Yup.string().required("First name is required"),
            lastName: Yup.string().required("Last name is required"),
            middleName: Yup.string(),
            birthdate: Yup.string().required("Birthdate is required"),
            suffix: Yup.string(),
            address: Yup.object().shape({
              line1: Yup.string().required("Address line 1 is required"),
              line2: Yup.string(),
              state: Yup.string().required("State is required"),
              zipCode: Yup.string().required("Zip code is required"),
              city: Yup.string().required("City is required"),
            }),
          }),
  });

  return (
    <div className="max-w-2xl mx-auto">
      <div className="py-8 px-5 md:px-10 lg:px-[5%] box-border bg-[white] max-w-4xl mx-auto rounded-lg mt-10">
        <div className="mx-auto">
          <h1 className="text-xl text-[#183558] mb-4 font-bold">
            Credit Application <span className="text-sm">(4 mins)</span>
          </h1>
          <div>
            <p className="text-sm text-[#757989] mb-10">
              Please select your application type to fill in the information
              below for us to process your application
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 px-[5%]">
          <div
            className="border border-[#DCDDDF] cursor-pointer flex flex-col justify-between"
            onClick={() => {
              setShowCarvanaForm(false);
              setShowCreditAPp(1);
            }}
          >
            <img src="/images/individual.jfif" />
            <button className="w-full bg-[#EAF1FB] text-[#171717] h-[3.5rem] font-medium">
              Individual applicant
            </button>
          </div>
          <div
            className="border border-[#DCDDDF] cursor-pointer flex flex-col justify-between"
            onClick={() => {
              setShowCarvanaForm(false);
              setShowCreditAPp(2);
            }}
          >
            <img src="/images/co-buyer.jfif" />
            <button className="w-full bg-[#EAF1FB] text-[#171717] h-[3.5rem] font-medium">
              Include co-buyer
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-10">
        <button
          type="button"
          onClick={() => {
            setResponse({
              ...response,
              step3: {
                ...response.step3,
                carvanaFinance: {},
                completed: false,
                paymentMethod: "",
              },
            });
            setSelectedFinance("");
            setShowCarvanaForm(false);
          }}
          className="rounded  text-[#00aed9] bg-transparent hover:bg-[#00aed9] hover:text-white font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
        >
          CHANGE PAYMENT OPTION
        </button>
      </div>
    </div>
  );
};

export default CarvanaFinance;
