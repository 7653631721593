import React, { useState } from "react";

const Protection = ({
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
}) => {
  const [loading, setLoading] = useState();
  const [selectedProtection, setSelectedProtection] = useState(
    response.step5?.protection?.type || "PLUS"
  );
  const [selectedMonths, setSelectedMonths] = useState(
    response.step5?.protection?.months || "24"
  );

  return (
    <div className="">
      <h1 className="text-3xl text-[#183558] mb-4 font-bold">
        Protect your vehicle with RenaissanceCare®
      </h1>
      <div>
        <p className="text-sm text-[#757989]">
          RenaissanceCare® provides peace of mind and can save you thousands of
          dollars in costly repairs.
        </p>
        <p className="font-bold text-[#183558] text-sm pb-6 mb-4  border-b border-[#d9dde9]">
          All options include $0 deductible at preferred locations nationwide
          and roadside assistance.
        </p>
        <div className="grid lg:grid-cols-3 gap-4">
          <div
            className={`p-5 pt-0 bg-white border rounded-md  ${
              selectedProtection === "ESSENTIAL"
                ? "border-[#00aed9]"
                : "border-[#d9dde9]"
            }`}
          >
            <div className="flex justify-between items-center pt-6 pb-5 border-b border-[#d9dde9] rounded mb-6">
              <label className="flex text-[#183558] mb-2 font-bold cursor-pointer">
                <input
                  className="mr-2 mt-1 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={selectedProtection === "ESSENTIAL"}
                  onClick={() => {
                    setSelectedProtection("ESSENTIAL");
                    setSelectedMonths("24");
                  }}
                  name=""
                />
                <div>
                  <span className="mt-[1px]">ESSENTIAL</span>
                </div>
              </label>
              <h1 className="text-xl  text-[#757989] font-bold ">+$1,850</h1>
            </div>
            <div className="mb-5 rounded-md">
              <p className="text-center font-semibold text-[#183558]">
                24 months or +24,000 miles
              </p>
              <p className="text-center text-sm mt-1 text-[#183558]">
                Basic Mechanical Coverage
              </p>
            </div>
            <div className="border-t border-[#d9dde9]">
              <div className="mt-5">
                <div className="flex items-start">
                  <img className="w-4 h-4 mr-1" src="/icons/tick.svg" alt="" />
                  <p className="text-sm text-[#757989]">
                    Powertrain components, such as the engine and transmission
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`p-5 pt-0 bg-white border rounded-md  ${
              selectedProtection === "PLUS"
                ? "border-[#00aed9]"
                : "border-[#d9dde9]"
            }`}
          >
            <div className="flex justify-between items-center pt-6 pb-5 border-b border-[#d9dde9] rounded mb-6">
              <label className="flex text-[#183558] mb-2 font-bold cursor-pointer">
                <input
                  className="mr-2 mt-1 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={selectedProtection === "PLUS"}
                  onClick={() => {
                    setSelectedProtection("PLUS");
                    setSelectedMonths("36");
                  }}
                  name=""
                />
                <div>
                  <span className="mt-[1px]">PLUS</span>
                </div>
              </label>
              <h1 className="text-xl  text-[#757989] font-bold ">+2,900</h1>
            </div>
            <div className="mb-5 rounded-md">
              <p className="text-center font-semibold text-[#183558]">
                36 months or +36,000 miles
              </p>
              <p className="text-center text-sm mt-1 text-[#183558]">
                Basic Mechanical Coverage
              </p>
            </div>
            <div className="border-t border-[#d9dde9]">
              <div className="mt-5">
                <div className="flex items-start">
                  <img className="w-4 h-4 mr-1" src="/icons/tick.svg" alt="" />
                  <p className="text-sm text-[#757989]">
                    Powertrain components, such as the engine and transmission
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`p-5 pt-0 bg-white border rounded-md  ${
              selectedProtection === "PREMIER"
                ? "border-[#00aed9]"
                : "border-[#d9dde9]"
            }`}
          >
            <div className="flex justify-between items-center pt-6 pb-5 border-b border-[#d9dde9] rounded mb-6">
              <label className="flex text-[#183558] mb-2 font-bold cursor-pointer">
                <input
                  className="mr-2 mt-1 w-5 h-5 cursor-pointer"
                  type="radio"
                  checked={selectedProtection === "PREMIER"}
                  onClick={() => {
                    setSelectedProtection("PREMIER");
                    setSelectedMonths("36");
                  }}
                  name=""
                />
                <div>
                  <span className="mt-[1px]">PREMIER</span>
                </div>
              </label>
              <h1 className="text-xl  text-[#757989] font-bold ">+$3,700</h1>
            </div>
            <div className="mb-5 rounded-md">
              <p className="text-center font-semibold text-[#183558]">
                36 months or +36,000 miles
              </p>
              <p className="text-center text-sm mt-1 text-[#183558]">
                Comprehensive Coverage
              </p>
            </div>
            <div className="border-t border-[#d9dde9]">
              <div className="mt-5">
                <div className="flex items-start">
                  <img className="w-4 h-4 mr-1" src="/icons/tick.svg" alt="" />
                  <p className="text-sm text-[#757989]">
                    Front-to-back coverage for mechanical and electrical
                    components
                  </p>
                </div>
              </div>
              <div className="mt-5">
                <div className="flex items-start">
                  <img className="w-4 h-4 mr-1" src="/icons/tick.svg" alt="" />
                  <p className="text-sm text-[#757989]">
                    Air conditioning, heating, power steering, fuel system and
                    more
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center text-[#183558] my-5">
          Not sure what to pick?
          <a
            href="https://assets.fastly.carvana.io/protection-products/CarvanaCareBrochureFeb2024.pdf"
            target="_blank"
            className="text-center font-semibold text-[#00aed9] cursor-pointer ml-1"
          >
            Compare Coverage
          </a>
        </p>
        <div className="flex justify-center border-b border-[#d9dde9]">
          <button
            type="submit"
            disabled={loading}
            onClick={() => {
              setLoading(true);
              handleSaveFormData(
                {
                  ...response,
                  step5: {
                    ...response.step4,
                    completed: true,
                    protection: {
                      isProtected: true,
                      type: selectedProtection,
                      months: selectedMonths,
                    },
                  },
                },
                setLoading,
                (res) => {
                  setCurrentStep((prev) => prev + 1);
                  setResponse(res.data.data);
                }
              );
            }}
            className="rounded mb-8 text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
          >
            SAVE & CONTINUE
          </button>
        </div>
        <div className="flex justify-center">
          <button
            className="text-center text-sm font-semibold mt-10 mb-1 text-[#00aed9] cursor-pointer"
            disabled={loading}
            onClick={() => {
              setLoading(true);
              handleSaveFormData(
                {
                  ...response,
                  step5: {
                    ...response.step4,
                    completed: true,
                    protection: {
                      isProtected: false,
                      selectedMonths: "",
                      type: "",
                    },
                  },
                },
                setLoading,
                (res) => {
                  setCurrentStep((prev) => prev + 1);
                  setResponse(res.data.data);
                }
              );
            }}
          >
            I choose to decline coverage and continue
          </button>
        </div>
        <p className="text-center text-[#757989] text-xs">
          You cannot purchase RenaissanceCare® once you have purchased this vehicle
        </p>
      </div>
    </div>
  );
};

export default Protection;
