import Routes from "./Routes";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const httpLink = createHttpLink({
  uri: "https://service.blackbookcloud.com/UsedCarWS/UsedCarWS/GraphQL",
});

const authLink = setContext((_, { headers }) => {
  const username = process.env.REACT_APP_CARVANA_USERNAME; // Replace with your actual username
  const password = process.env.REACT_APP_CARVANA_PASSWORD; // Replace with your actual password
  const token = btoa(`${username}:${password}`);
  return {
    headers: {
      ...headers,
      authorization: `Basic ${token}`,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <div className="bg-[#e4ecf0] min-h-screen">
      <div className="max-w-[1800px] mx-auto">
        <ApolloProvider client={client}>
          <Routes /> <ToastContainer />
        </ApolloProvider>
      </div>
    </div>
  );
}

export default App;
