import { Checkbox, InputLabel } from "@mui/material";
import React, { useEffect } from "react";

const ReviewSubmit = ({ fields, isCoBuyer, setIsCoBuyer, setStep }) => {
  const {
    errors,
    values,
    setValues,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
  } = fields;

  const isError = (field) => {
    return isCoBuyer ? errors[field] || errors.coBuyer?.[field] : errors[field];
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <div>
      <div className="mb-5 pb-5 border-b border-gray-300">
        <div
          className={`grid grid-cols-3 font-medium text-sm py-1 px-5 bg-gray-300`}
        >
          <div className="flex items-center">
            {!isCoBuyer && (
              <p
                className="text-xs underline cursor-pointer font-normal"
                onClick={() => {
                  setIsCoBuyer(true);
                }}
              >
                Add a Co-Buyer
              </p>
            )}
          </div>
          {!isCoBuyer && <div></div>}
          <div>Buyer Details</div>
          {isCoBuyer && (
            <div className="flex items-center">
              Co-buyer Details{" "}
              <p
                className="text-xs underline cursor-pointer ml-2 font-normal"
                onClick={() => {
                  setIsCoBuyer(false);
                }}
              >
                Remove
              </p>
            </div>
          )}
        </div>
        <div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium mt-5 mb-6">
              Personal Information
              <span
                className="ml-2 text-sm underline cursor-pointer"
                onClick={() => {
                  setStep(1);
                }}
              >
                Edit
              </span>
            </p>
          </div>
          <div className="grid grid-cols-3 text-sm">
            <div className="text-gray-400">
              <p
                className={`py-1 ${
                  isError("firstName") || isError("lastName")
                    ? "!text-[red]"
                    : ""
                }`}
              >
                Full Name
              </p>
              <p className={`py-1 ${isError("phone") ? "!text-[red]" : ""}`}>
                Phone Number
              </p>
              <p className={`py-1 ${isError("email") ? "!text-[red]" : ""}`}>
                Email
              </p>
              <p className={`py-1 ${isError("ssn") ? "!text-[red]" : ""}`}>
                Social Security Number
              </p>
              <p className={`py-1 ${isError("dob") ? "!text-[red]" : ""}`}>
                Date of Birth
              </p>
            </div>
            {!isCoBuyer && <div></div>}
            <div>
              <p className="py-1">
                {values.firstName ? values.firstName : "-"}{" "}
                {values.lastName ? values.lastName : "-"}
              </p>
              <p className="py-1">{values.phone ? values.phone : "-"}</p>
              <p className="py-1">{values.email ? values.email : "-"}</p>
              <p className="py-1">{values.ssn ? values.ssn : "-"}</p>
              <p className="py-1">{values.dob ? values.dob : "-"}</p>
            </div>
            {isCoBuyer && (
              <div>
                <p className="py-1">
                  {values.coBuyer?.firstName ? values.coBuyer?.firstName : "-"}{" "}
                  {values.coBuyer?.lastName ? values.coBuyer?.lastName : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.phone ? values.coBuyer?.phone : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.email ? values.coBuyer?.email : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.ssn ? values.coBuyer?.ssn : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.dob ? values.coBuyer?.dob : "-"}
                </p>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium mt-5 mb-6">
              Residential Information
              <span
                className="ml-2 text-sm underline cursor-pointer"
                onClick={() => {
                  setStep(2);
                }}
              >
                Edit
              </span>
            </p>
          </div>
          <div className="grid grid-cols-3 text-sm">
            <div className="text-gray-400">
              <p className={`py-1 ${isError("address") ? "!text-[red]" : ""}`}>
                Street Address
              </p>
              <p
                className={`py-1 ${isError("appartment") ? "!text-[red]" : ""}`}
              >
                Apartment/Unit
              </p>
              <p className={`py-1 ${isError("city") ? "!text-[red]" : ""}`}>
                City
              </p>
              <p className={`py-1 ${isError("state") ? "!text-[red]" : ""}`}>
                State
              </p>
              <p className={`py-1 ${isError("zip") ? "!text-[red]" : ""}`}>
                ZIP Code
              </p>
              <p
                className={`py-1 ${
                  isError("residenceType") ? "!text-[red]" : ""
                }`}
              >
                Residence Type
              </p>
              <p
                className={`py-1 ${
                  isError("monthlyPayment") ? "!text-[red]" : ""
                }`}
              >
                Mortgage Payment
              </p>
              <p
                className={`py-1 ${
                  isError("timeAtAddress") ? "!text-[red]" : ""
                }`}
              >
                Time at Address
              </p>
              <p className={`py-1 ${isError("years") ? "!text-[red]" : ""}`}>
                Years
              </p>
              <p className={`py-1 ${isError("months") ? "!text-[red]" : ""}`}>
                Months
              </p>
            </div>
            {!isCoBuyer && <div></div>}
            <div>
              <p className="py-1">{values.address ? values.address : "-"}</p>
              <p className="py-1">
                {values.appartment ? values.appartment : "-"}
              </p>
              <p className="py-1">{values.city ? values.city : "-"}</p>
              <p className="py-1">{values.state ? values.state : "-"}</p>
              <p className="py-1">{values.zip ? values.zip : "-"}</p>
              <p className="py-1">
                {values.residenceType ? values.residenceType : "-"}
              </p>
              <p className="py-1">
                {values.monthlyPayment ? values.monthlyPayment : "-"}
              </p>
              <p className="py-1">
                {values.timeAtAddress ? values.timeAtAddress : "-"}
              </p>
              <p className="py-1">{values.years ? values.years : "-"}</p>
              <p className="py-1">{values.months ? values.months : "-"}</p>
            </div>
            {isCoBuyer && (
              <div>
                <p className="py-1">
                  {values.coBuyer?.address ? values.coBuyer?.address : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.appartment
                    ? values.coBuyer?.appartment
                    : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.city ? values.coBuyer?.city : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.state ? values.coBuyer?.state : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.zip ? values.coBuyer?.zip : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.residenceType
                    ? values.coBuyer?.residenceType
                    : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.monthlyPayment
                    ? values.coBuyer?.monthlyPayment
                    : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.timeAtAddress
                    ? values.coBuyer?.timeAtAddress
                    : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.years ? values.coBuyer?.years : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.months ? values.coBuyer?.months : "-"}
                </p>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium mt-5 mb-6">
              Employment Information
              <span
                className="ml-2 text-sm underline cursor-pointer"
                onClick={() => {
                  setStep(3);
                }}
              >
                Edit
              </span>
            </p>
          </div>
          <div className="grid grid-cols-3 text-sm">
            <div className="text-gray-400">
              <p
                className={`py-1 ${
                  isError("employmentStatus") ? "!text-[red]" : ""
                }`}
              >
                Employment Status
              </p>
              <p className={`py-1 ${isError("jobTitle") ? "!text-[red]" : ""}`}>
                Job Title
              </p>
              <p
                className={`py-1 ${
                  isError("employerName") ? "!text-[red]" : ""
                }`}
              >
                Employer Name
              </p>
              <p
                className={`py-1 ${
                  isError("employerPhone") ? "!text-[red]" : ""
                }`}
              >
                Employer Phone Number
              </p>
              <p
                className={`py-1 ${
                  isError("employmentDuration") ? "!text-[red]" : ""
                }`}
              >
                Length of Time{" "}
              </p>
              <p
                className={`py-1 ${
                  isError("employmentyears") ? "!text-[red]" : ""
                }`}
              >
                Years
              </p>
              <p
                className={`py-1 ${
                  isError("employmentMonths") ? "!text-[red]" : ""
                }`}
              >
                Months
              </p>
            </div>
            {!isCoBuyer && <div></div>}
            <div>
              <p className="py-1">
                {values.employmentStatus ? values.employmentStatus : "-"}
              </p>
              <p className="py-1">{values.jobTitle ? values.jobTitle : "-"}</p>
              <p className="py-1">
                {values.employerName ? values.employerName : "-"}
              </p>
              <p className="py-1">
                {values.employerPhone ? values.employerPhone : "-"}
              </p>
              <p className="py-1">
                {values.employmentDuration ? values.employmentDuration : "-"}
              </p>
              <p className="py-1">
                {values.employmentyears ? values.employmentyears : "-"}
              </p>
              <p className="py-1">
                {values.employmentMonths ? values.employmentMonths : "-"}
              </p>
            </div>
            {isCoBuyer && (
              <div>
                <p className="py-1">
                  {values.coBuyer?.employmentStatus
                    ? values.coBuyer?.employmentStatus
                    : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.jobTitle ? values.coBuyer?.jobTitle : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.employerName
                    ? values.coBuyer?.employerName
                    : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.employerPhone
                    ? values.coBuyer?.employerPhone
                    : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.employmentDuration
                    ? values.coBuyer?.employmentDuration
                    : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.employmentyears
                    ? values.coBuyer?.employmentyears
                    : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.employmentMonths
                    ? values.coBuyer?.employmentMonths
                    : "-"}
                </p>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium mt-5 mb-6">
              Income Information
              <span
                className="ml-2 text-sm underline cursor-pointer"
                onClick={() => {
                  setStep(4);
                }}
              >
                Edit
              </span>
            </p>
          </div>
          <div className="grid grid-cols-3 text-sm">
            <div className="text-gray-400">
              <p
                className={`py-1 ${
                  isError("incomeFrequency") ? "!text-[red]" : ""
                }`}
              >
                How are you paid?
              </p>
              <p
                className={`py-1 ${
                  isError("grossIncome") ? "!text-[red]" : ""
                }`}
              >
                What is your gross income?
              </p>
            </div>
            {!isCoBuyer && <div></div>}
            <div>
              <p className="py-1">
                {values.incomeFrequency ? values.incomeFrequency : "-"}
              </p>
              <p className="py-1">
                {values.grossIncome ? values.grossIncome : "-"}
              </p>
            </div>
            {isCoBuyer && (
              <div>
                <p className="py-1">
                  {values.coBuyer?.incomeFrequency
                    ? values.coBuyer?.incomeFrequency
                    : "-"}
                </p>
                <p className="py-1">
                  {values.coBuyer?.grossIncome
                    ? values.coBuyer?.grossIncome
                    : "-"}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-start -ml-2">
        <Checkbox
          checked={values.toc1}
          inputProps={{ "aria-label": "Checkbox demo" }}
          onChange={(e) => {
            if (e.target.checked) {
              setFieldValue("toc1", true);
            } else {
              setFieldValue("toc1", false);
            }
          }}
          id="toc1"
          sx={{
            marginBottom: "6px",
            color: "blue",
            "&.Mui-checked": {
              color: "blue",
            },
          }}
        />
        <label htmlFor="toc1" className="cursor-pointer text-xs mt-2">
          I have read and agree to the terms of the dealership’s Privacy Policy.
          By providing my contact information anywhere on this website, I
          consent to be contacted by the dealership about offers and product
          information.
        </label>
      </div>
      {errors.toc1 && (
        <p className="ml-8 text-[#d32f2f] text-[0.75rem]">{errors.toc1}</p>
      )}
      <div className="flex items-start -ml-2">
        <Checkbox
          checked={values.toc2}
          inputProps={{ "aria-label": "Checkbox demo" }}
          onChange={(e) => {
            if (e.target.checked) {
              setFieldValue("toc2", true);
            } else {
              setFieldValue("toc2", false);
            }
          }}
          id="toc2"
          sx={{
            marginBottom: "6px",
            color: "blue",
            "&.Mui-checked": {
              color: "blue",
            },
          }}
        />
        <label htmlFor="toc2" className="cursor-pointer text-xs mt-2">
          I understand and agree that I am applying for credit by providing
          information and submit this credit application (“Application”). I
          authorize the dealership, its affiliates, agents, assignees, service
          providers and any bank, finance company or other institution
          (collectively “Receiving Party”) to investigate my credit and
          employment history, obtain credit reports, verify my employment and
          income and release information about my credit experience to the
          extent permitted by law. I understand that a Receiving Party may rely
          on the information in this credit Application in making a decision
          regarding whether or not to grant credit. In accordance with the Fair
          Credit Reporting Act, I further authorize each Receiving Party to
          forward my Application and all related information to other creditors
          for evaluation as a method of effectuating my request for credit. I
          acknowledge each Receiving Party may keep this Application and any
          other information provided whether or not the Application is approved.
          Please click here for any additional state-specific disclosures that
          may apply to your Application.
        </label>
      </div>
      {errors.toc2 && (
        <p className="ml-8 text-[#d32f2f] text-[0.75rem]">{errors.toc2}</p>
      )}
      <div className="flex items-start -ml-2">
        <Checkbox
          checked={values.toc3}
          inputProps={{ "aria-label": "Checkbox demo" }}
          onChange={(e) => {
            if (e.target.checked) {
              setFieldValue("toc3", true);
            } else {
              setFieldValue("toc3", false);
            }
          }}
          id="toc3"
          sx={{
            marginBottom: "6px",
            color: "blue",
            "&.Mui-checked": {
              color: "blue",
            },
          }}
        />
        <label htmlFor="toc3" className="cursor-pointer text-xs mt-2">
          I acknowledge and agree that each Receiving Party may monitor and
          record telephone calls regarding my account for quality assurance,
          compliance, training, or similar purposes. I also expressly consent
          and agree to each Receiving Party using written, electronic or verbal
          means to contact me as the law allows. This consent includes, but is
          not limited to, contact by manual calling methods, prerecorded or
          artificial voice messages, text messages, emails and/or automatic
          telephone dialing systems. I agree each Receiving Party may do so
          using any e-mail address or any telephone number I provide, now or in
          the future, including a number for a cellular phone or other wireless
          device, regardless of whether I incur charges as a result.
        </label>
      </div>
      {errors.toc3 && (
        <p className="ml-8 text-[#d32f2f] text-[0.75rem]">{errors.toc3}</p>
      )}
      <div className="flex items-start -ml-2">
        <Checkbox
          checked={values.toc4}
          inputProps={{ "aria-label": "Checkbox demo" }}
          onChange={(e) => {
            if (e.target.checked) {
              setFieldValue("toc4", true);
            } else {
              setFieldValue("toc4", false);
            }
          }}
          id="toc4"
          sx={{
            marginBottom: "6px",
            color: "blue",
            "&.Mui-checked": {
              color: "blue",
            },
          }}
        />
        <label htmlFor="toc4" className="cursor-pointer text-xs mt-2">
          I acknowledge and agree that this Application is an electronic credit
          application submitted by me using electronic means. By clicking
          “Submit” I indicate my intent to sign this Application electronically
          (e-signature), pursuant to the terms of the dealership’s Electronic
          Signature Disclosure.
        </label>
      </div>
      {errors.toc4 && (
        <p className="ml-8 text-[#d32f2f] text-[0.75rem]">{errors.toc4}</p>
      )}
      <div className="flex items-start -ml-2">
        <Checkbox
          checked={values.toc5}
          inputProps={{ "aria-label": "Checkbox demo" }}
          onChange={(e) => {
            if (e.target.checked) {
              setFieldValue("toc5", true);
            } else {
              setFieldValue("toc5", false);
            }
          }}
          id="toc5"
          sx={{
            marginBottom: "6px",
            color: "blue",
            "&.Mui-checked": {
              color: "blue",
            },
          }}
        />
        <label htmlFor="toc5" className="cursor-pointer text-xs mt-2">
          I certify that the information in the Application and in any other
          application submitted is true and complete. I understand that false
          statements may subject me to criminal penalties.
        </label>
      </div>
      {errors.toc5 && (
        <p className="ml-8 text-[#d32f2f] text-[0.75rem]">{errors.toc5}</p>
      )}
    </div>
  );
};

export default ReviewSubmit;
