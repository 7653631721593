import React, { useState } from "react";

const License = ({
  response,
  setResponse,
  setCurrentStep,
  handleSaveFormData,
}) => {
  const [loading, setLoading] = useState();
  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-3xl text-[#183558] mb-4 font-bold">
        Upload your driver's license
      </h1>
      <p className="text-sm text-[#757989] mb-10">
        A valid, readable driver's license is required to purchase a vehicle
        from Renaissance.
      </p>
      <div>
        <div className="px-10 py-16 bg-white rounded-lg mb-10">
          <div className="lg:flex items-center justify-between">
            <div className="flex justify-center items-center">
              <div className="w-40 h-40 rounded-full">
                <img src="/icons/license.svg" alt="" />
              </div>
            </div>
            <div className="mt-10 lg:mt-0 lg:w-[calc(100%-192px)] flex flex-col gap-4 ml-4">
              <ul className="list-disc marker:text-[#00619e]">
                <li className="text-[#757989]">
                  You will need to submit a valid driver's license
                </li>
                <li className="text-[#757989]">
                  Upload an image of your valid identification
                </li>
                <li className="text-[#757989]">
                  Your driver's license will be verified to complete the
                  purchase
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <button
            disabled={loading}
            onClick={() => {
              setLoading(true);
              handleSaveFormData(
                {
                  ...response,
                  step6: {
                    completed: true,
                  },
                },
                setLoading,
                (res) => {
                  setCurrentStep((prev) => prev + 1);
                  setResponse(res.data.data);
                }
              );
            }}
            className="rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-80 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
          >
            GET STARTED
          </button>
        </div>
      </div>
    </div>
  );
};

export default License;
