import { Checkbox, InputLabel } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";

const Features = ({ setData, data, setStep }) => {
  return (
    <div className="bg-white w-[calc(100%-40px-256px)]">
      <h1 className="text-[#183558] text-lg font-bold mb-5 mt-8">
        Vehicle Features
      </h1>
      <p className="text-[#757989] text-lg mb-5">
        We’ve looked up the standard equipment for your vehicle. Please review
        the list below and select your vehicle’s optional features.
      </p>
      <div>
        <Formik
          initialValues={
            data.features
              ? data.features
              : {
                  wheelsAndTyres: "",
                  entertainment: "",
                }
          }
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            setStep(3);
            setData({ ...data, features: values });
          }}
        >
          {({
            isSubmitting,
            errors,
            values,
            setValues,
            setFieldValue,
            handleChange,
            handleBlur,
            touched,
          }) => (
            <Form>
              <h1 className="text-base font-semibold text-[#183558] mt-8 mb-4">
                Wheels & Tires
              </h1>
              <InputLabel
                id="PremiumWheels"
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                <Checkbox
                  checked={values.wheelsAndTyres === "Premium Wheels"}
                  inputProps={{ "aria-label": "Checkbox demo" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue("wheelsAndTyres", "Premium Wheels");
                    } else {
                      setFieldValue("wheelsAndTyres", "");
                    }
                  }}
                  labelId="PremiumWheels"
                  sx={{
                    marginBottom: "6px",
                    color: "blue",
                    "&.Mui-checked": {
                      color: "blue",
                    },
                  }}
                />
                Premium Wheels
              </InputLabel>
              <InputLabel
                id="PremiumWheels19+"
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                <Checkbox
                  checked={values.wheelsAndTyres === "Premium Wheels 19 +"}
                  inputProps={{ "aria-label": "Checkbox demo" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue("wheelsAndTyres", "Premium Wheels 19 +");
                    } else {
                      setFieldValue("wheelsAndTyres", "");
                    }
                  }}
                  labelId="PremiumWheels19+"
                  sx={{
                    marginBottom: "6px",
                    color: "blue",
                    "&.Mui-checked": {
                      color: "blue",
                    },
                  }}
                />
                Premium Wheels 19"+
              </InputLabel>
              <h1 className="text-base font-semibold text-[#183558] mt-8 mb-4">
                Entertainment & Instrumentation
              </h1>
              <InputLabel
                id="DVDSystem"
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                <Checkbox
                  checked={values.entertainment.includes("DVD System")}
                  inputProps={{ "aria-label": "Checkbox demo" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue("entertainment", [
                        ...values.entertainment,
                        "DVD System",
                      ]);
                    } else {
                      console.log(
                        values.entertainment.filter(
                          (item) => item !== "DVD System"
                        )
                      );
                      setFieldValue(
                        "entertainment",
                        values.entertainment.filter(
                          (item) => item !== "DVD System"
                        )
                      );
                    }
                  }}
                  labelId="DVDSystem"
                  sx={{
                    marginBottom: "6px",
                    color: "blue",
                    "&.Mui-checked": {
                      color: "blue",
                    },
                  }}
                />
                DVD System
              </InputLabel>
              <InputLabel
                id="NavigationSystem"
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                <Checkbox
                  checked={values.entertainment.includes("Navigation System")}
                  inputProps={{ "aria-label": "Checkbox demo" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue("entertainment", [
                        ...values.entertainment,
                        "Navigation System",
                      ]);
                    } else {
                      setFieldValue(
                        "entertainment",
                        values.entertainment.filter(
                          (item) => item !== "Navigation System"
                        )
                      );
                    }
                  }}
                  labelId="NavigationSystem"
                  sx={{
                    marginBottom: "6px",
                    color: "blue",
                    "&.Mui-checked": {
                      color: "blue",
                    },
                  }}
                />
                Navigation System
              </InputLabel>
              <InputLabel
                id="PremiumSound"
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                <Checkbox
                  checked={values.entertainment.includes("Premium Sound")}
                  inputProps={{ "aria-label": "Checkbox demo" }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue("entertainment", [
                        ...values.entertainment,
                        "Premium Sound",
                      ]);
                    } else {
                      setFieldValue(
                        "entertainment",
                        values.entertainment.filter(
                          (item) => item !== "Premium Sound"
                        )
                      );
                    }
                  }}
                  labelId="PremiumSound"
                  sx={{
                    marginBottom: "6px",
                    color: "blue",
                    "&.Mui-checked": {
                      color: "blue",
                    },
                  }}
                />
                Premium Sound
              </InputLabel>
              <div className="my-10 flex justify-between gap-4 w-[95%]">
                <button
                  type="button"
                  onClick={() => {
                    setStep(1);
                  }}
                  className="rounded w-1/2 text-[#00aed9] bg-[#fff] hover:bg-[#00aed9] hover:text-white font-medium min-h-14  border border-[#00aed9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                >
                  BACK
                </button>
                <button
                  type="submit"
                  className="rounded w-1/2 text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
                >
                  NEXT
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Features;
