import React from "react";

const CancelSuccess = ({ setOpenPopup }) => {
  return (
    <div className="w-screen bg-[rgba(0,0,0,0.3)] h-screen flex justify-center items-center fixed top-0 right-0 z-10">
      <div className="absolute bottom-0 md:bottom-auto  bg-white md:max-w-[901px] w-full max-h-screen md:max-h-[90vh] overflow-auto z-10 rounded-t-lg md:rounded flex flex-col justify-between">
        <div
          className="flex items-center justify-between py-4 px-4"
          style={{ boxShadow: "rgba(25, 41, 61, 0.18) 0px 2px 4px -1px" }}
        >
          <div></div>
          <div className="text-[#183558] font-bold">ORDER CANCELLED</div>
          <div>
            <img
              onClick={() => {
                setOpenPopup(false);
              }}
              className="w-4 cursor-pointer"
              src="/icons/close.svg"
              alt=""
            />
          </div>
        </div>
        <div className="pt-8 pb-10 px-12">
          <div className="flex flex-col justify-between items-center w-[80%] mx-auto">
            <img className="w-14" src="/icons/success.svg" alt="" />
            <h1 className="mt-4 mb-2 text-2xl font-bold text-[#183558]">
              Your order has been cancelled.
            </h1>
            <p className="text-[#757989] ">
              Sorry this one didn't work out. If you're still looking for a car,
              check out our other vehicles.
            </p>
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => {
                setOpenPopup(false);
              }}
              className="mt-10 rounded  text-white bg-[#00aed9] hover:bg-[#00aed9]  font-medium min-h-14  border border-[#00aed9] md:w-64 disabled:text-white disabled:border-none disabled:bg-[#d9dde9] "
            >
              Search Vehicles
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelSuccess;
